import React from "react";
import Card from "react-credit-cards";
 

import {
  formatCreditCardNumber,
  formatCVC,
  formatExpirationDate,
  formatFormData
} from   '../functions/utils'; 

import "react-credit-cards/es/styles-compiled.css";

export default class Tarjeta extends React.Component {
  state = {
    number: this.props.numero,
    name: this.props.nombre,
    expiry: this.props.expira,
    cvc: this.props.codigo,
    issuer: "",
    focused: "",
    formData: null
  };

  handleCallback = ({ issuer }, isValid) => { 
    if (isValid) {
      this.setState({ issuer });
    }
  };

  handleInputFocus = ({ target }) => {
    this.setState({
      focused: target.name
    });
  };

  handleInputChange = ({ target }) => {
    if (target.name === "number") {
      target.value = formatCreditCardNumber(target.value);
      this.props.EnviarNumeroTarjeta(target.value);
    } else if (target.name === "expiry") {
      target.value = formatExpirationDate(target.value);
      this.props.EnviarExpiraTarjeta(target.value);
    } else if (target.name === "cvc") {
      target.value = formatCVC(target.value);
      this.props.EnviarCodigoTarjeta(target.value);
    }else if (target.name === "name") {
        this.props.EnviarNombreTarjeta(target.value);
      }
    this.setState({ [target.name]: target.value });
  };

  handleSubmit = e => {
    e.preventDefault();
  
    const formData = [...e.target.elements]
      .filter(d => d.name)
      .reduce((acc, d) => {
        acc[d.name] = d.value;
        return acc;
      }, {});

    this.setState({ formData });
    this.form.reset();

  };

  render() {
    const { name, number, expiry, cvc, focused, issuer, formData } = this.state;
   
    return (
      <div key="Payment">
        <div className="App-payment">
          <h1>Cargue la Tarjeta</h1> 
          <Card
            number={number}
            name={name}
            expiry={expiry}
            cvc={cvc}
            focused={focused}
            callback={this.handleCallback}
          />
          <form ref={c => (this.form = c)} onSubmit={this.handleSubmit}>
            <div className="form-group">
              <input
                type="tel"
                name="number" 
                className="form-control"
                placeholder="Numero de Tarjeta"
                pattern="[\d| ]{16,22}"
                required
                value={number}
                onChange={this.handleInputChange}
                onFocus={this.handleInputFocus}
              />
              <small></small>
            </div>
            <div className="form-group">
              <input
                type="text"
                name="name"
                className="form-control"
                placeholder="Nombre en la Tarjeta"
                required
                value={name}
                onChange={this.handleInputChange}
                onFocus={this.handleInputFocus}
              />
            </div>
            <div className="row">
              <div className="col-6">
                <input
                  type="tel"
                  name="expiry"
                  className="form-control"
                  placeholder="Fecha de Vencimiento"
                  pattern="\d\d/\d\d"
                  required
                  value={expiry}
                  onChange={this.handleInputChange}
                  onFocus={this.handleInputFocus}
                />
              </div>
              <div className="col-6">
                <input
                  type="tel"
                  name="cvc"
                  value={cvc}
                  className="form-control"
                  placeholder="Codigo seguridad"
                  pattern="\d{3,4}"
                  required
                  onChange={this.handleInputChange}
                  onFocus={this.handleInputFocus}
                />
              </div>
            </div>
            <input type="hidden" name="issuer" value={issuer} />
            {/* <div className="form-actions">
              <button className="btn btn-primary btn-block">PAY</button>
            </div> */}
          </form>
          {formData && (
            <div className="App-highlight">
              {formatFormData(formData).map((d, i) => (
                <div key={i}>{d}</div>
              ))}
            </div>
          )}
        </div>
      
      </div>
    );
  }
}
