import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Rating from '../components/Rating';
import {useDispatch, useSelector} from 'react-redux';
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
import { createReview, detailsProduct } from '../actions/productActions';
import {  PRODUCT_DETAILS_SUCCESS, PRODUCT_REVIEW_CREATE_RESET } from "../constants/productConstants";
import NumericInput from 'react-numeric-input';
import Carousel from 'react-elastic-carousel'
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
//import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { blue } from '@material-ui/core/colors';
import PropTypes from 'prop-types'; 
import { PANTALLAATRAS, PANTALLASELECCIONADA } from '../constants/userConstants';
import ZoomImagenes from '../components/ZoomImagenes';

function NewlineText(props) {
  const text = props.text;
  if(text)
  return text.split('\n').map(str => <h4 style={{margin:0,padding:0}}>{str}</h4>);
  else
  return  <h4 style={{margin:0,padding:0}}> </h4> ;
}


const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
  listItemText:{
    fontSize:'1.5em',//Insert your required size
  }
});

function DialogCaracteristica1(props) {
  const classes = useStyles();
  const { onClose, selectedValue, open } = props;
  const handleClose = () => {
    onClose(selectedValue);
  };
  const handleListItemClick = (value) => {
    onClose(value);
  };
  const productDetails =  useSelector(state => state.productDetails);
  const {product} = productDetails;
  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
    {/*   <DialogTitle id="simple-dialog-title" style={{marginTop:"0px", marginBottom:"0px"}} > {product.Caracteristicas1.sDescripcion} </DialogTitle> */}
      <List>
        {product.lstCaracteristicasOpciones1.map((opcion) => (
          <ListItem   button onClick={() => handleListItemClick(opcion)} key={opcion.Id}
          disabled={product.lstCaracteristicasOpcionesStock.filter(r => r.MercaderiaCaracteristicaOpcion1_Id === parseInt(opcion.Id) 
            &&  r.nStock > 0).length > 0 ? false : true }
          >
            {opcion.sLink !== "" &&
            <ListItemAvatar>
              <Avatar   src={opcion.sLink}  >
              </Avatar>
            </ListItemAvatar>}
            <ListItemText classes={{primary:classes.listItemText}}  primary={opcion.sValor}  />
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
}

DialogCaracteristica2.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired 
};

function DialogCaracteristica2(props) {
  const classes = useStyles();
  const { onClose, selectedValue, open } = props;
  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  const productDetails =  useSelector(state => state.productDetails);
  const {product} = productDetails;
 
  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
 {/*      <DialogTitle id="simple-dialog-title"  style={{paddingBottom:"0px",paddingTop:"0px", marginTop:"0px",marginBottom:"0px"}}><h2>{product.Caracteristicas2.sDescripcion}</h2></DialogTitle> */}
      <List style={{paddingBottom:"0px", marginTop:"0px",marginBottom:"0px"}}>
        {product.lstCaracteristicasOpciones2.map((opcion) => (
          <ListItem button onClick={() => handleListItemClick(opcion)} key={opcion.Id}  
          disabled={product.lstCaracteristicasOpcionesStock.filter(r => r.MercaderiaCaracteristicaOpcion1_Id === parseInt(product.caracteristicaseleccionadaId1) 
                     && r.MercaderiaCaracteristicaOpcion2_Id ===  parseInt(opcion.Id) &&  r.nStock > 0).length > 0 ? false : true }>
        {opcion.sLink !== "" &&
            <ListItemAvatar>
              <Avatar   src={opcion.sLink}>
              </Avatar>
            </ListItemAvatar>}
            <ListItemText classes={{primary:classes.listItemText}}  primary={opcion.sValor} />
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
}

DialogCaracteristica2.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired 
};

export default function ProductScreen(props) {
  const MercaderiaId = props.match.params.id;
  
  const productDetails =  useSelector(state => state.productDetails);
  const {loading,error,product} = productDetails;

  const [ListaImagenes, setListaImagenes] = useState();
  const [Caracteristica1, setCaracteristica1] = useState();
  const [Caracteristica1Imagen, setCaracteristica1Imagen] = useState();
  const [Caracteristica2, setCaracteristica2] = useState();
  const [Caracteristica2Imagen, setCaracteristica2Imagen] = useState();
  const [IndiceImagen, setIndiceImagen] = useState();
  const productReviewCreate = useSelector((state) => state.productReviewCreate);
  const { loading: loadingReviewCreate, error: errorReviewCreate, success: successReviewCreate, } = productReviewCreate;
  const userSignin =  useSelector(state => state.userSignin);
  const {userInfo} = userSignin;
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState('');
  const [open2, setOpen2] = useState(false);
  const [open, setOpen] = useState(false);
  const [cantidad, setCantidad] = useState(1);

  const empresaInfo = useSelector((state) => state.empresaInfo);
  const { empresaData } = empresaInfo;

  const handleClickOpen = () => {  setOpen(true); };
    const handleClose = (value) => { 
    setOpen(false);
    if(!value || !value.sValor){return}
    product.caracteristicaseleccionadaId1 = value.Id;
    dispatch({type: PRODUCT_DETAILS_SUCCESS, payload:  product});
    setCaracteristica1(value.sValor);
    setCaracteristica1Imagen(value.sLink);
    let oImagenSeleccionada1 = product.lstImagenes.find(l => {
    return  l.Link === value.sLink})
    if(oImagenSeleccionada1){
      setIndiceImagen(oImagenSeleccionada1.Id - 1);
    }

    let lstCarateristicas2OpcionStock = product.lstCaracteristicasOpcionesStock.filter(l =>  l.MercaderiaCaracteristicaOpcion1_Id === parseInt(value.Id)   &&  l.nStock > 0)
    if(lstCarateristicas2OpcionStock)
    {
        let oCarateristica2Default = product.lstCaracteristicasOpciones2.find(r => r.sValor === product.Caracteristicas2.sDefault)
        if(oCarateristica2Default)
        {
            let oCaracteristicasOpcionesStockDefault = lstCarateristicas2OpcionStock.find(r => r.MercaderiaCaracteristicaOpcion2_Id === oCarateristica2Default.Id)
            if(oCaracteristicasOpcionesStockDefault)
            {
                setCaracteristica2(oCarateristica2Default.sValor);
                setCaracteristica2Imagen(oCarateristica2Default.sLink);
                product.caracteristicaseleccionadaId2 = oCarateristica2Default.Id;
                dispatch({type: PRODUCT_DETAILS_SUCCESS, payload:  product});
                let oImagenSeleccionada2 = product.lstImagenes.find(l => {
                return  l.Link === oCarateristica2Default.sLink})
                if(oImagenSeleccionada2){
                  setIndiceImagen(oImagenSeleccionada2.Id - 1);
                }
            }
            else
            {
              if(lstCarateristicas2OpcionStock && lstCarateristicas2OpcionStock[0])
              {
                oCarateristica2Default = product.lstCaracteristicasOpciones2.find(r => r.Id === lstCarateristicas2OpcionStock[0].MercaderiaCaracteristicaOpcion2_Id)
                if(oCarateristica2Default)
                {
                    setCaracteristica2(oCarateristica2Default.sValor);
                    setCaracteristica2Imagen(oCarateristica2Default.sLink);
                    product.caracteristicaseleccionadaId2 = oCarateristica2Default.Id;
                    dispatch({type: PRODUCT_DETAILS_SUCCESS, payload:  product});
                    let oImagenSeleccionada2 = product.lstImagenes.find(l => {
                    return  l.Link === oCarateristica2Default.sLink})
                    if(oImagenSeleccionada2){
                      setIndiceImagen(oImagenSeleccionada2.Id - 1);
                    }
                }
              }
            }
      }
    }

    var nPrecioCaracteristica = product.lstCaracteristicasOpcionesStock.find( l => l.MercaderiaCaracteristicaOpcion1_Id === product.caracteristicaseleccionadaId1
      && l.MercaderiaCaracteristicaOpcion2_Id === (product.caracteristicaseleccionadaId2 ? product.caracteristicaseleccionadaId2 : 0))

      product.nPrecio = nPrecioCaracteristica ? nPrecioCaracteristica.nPrecio : product.nPrecio;
      product.nPrecioDescuento = nPrecioCaracteristica ? nPrecioCaracteristica.nPrecioDescuento : product.nPrecioDescuento;
    };

  const handleClickOpen2 = () => {
    setOpen2(true);
  };
  const handleClose2 = (value) => {
    setOpen2(false);
    if(!value || !value.sValor  ){return}
   setCaracteristica2(value.sValor);
   setCaracteristica2Imagen(value.sLink);
   product.caracteristicaseleccionadaId2 = value.Id;

    var nPrecioCaracteristica = product.lstCaracteristicasOpcionesStock.find( l => l.MercaderiaCaracteristicaOpcion1_Id === product.caracteristicaseleccionadaId1
                                                            && l.MercaderiaCaracteristicaOpcion2_Id === product.caracteristicaseleccionadaId2)

   product.nPrecio = nPrecioCaracteristica ? nPrecioCaracteristica.nPrecio : product.nPrecio;
   product.nPrecioDescuento = nPrecioCaracteristica ? nPrecioCaracteristica.nPrecioDescuento : product.nPrecioDescuento;

   dispatch({type: PRODUCT_DETAILS_SUCCESS, payload:  product});
   let oImagenSeleccionada2 = product.lstImagenes.find(l => {
    return  l.Link === value.sLink})
    if(oImagenSeleccionada2){
      setIndiceImagen(oImagenSeleccionada2.Id - 1);
    }
  };

  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 1, itemsToScroll: 1 },
    { width: 768, itemsToShow: 1 },
    { width: 1200, itemsToShow: 1 }
  ];
  const dispatch = useDispatch();
  useEffect(() =>{
    if (successReviewCreate) {
      window.alert('Comentario enviado satisfactoriamente');
      setRating('');
      setComment('');
      dispatch({ type: PRODUCT_REVIEW_CREATE_RESET });
     
    }
    dispatch(detailsProduct(MercaderiaId));
    window.scrollTo(0, 0);
  },
  [dispatch,MercaderiaId,successReviewCreate]);
  const addToCartHandler = () => {
    props.history.push(`/cart/${MercaderiaId}/${cantidad}/${product.caracteristicaseleccionadaId1}/${product.caracteristicaseleccionadaId2}`);
  };
  let carousel;
 
  useEffect(() =>{
    if(carousel)
    {
      carousel.goTo(Number(IndiceImagen))
      window.scrollTo(0, 0)
    }
  },
  [dispatch,carousel,IndiceImagen]);

  useEffect(() =>{
    if(empresaData && empresaData.bTiendaInicioPorGrupos === true)
    {
      dispatch({type: PANTALLASELECCIONADA, payload:  'Producto'}); 
      dispatch({type: PANTALLAATRAS, payload:  'ProductosPorGrupo/' + localStorage.getItem('EmpresaId')}); 
    }
    else
    {
        dispatch({type: PANTALLASELECCIONADA, payload:  'Producto'}); 
        dispatch({type: PANTALLAATRAS, payload:  'Empresa/' + localStorage.getItem('EmpresaId')}); 
    }

    if (product) {
      setListaImagenes(product.lstImagenes);
      let oCarateristica1OpcionDefault = product.lstCaracteristicasOpciones1.find(l => {
      return  l.MercaderiaCaracteristica_Id === product.Caracteristicas1.Id})

      let oCarateristica2OpcionDefault = product.lstCaracteristicasOpciones2.find(l => {
      return  l.MercaderiaCaracteristica_Id === product.Caracteristicas2.Id
      && (product.lstCaracteristicasOpcionesStock.filter(r => r.MercaderiaCaracteristicaOpcion1_Id === parseInt(oCarateristica1OpcionDefault.Id) 
      && r.MercaderiaCaracteristicaOpcion2_Id === parseInt(l.Id) &&  r.nStock > 0).length > 0)})

      if(oCarateristica1OpcionDefault){
        setCaracteristica1(oCarateristica1OpcionDefault.sValor);
        setCaracteristica1Imagen(oCarateristica1OpcionDefault.sLink);
        let oImagenSeleccionada1 = product.lstImagenes.find(l => { return l.Link === oCarateristica1OpcionDefault.sLink})
        if(oImagenSeleccionada1){ setIndiceImagen(oImagenSeleccionada1.Id - 1);  }
        product.caracteristicaseleccionadaId1 = oCarateristica1OpcionDefault.Id;
      }

      if(oCarateristica2OpcionDefault){ 
        setCaracteristica2Imagen(oCarateristica2OpcionDefault.sLink);
        setCaracteristica2(oCarateristica2OpcionDefault.sValor);
        product.caracteristicaseleccionadaId2 = oCarateristica2OpcionDefault.Id;
        let oImagenSeleccionada2 = product.lstImagenes.find(l => l.Link === oCarateristica2OpcionDefault.sLink)
        if(oImagenSeleccionada2){ setIndiceImagen(oImagenSeleccionada2.Id -1 );   }
      }
      
      if(oCarateristica1OpcionDefault || oCarateristica2OpcionDefault)
      {
          var nPrecioCaracteristica = product.lstCaracteristicasOpcionesStock.find( l => l.MercaderiaCaracteristicaOpcion1_Id === product.caracteristicaseleccionadaId1
            && l.MercaderiaCaracteristicaOpcion2_Id === (product.caracteristicaseleccionadaId2 ?product.caracteristicaseleccionadaId2:0) )
      
          product.nPrecio = nPrecioCaracteristica ? nPrecioCaracteristica.nPrecio : product.nPrecio;
          product.nPrecioDescuento = nPrecioCaracteristica ? nPrecioCaracteristica.nPrecioDescuento : product.nPrecioDescuento;
      }
      dispatch({type: PRODUCT_DETAILS_SUCCESS, payload:  product});
    }
  },
  [dispatch,product,empresaData]);

  const submitHandler = (e) => {
    e.preventDefault();
    if (comment && rating) {
      dispatch(
        createReview(MercaderiaId, { rating, comment, name: userInfo.Email })
      );
    } else {
      alert('Por favor ingrese el comentario y calificacion');
    }
  };


return (
  <div style={{marginBottom:'50px',marginTop:"-45px"}}>

    {ListaImagenes &&  ListaImagenes.length > 0 && <div style={{ position: 'absolute',zIndex: 1, top: 80,  left: 0,  
    justifyContent: 'center', alignItems: 'center', marginBottom:"30px"}} >
      <ZoomImagenes />
    </div>}
        {loading? <LoadingBox></LoadingBox>
        :
        error?<MessageBox variant="danger">{error}</MessageBox>
        :
        <div>
          <div className="row top">
          {ListaImagenes &&  ListaImagenes.length > 1 ? (
                       <div className="col-4">
                       <div style={{ position: 'relative'}}  >
                           <Carousel    breakPoints={breakPoints}  focusOnSelect={true} outerSpacing={0}   
                           renderButtonGroupOutside={true}  
                           showArrows={false} ref={ref => (carousel = ref)} >
                                { ListaImagenes.map(imagen => (
   
                              <div>
                              <img style={{verticalAlign:'center', alignContent:'center', width:"100%" }}   
                                   alt='' src={imagen.Link} />
                                      </div>
                               )) }
                       </Carousel>
                 {              
                     <><button style={{zIndex :'0' ,position:'absolute',top:'50%' }} onClick={() => carousel.slidePrev()}> <i className="fa fa-arrow-left"></i> </button>
                     <button style={{zIndex :'0', right:'0',  position:'absolute',top:'50%'}} onClick={() => carousel.slideNext()}> <i className="fa fa-arrow-right"></i> </button></>
                 }
               </div>
               </div>
                        ) : (
                          product.LinkFoto && String(product.LinkFoto) !== "https://twinsarchivos.blob.core.windows.net/imagenesabmmercaderias/Producto.png" &&  
                          <div className="col-4" style={{marginTop:"30px", alignItems:"center"}}>
                        
                              <img  className="large"  src={ product.LinkFoto } alt={product.MercaderiaDescripcion} ></img>
                          
                        </div>
                        )} 
            <div className="col-1">
              <ul style={{marginLeft:'10px'}}>
                <li>
                  <h1>{product.MercaderiaDescripcion}</h1>
                </li>
                <li>
                  <Rating  rating={product.rating} numReviews={product.numReviews}  ></Rating>
                </li>
                <li>Precio :  
                {product.nPrecio !== product.nPrecioDescuento && <label style={{textDecoration:"line-through",marginRight:"10px"}}>${product.nPrecioDescuento} </label>  } 
                ${product.nPrecio} 
                {product.TipoDescuento !== "" && <label>({product.TipoDescuento})</label>}
                </li>
                <li> 
                  <NewlineText text={product.Obs} />
                  {/* <p>{product.Obs}</p> */}
                </li>
                <tr>
              { ( product.Caracteristicas1.Id > 0 && product.lstCaracteristicasOpciones1 && product.lstCaracteristicasOpciones1.length>0 ) &&
                <td>
                  <Button variant="outlined" fontSize="32px" color="primary" onClick={handleClickOpen}>
                      <table>
                          <tr>
                          {Caracteristica1Imagen &&
                          <td>  <img src={Caracteristica1Imagen} className='small' alt=''></img></td>}
                              <td> <h7>{product.Caracteristicas1.sDescripcion} </h7>
                              <h4> {Caracteristica1}</h4></td>
                          </tr>
                      </table>
                  </Button>
                  <DialogCaracteristica1 selectedValue={Caracteristica1} open={open} onClose={handleClose} />
            </td> 
            }  
          {  product.Caracteristicas2.Id > 0 &&
                <td >
                  <Button variant="outlined" fontSize="32px" color="primary" onClick={handleClickOpen2}>
                  <table>
                    <tr>
                    {Caracteristica2Imagen &&
                    <td>  <img src={Caracteristica2Imagen} className='small' alt=''></img></td>}
                        <td> <h7>{product.Caracteristicas2.sDescripcion} </h7>
                      <h4> {Caracteristica2}</h4></td>
                    </tr>
                  </table>
                  </Button>
                  <DialogCaracteristica2 selectedValue={Caracteristica2} open={open2} onClose={handleClose2} />
            </td> 
            } 
</tr>
        <div className="row center">
         { product.lstImagenes.length > 1 &&(
                product.lstImagenes.map(imagen => (
                  <li><button type="button" className="light" onClick={e => setIndiceImagen(imagen.Id - 1)}><img src={imagen.Link} alt="product" className="small"  /></button></li>
                )))
                }
               </div>
              </ul>
            </div>
            <div className="col-1">
              <div className="card card-body">
                <ul>
                  <li>
                    <div className="row">
                      <div>Precio</div>
                      <div className="price">
                      {product.nPrecio !== product.nPrecioDescuento && <label style={{textDecoration:"line-through",marginRight:"10px"}}>${product.nPrecioDescuento} </label>  } 
                ${product.nPrecio} 
                {product.TipoDescuento !== "" && <label>({product.TipoDescuento})</label>}
                        </div>
                    </div>
                  </li>
                  <li>
                    <div className="row">
                      <div>Estado</div>
                      <div>
                        {product.nStock > 0 ? (
                          <span className="success">En Stock</span>
                        ) : (
                          <span className="danger">No Disponible</span>
                        )}
                      </div>
                    </div>
                  </li>
                  {product.nStock > 0 &&  empresaData.bSoloCatalogo === false  && (
                    <>
                      {
                        product.nStock > 0 && 
                        (
                              <>                               
                                <li>
                                      <div className="row">
                                        <div>Cantidad</div>
                                       { product.Medicion === 'P' && 
                                        (
                                        <NumericInput step={0.1} mobile  size={6} precision={2} min={ product.CantidadMinimaPresentacion }  required value={cantidad?cantidad:0} onChange={e => setCantidad(e?e:product.CantidadMinimaPresentacion)}
                                        style={{
                                          wrap: { fontSize: 22 },
                                          input: { fontWeight: 100,  },
                                          'input:focus' : { border: '1px inset #69C',  outline: 'none' },
                                          arrowUp: {  borderBottomColor: 'rgba(66, 54, 0, 0.63)' },
                                          arrowDown: {  borderTopColor: 'rgba(66, 54, 0, 0.63)' }
                                      }}/>
                                         )}
                                          { product.Medicion !== 'P' && 
                                        (
                                        <NumericInput mobile step={1} min={ product.CantidadMinimaPresentacion } size={6} precision={0} required value={cantidad?cantidad:0} onChange={e => setCantidad(e?e:product.CantidadMinimaPresentacion)} 
                                        style={{
                                          wrap: {  fontSize: 22 },
                                          input: {  fontWeight: 100,  },
                                          'input:focus' : {  border: '1px inset #69C', outline: 'none'  },
                                          arrowUp: {  borderBottomColor: 'rgba(66, 54, 0, 0.63)'  },
                                          arrowDown: {  borderTopColor: 'rgba(66, 54, 0, 0.63)' }
                                      }}/>
                                         )} 
                                      </div>
                                </li> 
                                <li>
                                    <button onClick={addToCartHandler} className="primary block" >Agregar al Carro</button>
                                </li>
                              </> 
                        )
                      }
                    </>
                  )}
                </ul>
              </div>
            </div>
          </div>
          <div style={{marginLeft:'10px',marginRight:'10px'}}>
            <h2 id="reviews">Calificaciones</h2>
            {product.lstComentarios.length === 0 && (
              <MessageBox>Este producto aun no posee calificaciones</MessageBox>
            )}
            <ul>
              {product.lstComentarios.map((review) => (
                <li key={review.Id}>
                  <strong>{review.Operador}</strong>
                  <Rating rating={review.hCalificacion} caption=" "></Rating>
                  <p>{review.dtCreado.substring(0, 10)}</p>
                  <p>{review.sComentario}</p>
                </li>
              ))}
              <li>
                {userInfo ? (
                  <form className="form" onSubmit={submitHandler}>
                    <div>
                      <h2>Escriba un comentario</h2>
                    </div>
                    <div>
                      <label htmlFor="rating">Calificacion</label>
                      <select
                        id="rating"
                        value={rating}
                        onChange={(e) => setRating(e.target.value)}
                      >
                        <option value="">Seleccione...</option>
                        <option value="1">1- Malo</option>
                        <option value="2">2- Regular</option>
                        <option value="3">3- Bueno</option>
                        <option value="4">4- muy bueno</option>
                        <option value="5">5- Excelente</option>
                      </select>
                    </div>
                    <div>
                      <label htmlFor="comment">Comentario</label>
                      <textarea
                        id="comment"
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                      ></textarea>
                    </div>
                    <div>
                      <label />
                      <button className="primary" type="submit">
                        Enviar
                      </button>
                    </div>
                    <div>
                      {loadingReviewCreate && <LoadingBox></LoadingBox>}
                      {errorReviewCreate && (
                        <MessageBox variant="danger">
                          {errorReviewCreate}
                        </MessageBox>
                      )}
                    </div>
                  </form>
                ) : (
                  <MessageBox>
                    Por favor <Link to="/signin">Ingrese al sistema</Link> para escribir un comentario
                  </MessageBox>
                )}
              </li>
            </ul>
          </div>
        </div>
        }       
      </div> 
)
 
     
 }