const {
  FILTRO_SET
  } = require("../constants/filtrosConstantes")

  export const filtrosReducer = (state = {filtroItems:{Grupo:0,Palabra:'',Precio:'Todos', Rating:'Todas'}}, action) => {
    switch (action.type) {
      case FILTRO_SET:
        return { ...state, filtroItems: action.payload };
      default:
        return state;
    }
  };