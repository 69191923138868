import {  FILTRO_SET  } from "../constants/filtrosConstantes";

export const saveFiltroPrecio = (filtro) => async (dispatch,getState) =>{
    const {  filtros: { filtroItems }  } = getState();
    filtroItems.Precio = filtro;
    filtroItems.PrecioDescripcion = filtro === 'Todos' ? '' :' Precio: ' + filtro;
    dispatch({type: FILTRO_SET, payload: filtroItems});
}
export const saveFiltroPalabra = (filtro) => async (dispatch,getState) =>{
    const {  filtros: { filtroItems }  } = getState();
    filtroItems.Palabra = filtro;
    dispatch({type: FILTRO_SET, payload: filtroItems});
}

export const saveFiltroGrupo = (filtro,GrupoDescripcion) => async (dispatch,getState) =>{
    const {  filtros: { filtroItems }  } = getState();
    filtroItems.Grupo = filtro;
    filtroItems.GrupoDescripcion = filtro === 0 ? '' :' Grupo: ' + GrupoDescripcion;
    dispatch({type: FILTRO_SET, payload: filtroItems});
}
 
export const saveFiltroRating = (filtro) => async (dispatch,getState) =>{
    const {  filtros: { filtroItems }  } = getState();
    filtroItems.Rating = filtro;
    filtroItems.RatingDescripcion = filtro === 'Todas' ? '' :' Calif: ' + filtro;
    dispatch({type: FILTRO_SET, payload: filtroItems});
}