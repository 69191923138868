import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { insertDireccion } from '../actions/userActions'; 
import { listProvincias,listLocalidades } from '../actions/userActions';
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
import TextField from '@material-ui/core/TextField'; 
import Autocomplete from '@material-ui/lab/Autocomplete'; 
import { Typography } from '@material-ui/core' 
import { DIRECCION_INSERT_RESET, LOCALIDADES_SUCCESS } from '../constants/userConstants';
import ExitToAppIcon from '@material-ui/icons/ExitToApp'; 
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import swal from 'sweetalert';
const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
    width: '100px'
  },
}));
export default function NuevaDireccionEntrega(props) {
  const classes = useStyles();
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;

  if (!userInfo) {
    props.history.push('/signin');
  }

  const DireccionId =0;
  const [Direccion, setDireccion] = useState('');
  const [ProvinciaId, setProvinciaId] = useState(0);
  const [ProvinciaCodigo, setProvinciaCodigo] = useState(0);
  const [Pais, setPais] = useState('Argentina');
  const [LocalidadCodigo, setLocalidadCodigo] = useState(''); 
  const [LocalidadDescripcion, setLocalidadDescripcion] = useState('');  
  const [EntreCalles, setEntreCalles] = useState('');
  const dispatch = useDispatch();
  const provinciasList =  useSelector(state => state.provinciasList);
  const {loading: loadingProvincias, error: errorProvincias, provincias} = provinciasList;
  useEffect(() => { 
       dispatch(listProvincias())
  }, [dispatch])

  const localidadesList =  useSelector(state => state.localidadesList);
  const {loading: loadingLocalidades, error: errorLocalidades, localidades} = localidadesList;
  useEffect(() => { 
       dispatch(listLocalidades(ProvinciaCodigo))
  }, [dispatch,ProvinciaCodigo])

  const direccionInsert = useSelector((state) => state.direccionInsert);
  const { loading: loadingUpdate, error: errorUpdate, success: successUpdate } = direccionInsert;
    
  const submitHandler = (e) => {
    e.preventDefault();
    if(!ProvinciaId || ProvinciaId === 0)
    {
   
        swal({
          title: "Debe seleccionar la provincia",
          text: "",
          icon: "error",
          button: "cerrar",
        });
    }
    else if(!LocalidadCodigo|| LocalidadCodigo === '')
    {
      swal({
        title: "Debe seleccionar la localidad",
        text: "",
        icon: "error",
        button: "cerrar",
      });
    }
    else if(!Direccion|| Direccion === '')
    {
      swal({
        title: "Debe cargar la direccion",
        text: "",
        icon: "error",
        button: "cerrar",
      });
    }
    else if(!EntreCalles|| EntreCalles === '')
    {
      swal({
        title: "Debe cargar la Entre Calles",
        text: "",
        icon: "error",
        button: "cerrar",
      });
    }
    else
    {
      swal({
        title: "Esta seguro de agregar esta direccion ?",
        text: "",
        icon: "warning",
        buttons: ["NO", "SI"],
        dangerMode: true,
      })
      .then((willDelete) => {
        if (willDelete) {
       dispatch( 
          insertDireccion( DireccionId,ProvinciaId,Direccion,LocalidadDescripcion,LocalidadCodigo,EntreCalles)
        );
        }  
      });

    }
  };

  useEffect(() => {
    if(successUpdate)
    {
      dispatch({ type: DIRECCION_INSERT_RESET });
      swal("Exito! Direccion Guardada satisfactoriamente", {
        icon: "success",
      });
        props.history.push('/shipping');  
    }
  },[dispatch, props.history, successUpdate] ) ;

  const Salir = () => {
    props.history.push('/shipping');
  }

const cambioLocalidad = (e) => {
  if(e)
  {
    setLocalidadCodigo(e.sCodigo)
    setLocalidadDescripcion(e.sDescripcion)
  }
}

const cambioProvincia = (e) => {
  if(e)
  {
   
    setProvinciaCodigo(e.sCodigo)
    setProvinciaId(e.Id)
  }
  else
  {
    setProvinciaCodigo('')
    setProvinciaId(0)
    dispatch({type: LOCALIDADES_SUCCESS, payload: []}); 
    setLocalidadCodigo('')
    setLocalidadDescripcion('')
  }
}

  return (
    <div > 
<form className="form" onSubmit={submitHandler}>      
        <div>
        <Button 
        variant="contained"
        color="secondary" 
        onClick={() => Salir()}
        className={classes.button}
        startIcon={<ExitToAppIcon />}
      >SALIR</Button>
          <label htmlFor="country">Pais</label>
          <input
          readOnly='true'
            type="text"
            id="country"
            placeholder="Ingresar Pais"
            value={Pais}
            onChange={(e) => setPais(e.target.value)}
            required
          ></input>
        </div>

        <div>
              {loadingProvincias? <LoadingBox></LoadingBox>
              :
              errorProvincias?<MessageBox variant="danger">{errorProvincias}</MessageBox>
              :    
              <Autocomplete
              onChange={(event, value) => cambioProvincia(value)} 
              autoSelect 
              required
              getOptionLabel={(option) => option.sDescripcion}
              getOptionSelected={(option) => option.Id}
              renderOption={(option) => (
                <Typography style={{ fontSize: "1.5rem" }}>{option.sDescripcion}</Typography>
              )}
              itemID= "Id"
              options={provincias} 
              renderInput={(params) => (  <TextField {...params}   variant="outlined"  label="Ingrese Provincia"   inputProps={{ ...params.inputProps, style: { fontSize: "1.5rem" } }} />   )} 
            />}
        </div>

        <div>
              {loadingLocalidades? <LoadingBox></LoadingBox>
              :
              errorLocalidades?<MessageBox variant="danger">{errorLocalidades}</MessageBox>
              :    
              <Autocomplete
              onChange={(event, value) => cambioLocalidad(value)} 
              required
              autoSelect 
              getOptionLabel={(option) => option.sDescripcion}
              getOptionSelected={(option) => option.Id}
              renderOption={(option) => (
                <Typography style={{ fontSize: "1.5rem" }}>{option.sDescripcion}</Typography>
              )}
              itemID= "Id"
              options={localidades} 
              renderInput={(params) => (  <TextField {...params}   variant="outlined"  label="Ingrese Localidad"   inputProps={{ ...params.inputProps, style: { fontSize: "1.5rem" } }} />   )} 
            />}
        </div>
 

      
        <div>
          <label htmlFor="address">Direccion</label>
          <input
            type="text"
            id="address"
            placeholder="Ingresar Direccion"
            value={Direccion}
            onChange={(e) => setDireccion(e.target.value)}
            required
          ></input>
        </div>

        <div>
          <label htmlFor="address">Entre Calles</label>
          <input maxLength="200"
            type="text"
            id="EntreCalles"
            placeholder="Ingresar Entre Calles"
            value={EntreCalles}
            onChange={(e) => setEntreCalles(e.target.value)}
            required
          ></input>
        </div>
             
        {loadingUpdate && <LoadingBox></LoadingBox>}
            {errorUpdate && (
              <MessageBox variant="danger">{errorUpdate}</MessageBox>
            )}


        <div>
          <label />
          <button className="primary" type="submit">
            Guardar Direccion
          </button>
        </div>
      </form>
    </div>
  );
}