import { FormControl, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import React, { useState,useEffect  } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { saveHorario } from '../actions/cartActions';
import CheckoutSteps from '../components/CheckoutSteps';
import { PANTALLAATRAS, PANTALLASELECCIONADA } from '../constants/userConstants';
import { Calendar } from 'primereact/calendar';
import { addLocale } from 'primereact/api';
import Moment from 'moment';
import { getlistaRangosHorario } from '../actions/rangoshorarioActions';
export default function HorarioScreen(props) {
  const cart = useSelector((state) => state.cart);
  const { shippingAddress } = cart;
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;
  const RangosHorario =  useSelector(state => state.RangosHorario)
  const { RangosHorarioLista} = RangosHorario;
  const empresaInfo = useSelector((state) => state.empresaInfo);
  const { empresaData } = empresaInfo;
  const [Dias, setDias] = useState(null);
  const [Leyenda, setLeyenda] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    var s = (empresaData && empresaData.sDiasDisponibles) ?empresaData.sDiasDisponibles:"";
      let o =[];
      if(!s.includes('0'))
      o.push(0);
      if(!s.includes('1'))
      o.push(1);
      if(!s.includes('2'))
      o.push(2);
      if(!s.includes('3'))
      o.push(3);
      if(!s.includes('4'))
      o.push(4);
      if(!s.includes('5'))
      o.push(5);
      if(!s.includes('6'))
      o.push(6);
      setDias(o)

      var now = new Date();
      if(!s.includes(String(now.getDay())))
      {
      setLeyenda('Hoy la tienda esta cerrada')
      for (let index = 0; index < 7; index++) {
        now.setDate(now.getDate()+1); 
        if(s.includes(String(now.getDay())))
        { 
          setFecha(now)
          break;
        }
      }
      }
      else
      {
        setFecha(now)
      }

     
 

        }, [dispatch,empresaData]);
    

  const [Fecha, setFecha] = useState(new Date());
   

  if (!userInfo) {
  props.history.push('/signin?redirect=payment');
  }

  if (!cart.cartItems) {
    props.history.push('/empres/'+ localStorage.getItem('EmpresaId'));

  }if (cart.cartItems.length === 0) {
    props.history.push('/empresa/'+ localStorage.getItem('EmpresaId'));
  }
  
  if (!shippingAddress.Id) {
    props.history.push('/shipping');
  }

  const [horarioseleccionado, setHorarioSeleccionado] = useState(RangosHorarioLista ? ( RangosHorarioLista.filter(r => r.iCantidad  > r.PedidosRealizados)[0] ?(RangosHorarioLista.filter(r => r.iCantidad  > r.PedidosRealizados)[0].Id):null): null);
  const handleChangeMetodo = (event) => { 
    setHorarioSeleccionado((Number(event.target.value)));
  };
 
 
  useEffect(() => {
    dispatch({type: PANTALLASELECCIONADA, payload:  'Horario'}); 
    dispatch({type: PANTALLAATRAS, payload: 'payment'}); 
  }, [dispatch]);


 useEffect(() => {
  dispatch(getlistaRangosHorario(Fecha));
  cart.Fecha=Fecha;
  }, [dispatch,Fecha,cart]);

  useEffect(() => {
    if(cart.cartItems.find(r => r.bConsumeCupoHorario === true) )
    {
      setHorarioSeleccionado(RangosHorarioLista ? ( RangosHorarioLista.filter(r => r.iCantidad  > r.PedidosRealizados)[0] ?(RangosHorarioLista.filter(r => r.iCantidad  > r.PedidosRealizados)[0].Id):null): null);
    }
    else
    {
       setHorarioSeleccionado(RangosHorarioLista ? ( RangosHorarioLista[0] ?(RangosHorarioLista[0].Id):null): null);
    }

   
  }, [dispatch,RangosHorarioLista,cart.cartItems]);

  const submitHandler = (e) => {
    e.preventDefault();
    const drSeleccionada = RangosHorarioLista.filter(l => { return  l.Id === horarioseleccionado})[0];
    dispatch(saveHorario(drSeleccionada));
    cart.horario = drSeleccionada;
    cart.Fecha = Fecha;
    props.history.push('/placeorder');
  };

  addLocale('es', {
    
    dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
    dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
    dayNamesMin: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
    monthNames: ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
    monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
    today: 'Hoy',
    clear: 'Claro'
});

  return (
    <div >
      <CheckoutSteps step1 step2 step3 step4></CheckoutSteps>
      <div className="row" style={{margin:0,padding:0}}>
    
      <form className="form" onSubmit={submitHandler} >

      <Calendar style={{margin:0,padding:0}} disabledDays={Dias} value={Fecha} onChange={(e) => setFecha(e.value)} inline   locale="es" minDate={new Date()} />
     
      <div >
        <h4 style={{color:"red", padding:0, margin:0}} >{Leyenda}</h4>
          <h5 style={{margin:0,padding:0}}> Fecha Pedido:   {Moment(Fecha).format('DD/MM/YYYY')}</h5>
        </div>

        <div style={{margin:0,padding:0}}>
          <h1 style={{margin:0,padding:0}}>Horario Entrega</h1>
        </div>

        {RangosHorarioLista &&  
      <>
     
     {cart.cartItems.find(r => r.bConsumeCupoHorario === true) ?
     <FormControl component="fieldset" style={{margin:0,padding:0}} >
          <RadioGroup aria-label="gender"  name="gender1" value={horarioseleccionado} onChange={handleChangeMetodo}>
          { RangosHorarioLista.map(metodo => (
            <FormControlLabel   key={metodo.sDesde + ' a ' + metodo.sHasta}
            style={{margin:0,padding:0, RadioLabel: {  fontSize: "3rem"   } }}
            value={metodo.Id}  
            control=  {    <Radio disabled={(metodo.iCantidad > metodo.PedidosRealizados) ?false:true} style={{ RadioLabel: {  fontSize: "3rem"   } }}/>}   
            margin="normal"
            label={<span style={{ fontSize: '2rem',textDecoration: metodo.iCantidad > metodo.PedidosRealizados ? "" :"line-through"  }}>{metodo.sDesde + ' a ' + metodo.sHasta} </span>}/>
            ))
          }
          </RadioGroup>
      </FormControl>
      :
      <FormControl component="fieldset" style={{margin:0,padding:0}} >
      <RadioGroup aria-label="gender"  name="gender1" value={horarioseleccionado} onChange={handleChangeMetodo}>
      { RangosHorarioLista.map(metodo => (
        <FormControlLabel   key={metodo.sDesde + ' a ' + metodo.sHasta}
        style={{margin:0,padding:0, RadioLabel: {  fontSize: "3rem"   } }}
        value={metodo.Id}  
        control=  {    <Radio  style={{ RadioLabel: {  fontSize: "3rem"   } }}/>}   
        margin="normal"
        label={<span style={{ fontSize: '2rem'  }}>{metodo.sDesde + ' a ' + metodo.sHasta} </span>}/>
        ))
      }
      </RadioGroup>
  </FormControl>
      
      
      }
      </>}
        <div style={{marginBottom: '50px'}}>
        {RangosHorarioLista &&
          <p style={{color:"red"}}>{( RangosHorarioLista.filter(r => r.iCantidad  > r.PedidosRealizados)[0] ? "":"El carrito tiene productos por cupos horarios y ya no quedan horarios para la fecha seleccionada")}</p>
        }
          <button className="primary" type="submit" disabled={RangosHorarioLista ? ( RangosHorarioLista.filter(r => r.iCantidad  > r.PedidosRealizados)[0] ? false:true): true}>
            Continuar
          </button>
        </div>
      </form>
    </div>
 
    </div>
  );
}