import { PRODUCT_LIST_REQUEST, 
    PRODUCT_LIST_SUCCESS ,
    PRODUCT_LIST_FAIL, 
    PRODUCT_DETAILS_REQUEST,
    PRODUCT_DETAILS_SUCCESS,
    PRODUCT_DETAILS_FAIL,
    PRODUCT_CATEGORY_LIST_SUCCESS,
    PRODUCT_CATEGORY_LIST_REQUEST,
    PRODUCT_CATEGORY_LIST_FAIL,
    PRODUCT_REVIEW_CREATE_REQUEST,
    PRODUCT_REVIEW_CREATE_SUCCESS,
    PRODUCT_REVIEW_CREATE_FAIL,
    PRODUCT_IMAGENES_SUCCESS
} from "../constants/productConstants";
import { EMPRESA_SUCCESS, USER_SIGNIN_SUCCESS } from "../constants/userConstants";
import { prices, ratings } from "../functions/utils";

export const listProducts = (EmpresaId, {name='', category = 0, min=0,max=0,rating =0,order=''}) => async (dispatch,getState) =>{

  if(localStorage.getItem('listaProductos'))
  {
      dispatch({type: PRODUCT_LIST_SUCCESS, payload:  JSON.parse(localStorage.getItem('listaProductos'))}); 
  }
else
{
  dispatch({  type: PRODUCT_LIST_REQUEST  });
}

 
    try{   
      var parametros = {EmpresaId: EmpresaId};
      const {  filtros: { filtroItems }  } = getState();

      if(!localStorage.getItem('Empresa'))
      {
        const responseIdentificacion = await fetch('https://twinsappservice.azurewebsites.net/api/Operadores/GetIdentificacion', {
          method: 'POST',
          headers: { 'Content-type': 'application/json; charset=UTF-8','Access-Control-Allow-Credentials':'true'},
          body: JSON.stringify(parametros)
          });    
        const dataIden = await responseIdentificacion.json();
        localStorage.setItem('Empresa', JSON.stringify(dataIden.Identificacion));
       dispatch({type: EMPRESA_SUCCESS, payload:  dataIden.Identificacion});
      }
      else
      {
        const serialisedState = JSON.parse(localStorage.getItem('Empresa'));
        dispatch({type: EMPRESA_SUCCESS, payload: serialisedState});
      } 
      if(localStorage.getItem('userInfo'))
      {
        dispatch({type: USER_SIGNIN_SUCCESS, payload:  JSON.parse(localStorage.getItem('userInfo'))});
      }
 
         var data = {EmpresaId: EmpresaId, 
          filtro: filtroItems.Palabra?filtroItems.Palabra:'', 
          Grupo:filtroItems.Grupo?filtroItems.Grupo:0 
        };

         const response = await fetch('https://twinsappservice.azurewebsites.net/api/Pedidos/ListaMercaderiasApp2', {
               method: 'POST',
               headers: { 'Content-type': 'application/json; charset=UTF-8','Access-Control-Allow-Credentials':'true'},
               body: JSON.stringify(data)
               });    
              const data2 = await response.json();
              let dtMercaderias = data2.Lista;
             

              let dataPrecio = prices.find(l => {
              return  l.name === filtroItems.Precio})

              if(filtroItems.Rating !== "Todas")
              {
                  let dataRating = ratings.find(l => { return  l.name ===  filtroItems.Rating });
                  const ratingSeleccionado = dataRating?dataRating.rating:0;
                  dtMercaderias = dtMercaderias.filter(l => {
                    return  l.rating >= ratingSeleccionado;
                }); 
              }

            const PrecioMin = dataPrecio?dataPrecio.min:0;
            const PrecioMax = dataPrecio?dataPrecio.max:0;
           
 
            if(PrecioMin>0)
            {
                dtMercaderias = dtMercaderias.filter(l => {
                return  l.nPrecio >= PrecioMin;
                });
            }
            if(PrecioMax>0)
            {
                dtMercaderias = dtMercaderias.filter(l => {
                return  l.nPrecio <= PrecioMax;
            });  }
            
           /*  if(ratingSeleccionado>0)
            {
                dtMercaderias = dtMercaderias.filter(l => {
                return  l.rating >= ratingSeleccionado;
            });  } */
      
            switch (localStorage.getItem('ListaProductosOrden')) {
              case "newest":
                    dtMercaderias = dtMercaderias.sort((a, b) => b.MercaderiaId - a.MercaderiaId);
                   break;
              case 'lowest':
                dtMercaderias = dtMercaderias.sort((a, b) => a.nPrecio - b.nPrecio);
                break;
              case 'highest':
                dtMercaderias = dtMercaderias.sort((a, b) => b.nPrecio - a.nPrecio);
                break;
                case 'toprated':
                  dtMercaderias = dtMercaderias.sort((a, b) => b.rating - a.rating);
                  break;
            default:
            }
            localStorage.removeItem('listaProductos');
            localStorage.setItem('listaProductos',  JSON.stringify(dtMercaderias ));
            dispatch({type: PRODUCT_LIST_SUCCESS, payload:  dtMercaderias});     
    }
    catch(error){
        dispatch({ type: PRODUCT_LIST_FAIL, payload: error.message });
    }
}

export const detailsProduct = (MercaderiaId) => async(dispatch) => {
    dispatch({type: PRODUCT_DETAILS_REQUEST, payload: MercaderiaId})
    try{      
        var parametros = {EmpresaId: localStorage.getItem('EmpresaId'),MercaderiaId: MercaderiaId};
        if(localStorage.getItem('userInfo'))
        {
          dispatch({type: USER_SIGNIN_SUCCESS, payload:  JSON.parse(localStorage.getItem('userInfo'))});
        }
        const response = await fetch('https://twinsappservice.azurewebsites.net/api/Pedidos/GetMercaderiaApp', {
              method: 'POST',
              headers: { 'Content-type': 'application/json; charset=UTF-8','Access-Control-Allow-Credentials':'true'},
              body: JSON.stringify(parametros)
              });    
             const data2 = await response.json();
             if(!data2 || data2.MercaderiaId === 0)
             {dispatch({ type: PRODUCT_DETAILS_FAIL, payload:  'Mercaderia No Encontrada'  });}
             else
             {
             dispatch({type: PRODUCT_DETAILS_SUCCESS, payload:  data2});
             dispatch({type: PRODUCT_IMAGENES_SUCCESS, payload:  []});
             dispatch({type: PRODUCT_IMAGENES_SUCCESS, payload:  data2.lstImagenes});
             }
   }
   catch(error){
       dispatch({ type: PRODUCT_DETAILS_FAIL, payload:  error.response && error.response.data.message
        ? error.response.data.message
        : error.message,});
   }
}

export const listProductCategories = (IdEmpresa) => async (dispatch)  => {
  if(localStorage.getItem('listaGrupos'))
  {
      dispatch({type: PRODUCT_CATEGORY_LIST_SUCCESS, payload:  JSON.parse(localStorage.getItem('listaGrupos'))}); 
  }
else
{
  dispatch({  type: PRODUCT_CATEGORY_LIST_REQUEST  });
}
 
    try {
      var parametros = {EmpresaId: localStorage.getItem('EmpresaId')};
      if(!localStorage.getItem('Empresa'))
      {
        const responseIdentificacion = await fetch('https://twinsappservice.azurewebsites.net/api/Operadores/GetIdentificacion', {
          method: 'POST',
          headers: { 'Content-type': 'application/json; charset=UTF-8','Access-Control-Allow-Credentials':'true'},
          body: JSON.stringify(parametros)
          });    
        const dataIden = await responseIdentificacion.json();
        localStorage.setItem('Empresa', JSON.stringify(dataIden.Identificacion));
       dispatch({type: EMPRESA_SUCCESS, payload:  dataIden.Identificacion});
      }
      else
      {
        const serialisedState = JSON.parse(localStorage.getItem('Empresa'));
        dispatch({type: EMPRESA_SUCCESS, payload: serialisedState});
      } 
      if(localStorage.getItem('userInfo'))
      {
        dispatch({type: USER_SIGNIN_SUCCESS, payload:  JSON.parse(localStorage.getItem('userInfo'))});
      }


      if(IdEmpresa === 0)
      { 
          dispatch({ type: PRODUCT_CATEGORY_LIST_SUCCESS, payload: []});
          localStorage.removeItem('listaGrupos');
          localStorage.setItem('listaGrupos',  JSON.stringify([] ));
    }
      else
      {
        var data = {EmpresaId: IdEmpresa };
        const response = await fetch('https://twinsappservice.azurewebsites.net/api/Pedidos/ListaGruposMercaderias', {
              method: 'POST',
              headers: { 'Content-type': 'application/json; charset=UTF-8','Access-Control-Allow-Credentials':'true'},
              body: JSON.stringify(data)
              });    
             const data2 = await response.json(); 
      dispatch({ type: PRODUCT_CATEGORY_LIST_SUCCESS, payload: data2.Lista});
      localStorage.removeItem('listaGrupos');
      localStorage.setItem('listaGrupos',  JSON.stringify(data2.Lista ));
            }
    } catch (error) {
      dispatch({ type: PRODUCT_CATEGORY_LIST_FAIL, payload: error.message });
    }
  };
  

  export const createReview = (productId, review) => async (
    dispatch,
    getState
  ) => {
    dispatch({ type: PRODUCT_REVIEW_CREATE_REQUEST });
    const {  userSignin: { userInfo }, empresaInfo: { empresaData }  } = getState();
    try {



      var parametros = {
        EmpresaId: empresaData.Id,
        MercaderiaId: productId,
        OperadorAppId: userInfo.Id,
        PersonaId: userInfo.ClienteId,
        Comentario : review.comment,
        Calificacion: review.rating,
};


      const response = await fetch('https://twinsappservice.azurewebsites.net/api/Pedidos/AgregarComentario', {
        method: 'POST',
        headers: { 'Content-type': 'application/json; charset=UTF-8','Access-Control-Allow-Credentials':'true'},
        body: JSON.stringify(parametros)
        });    
       const data = await response.json();
       alert( data );
       if(data.Message)
        { 
          dispatch({ type: PRODUCT_REVIEW_CREATE_FAIL, payload: data.Message});
        }
       else if( data !== "")
       {
         dispatch({ type: PRODUCT_REVIEW_CREATE_FAIL, payload:  data  });
        }
       else
       {
       
        dispatch({ type: PRODUCT_REVIEW_CREATE_SUCCESS, payload: review});
       }
 
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({ type: PRODUCT_REVIEW_CREATE_FAIL, payload: message });
    }
  };