import React, {useState, useEffect,useRef  } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteOrder, listOrderMine } from '../actions/orderActions';
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded'; 
import { Tooltip } from '@material-ui/core';
import VisibilityRoundedIcon from '@material-ui/icons/VisibilityRounded';   
import Mensaje from 'sweetalert';

import { InputText } from 'primereact/inputtext';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import './DataTableDemo.css';
import { ORDER_DELETE_RESET } from '../constants/orderConstants';

export default function OrderHistoryScreen2(props) {
const orderMineList =  useSelector(state => state.orderMineList)
const {loading, error, orders} = orderMineList;
const dt = useRef(null);
const dispatch = useDispatch();
useEffect(() => {
  dispatch(listOrderMine());
}, [dispatch]);

const orderDelete = useSelector((state) => state.orderDelete);
const { error: errororderDelete, success: successorderDelete } = orderDelete;
const Eliminar = (e) => { 
  Mensaje({
    title: "Esta seguro de eliminar el pedido "+e.Id+"?",
    text: "",
    icon: "warning",
    buttons: ["NO", "SI"],
    dangerMode: true,
  })
  .then((willDelete) => {
    if (willDelete) { 
       dispatch( 
        deleteOrder( e  )
      ); 
    }  
    else
    {
       
    }
  });
};

useEffect(() => {
  if(successorderDelete)
  {  
    dispatch({ type: ORDER_DELETE_RESET });
    Mensaje(" Pedido Eliminado satisfactoriamente", {
      icon: "success",
    });
    dispatch(listOrderMine());
  }
  else if(errororderDelete)
  {
    Mensaje(errororderDelete, {
      icon: "error",
    });
  }
},[dispatch, successorderDelete,errororderDelete] ) ;

const [globalFilter, setGlobalFilter] = useState(null);
const [selectedCustomers, setSelectedCustomers] = useState(null);
const renderHeader = () => {
  return (
      <div className="table-header" style={{marginBottom:'50px',marginTop:"-45px"}}>
          Lista de pedidos
          <span className="p-input-icon-left">
              <i className="pi pi-search" />
              <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Busqueda Global" />
          </span>
          
       {/*    <div style={{textAlign:'left'}}>
          <CSVLink data={orders} headers={headers} ene>Download me</CSVLink>
            </div> */}
      </div>
  );
} 
const IdBodyTemplate = (rowData) => {
  return (
      <React.Fragment>
          <span className="p-column-title">Id</span>
          {rowData.Id}
      </React.Fragment>
  );
}
const dFechaEntregaBodyTemplate = (rowData) => {
  return (
      <React.Fragment>
          <span className="p-column-title">Fecha</span>
          <span>{rowData.dFechaEntrega.substring(0,10) }</span>
      </React.Fragment>
  );
}

const TotalBodyTemplate = (rowData) => {
  return (
    <React.Fragment>
    <span className="p-column-title">Total</span>
    {rowData.TotalPedido}
</React.Fragment>
  );
}
const eliminarBodyTemplate = (rowData, column) => {
if(!rowData.bCerrado && !rowData.bAprobado )
{
  return (
<Tooltip title={<h2 style={{ color: "#FE6DFE" }}>Eliminar el pedido</h2>}>
<DeleteForeverRoundedIcon style={  {fontSize: "3rem"  ,cursor: "hand" , verticalAlign: "bottom"  }   }    onClick={() => Eliminar(rowData)} /> 
</Tooltip>
 
  );
}
}

const verBodyTemplate = (rowData, column) => {
  return (
    <Tooltip title={<h2 style={{ color: "lightgreen" }}>Mostrar detalle del pedido</h2>}>
<VisibilityRoundedIcon style={  {fontSize: "3rem"  ,cursor: "hand" , verticalAlign: "bottom"  }   }   onClick={() => {
                      props.history.push(`/order/${rowData.Id}`);
                    }} /> 
</Tooltip>
  );
}  

const header = renderHeader();


/* const onRowSelect = (event) => {
  props.history.push(`/order/${event.data.Id}`);
   
} */

return (

  loading? <LoadingBox Mensaje={'Buscando Pedidos Realizados'}></LoadingBox>:
                 error? <MessageBox variant="danger" >{error}</MessageBox>
                :
                (



<div className="datatable-doc-demo">
<div className="card">
  <DataTable ref={dt} value={orders} header={header} className="p-datatable-customers" dataKey="Id"
  rowHover globalFilter={globalFilter}
  selection={selectedCustomers} onSelectionChange={e => setSelectedCustomers(e.value)}
  

  /* onRowSelect={onRowSelect} selectionMode="single" */

  paginator rows={10} emptyMessage="No se encontraron Pedidos" currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros"
  paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" rowsPerPageOptions={[10,25,50]}


  >
     
  {/* <Column selectionMode="multiple" style={{width:'3em'}}/> */}
  <Column body={verBodyTemplate} headerStyle={{width: '3em', textAlign: 'center' , margin:'0'}} bodyStyle={{textAlign: 'center', overflow: 'visible'}} />
      <Column field="Id" header="Id"  body={IdBodyTemplate} sortable filter filterPlaceholder="Buscar por Id"></Column>
      <Column field="dFechaEntrega" body={dFechaEntregaBodyTemplate} header="Fecha" sortable filter  ></Column>
      <Column field="TotalPedido" header="Total" body={TotalBodyTemplate} sortable filter filterMatchMode="gte" filterPlaceholder="Desde"></Column>
      <Column body={eliminarBodyTemplate} headerStyle={{width: '3em', textAlign: 'center' , margin:'0'}} bodyStyle={{textAlign: 'center', overflow: 'visible'}} />
      {/* <Column field="bCerrado" header="PAGADO"></Column> 
      <Column body={eliminarBodyTemplate} headerStyle={{width: '4em', textAlign: 'center' , margin:'0'}} bodyStyle={{textAlign: 'center', overflow: 'visible'}} />
      <Column body={verBodyTemplate} headerStyle={{width: '4em', textAlign: 'center' , margin:'0'}} bodyStyle={{textAlign: 'center', overflow: 'visible'}} />*/}
  </DataTable>       
            </div>
        </div>)
);

}