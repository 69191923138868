import React, { useEffect,useState } from 'react'
import { listProducts } from '../actions/productActions';
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
import Product from '../components/Product';
import {useDispatch, useSelector } from 'react-redux' 
import { CART_EMPTY } from '../constants/cartConstants';
import { PANTALLAATRAS, PANTALLASELECCIONADA, USER_SIGNOUT } from '../constants/userConstants';
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded'; 
import {  saveFiltroGrupo, saveFiltroPalabra, saveFiltroPrecio, saveFiltroRating } from '../actions/FiltrosActions';
import { GetIdentificacion } from '../actions/userActions';


export default function HomeScreen(props) {
const EmpresaId = props.match.params.id;
const Puesto = props.match.params.puesto;
 

const filtros = useSelector((state) => state.filtros);
const { filtroItems } = filtros;
 
const empresaInfo = useSelector((state) => state.empresaInfo);
const { empresaData } = empresaInfo;

const dispatch = useDispatch();

useEffect(() => {
  dispatch(GetIdentificacion(EmpresaId))
},[dispatch,EmpresaId]);

useEffect(() => {
  if(empresaData && empresaData.bTiendaInicioPorGrupos === true)
  {
    dispatch({type: PANTALLASELECCIONADA, payload:  'Productos'}); 
    dispatch({type: PANTALLAATRAS, payload:  'Empresa/' + localStorage.getItem('EmpresaId')}); 
  }
  else
  {
      dispatch({type: PANTALLASELECCIONADA, payload:  'Menu'}); 
      dispatch({type: PANTALLAATRAS, payload:  ''}); 
  }
},[dispatch,empresaData]);

const [order, setOrder] = useState(localStorage.getItem('ListaProductosOrden'));

const productList =  useSelector(state => state.productList);
const {loading, error, products} = productList;

useEffect(() => { 

      
      if(EmpresaId !== localStorage.getItem('EmpresaId'))
      {
        localStorage.setItem('Puesto',Puesto?Puesto:'') ;
        localStorage.setItem('EmpresaId',EmpresaId) ;
        dispatch({type: CART_EMPTY});
        dispatch({ type: USER_SIGNOUT });
        dispatch(saveFiltroPrecio('Todos'));
        dispatch(saveFiltroPalabra(''));
        dispatch(saveFiltroGrupo(0));
        dispatch(saveFiltroRating('Todas'));
        localStorage.removeItem('userInfo');
        localStorage.removeItem('cartItems');
        localStorage.removeItem('shippingAddress'); 
        localStorage.removeItem('ListaProductosOrden') 
        localStorage.setItem('ListaProductosOrden','newest') 
      }
      
     dispatch(listProducts(EmpresaId,{ }))
},[dispatch,EmpresaId,order,Puesto])
const Ordenar= (orden) =>
{
  localStorage.setItem('ListaProductosOrden', orden);
  setOrder(orden);
}


const EliminarFiltro = (e) => { 
  switch (e) {
    case 1:
      dispatch(saveFiltroGrupo(0));
      break;
      case 2:
        dispatch(saveFiltroPrecio('Todos'));
        break;
        case 3:
          dispatch(saveFiltroRating('Todas'));
          break;
    default:
      break;
  }
  dispatch(listProducts(EmpresaId,{ }));
};


return (
      <div >
<div className="row">
{!loading && <div>{products.length} Resultados</div>}
{!loading && <div> <ul>
                      
                          {filtroItems.GrupoDescripcion}
                          {(filtroItems.GrupoDescripcion !== "" && filtroItems.GrupoDescripcion)  && 
                          <DeleteForeverRoundedIcon style={  {fontSize: "3rem"  ,cursor: "hand", marginLeft:"0" , verticalAlign:"Bottom"}  }    
                        onClick={() => EliminarFiltro( 1 )} /> }
                        
                         

                         {filtroItems.PrecioDescripcion}
                        {(filtroItems.PrecioDescripcion !== "" && filtroItems.PrecioDescripcion)  && 
                          <DeleteForeverRoundedIcon style={  {fontSize: "3rem"  ,cursor: "hand", marginLeft:"0" , verticalAlign:"Bottom"}  }    
                        onClick={() => EliminarFiltro( 2 )} /> }
                        
                         {filtroItems.RatingDescripcion}
                        {(filtroItems.RatingDescripcion !== "" && filtroItems.RatingDescripcion)  && 
                          <DeleteForeverRoundedIcon style={  {fontSize: "3rem"  ,cursor: "hand", marginLeft:"0" , verticalAlign:"Bottom"}  }    
                        onClick={() => EliminarFiltro( 3 )} /> }
                       
                        </ul>  
                        </div>}
{!loading && 
<div >
Ordenar Por{' '}
          <select
            value={order}
            onChange={(e) => {
              Ordenar(  e.target.value   );
            }}
          >
            <option value="newest">Mas Nuevo</option>
            <option value="lowest">Precio: mas bajo</option>
            <option value="highest">Precio: mas alto</option>
            <option value="toprated">Mejores Calificaciones</option>
          </select>
        </div>
      }
        </div>
         <div >
        {loading? <LoadingBox></LoadingBox>
        :
        error?<MessageBox variant="danger">{error}</MessageBox>
        :
<div className="row center">

            <div className="row center">
            {

              
              products.map(product => (
              <Product key={product.MercaderiaId} product={product} props={props}/>
              ))
            }
            </div>
            
            </div> 
        }     
      </div> 
      </div> 
    )
}
