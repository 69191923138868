 
import {
  CART_ADD_ITEM,
  CART_REMOVE_ITEM,
  CART_SAVE_SHIPPING_ADDRESS,
  CART_SAVE_PAYMENT_METHOD,
  CART_SAVE_TARJETA,
  CART_GET_PROMOS,CART_REMOVEPORID_ITEM, CART_SAVE_HORARIO
} from '../constants/cartConstants';
import { EMPRESA_SUCCESS } from '../constants/userConstants';

export const addToCart = (MercaderiaId, cantidad,caracteristica1,caracteristica2,caracteristica1sValor,caracteristica1sLink,
  caracteristica2sValor,caracteristica2sLink,Precio,PrecioDescuento,TipoDescuento,TipoDescuentoId) => async (dispatch, getState) => {
    var parametros = {EmpresaId: localStorage.getItem('EmpresaId'),MercaderiaId: MercaderiaId};

         let data = getState().productList.products.find(l => {
             return  l.MercaderiaId === parseInt(MercaderiaId)})
            
             if(!data)
             {
              const response = await fetch('https://twinsappservice.azurewebsites.net/api/Pedidos/GetMercaderiaCart', {
                method: 'POST',
                headers: { 'Content-type': 'application/json; charset=UTF-8','Access-Control-Allow-Credentials':'true'},
                body: JSON.stringify(parametros)
                });    
               data = await response.json();
             }
             let Link = data.LinkFoto;
             let Descripcion = data.MercaderiaDescripcion;
                  if(caracteristica1)
                  {
                    Descripcion    = Descripcion + ' ' + caracteristica1sValor   
                  } 
                  if(caracteristica2)
                  {
                    Descripcion   =  Descripcion + ' ' + caracteristica2sValor  
                  } 
              if (caracteristica1sLink && caracteristica1sLink !== "")
              {Link=caracteristica1sLink}
              else if (caracteristica2sLink && caracteristica2sLink !== "")
              {Link=caracteristica2sLink}

  dispatch({
    type: CART_ADD_ITEM,
    payload: {
      name: Descripcion,
      image: Link,
      price: Precio ? Precio: data.nPrecio,
      countInStock: data.nStock,
      product: data.MercaderiaId,
      cantidad,
      Medicion: data.Medicion,
      MedicionPresentacion: data.MedicionPresentacion,
      UnidadId: data.UnidadId,
      CantidadEstandar: data.CantidadEstandar,
      caracteristica1:caracteristica1?caracteristica1:0,
      caracteristica2:caracteristica2?caracteristica2:0,
      caracteristica1sValor:caracteristica1sValor?caracteristica1sValor:"",
      caracteristica2sValor:caracteristica2sValor?caracteristica2sValor:"",
      caracteristica1sLink:caracteristica1sLink?caracteristica1sLink:"",
      caracteristica2sLink:caracteristica2sLink?caracteristica2sLink:"",
      nPrecioDescuento:PrecioDescuento?PrecioDescuento:(Precio ? Precio: data.nPrecio),
      TipoDescuento:TipoDescuento?TipoDescuento:"",
      TipoDescuentoId:TipoDescuentoId?TipoDescuentoId:0,
      bConsumeCupoHorario:data.bConsumeCupoHorario
    },
  });
  localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems));
};

export const removeFromCart = (MercaderiaId) => (dispatch, getState) => {
    dispatch({ type: CART_REMOVE_ITEM, payload: MercaderiaId });
    localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems));
  };

  export const removeFromCartPorId = (MercaderiaId) => (dispatch, getState) => {
    dispatch({ type: CART_REMOVEPORID_ITEM, payload: MercaderiaId });
    localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems));
  };
  
  export const saveShippingAddress = (data) => (dispatch) => {
    dispatch({ type: CART_SAVE_SHIPPING_ADDRESS, payload: data });
    localStorage.setItem('shippingAddress', JSON.stringify(data));
    
  };

  export const savePaymentMethod = (data) =>   async(dispatch) => {
    var parametros = {EmpresaId: localStorage.getItem('EmpresaId')};
    if(!localStorage.getItem('Empresa'))
    {
      const responseIdentificacion = await fetch('https://twinsappservice.azurewebsites.net/api/Operadores/GetIdentificacion', {
        method: 'POST',
        headers: { 'Content-type': 'application/json; charset=UTF-8','Access-Control-Allow-Credentials':'true'},
        body: JSON.stringify(parametros)
        });    
      const dataIden = await responseIdentificacion.json();
      localStorage.setItem('Empresa', JSON.stringify(dataIden.Identificacion));
     dispatch({type: EMPRESA_SUCCESS, payload:  dataIden.Identificacion});
    }
    else
    {
      const serialisedState = JSON.parse(localStorage.getItem('Empresa'));
      dispatch({type: EMPRESA_SUCCESS, payload: serialisedState});
    }
    dispatch({ type: CART_SAVE_PAYMENT_METHOD, payload: data });
  };

  export const saveTarjeta = (data) => (dispatch) => {
    dispatch({ type: CART_SAVE_TARJETA, payload: data });
    localStorage.setItem('tarjeta', JSON.stringify(data));
  };

  export const saveHorario = (data) => (dispatch) => {
    dispatch({ type: CART_SAVE_HORARIO, payload: data });
  };

  export const promosCart = () => async (dispatch, getState) => {
    var parametros = {EmpresaId: localStorage.getItem('EmpresaId'),lstCarrito: getState().cart.cartItems};



    const response = await fetch('https://twinsappservice.azurewebsites.net/api/Pedidos/CalcularPromos', {
    method: 'POST',
    headers: { 'Content-type': 'application/json; charset=UTF-8','Access-Control-Allow-Credentials':'true'},
    body: JSON.stringify(parametros)
    });    
    const data = await response.json();          
  dispatch({
    type: CART_GET_PROMOS,
    payload: data,
  });
 
};
