import React, { useEffect,useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { detailsUser,updateUserProfile } from '../actions/userActions';
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox'; 
import { USER_UPDATE_PROFILE_RESET } from '../constants/userConstants';
import swal from 'sweetalert';
export default function ProfileScreen() {

  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;

  const [nombre, setNombre] = useState(userInfo.Nombre);
  const [apellido, setApellido] = useState(userInfo.Apellido);
  const [email, setEmail] = useState(userInfo.Email);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');


  const userDetails = useSelector((state) => state.userDetails);
  const {  user } = userDetails;

  const userUpdateProfile = useSelector((state) => state.userUpdateProfile);
  const { loading: loadingUpdate, error: errorUpdate, success: successUpdate } = userUpdateProfile;

  const dispatch = useDispatch();
  useEffect(() => {

if(successUpdate)
{
    if(!user){
        dispatch({type: USER_UPDATE_PROFILE_RESET})
        dispatch(detailsUser(userInfo.Id));
    }else{
        setNombre(user.Nombre);
        setApellido(user.Apellido);
        setEmail(user.Email);
    }

    dispatch(detailsUser(userInfo.Id));
    swal("Su perfil ha sido actualizado", {
      icon: "success",
    });
  }
  } ,[user, dispatch, userInfo.Id,successUpdate]);

  const submitHandler = (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
        alert('Password y Confirma Password no coinciden');
      } else {
        dispatch(updateUserProfile({ Id: userInfo.Id, nombre, apellido, email, password }));
      }
  };


  return (
    <div>
      <form className="form" onSubmit={submitHandler}>
        <div>
          <h1>Perfil de Usuario</h1>
        </div>



        {loadingUpdate && <LoadingBox Mensaje={'Actualizando el perfil...'}></LoadingBox>}
            {errorUpdate && (
              <MessageBox variant="danger">{errorUpdate}</MessageBox>
            )}
            {successUpdate && (
              <MessageBox variant="success">
                Perfil actualizado satisfactoriamente
              </MessageBox>
            )}

            <div>
              <label htmlFor="Nombre">Nombre</label>
              <input
                id="Nombre"
                type="text"
                required
                placeholder="Ingrese Nombre"
                value={nombre}
                onChange={(e) => setNombre(e.target.value)}
              ></input>
            </div>
            <div>
              <label htmlFor="Apellido">Nombre</label>
              <input
                id="Apellido"
                type="text"
                required
                placeholder="Ingrese Apellido"
                value={apellido}
                onChange={(e) => setApellido(e.target.value)}
              ></input>
            </div>
            <div>
              <label htmlFor="Email">Email</label>
              <input
                id="Email"
                type="email"
                required
                placeholder="Ingrese email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              ></input>
            </div>
            <div>
              <label htmlFor="password">Password</label>
              <input
                id="password"
                type="password"
                required
                placeholder="Ingrese password"
                onChange={(e) => setPassword(e.target.value)}
              ></input>
            </div>
            <div>
              <label htmlFor="confirmPassword">confirm Password</label>
              <input
                id="confirmPassword"
                type="password"
                required
                placeholder="confirmar password"
                onChange={(e) => setConfirmPassword(e.target.value)}
              ></input>
            </div>
            <div>
              <label />
              <button className="primary" type="submit">
                Modificar
              </button>
            </div>
         
        
      </form>
    </div>
  );
 

  /* return (
    <div>
      <form className="form" onSubmit={submitHandler}>
        <div>
          <h1>Perfil de Usuario</h1>
        </div>
        {loading ? (
          <LoadingBox></LoadingBox>
        ) : error ? (
          <MessageBox variant="danger">{error}</MessageBox>
        ) : (
          <>
            {loadingUpdate && <LoadingBox></LoadingBox>}
            {errorUpdate && (
              <MessageBox variant="danger">{errorUpdate}</MessageBox>
            )}
            {successUpdate && (
              <MessageBox variant="success">
                Perfil actualizado satisfactoriamente
              </MessageBox>
            )}

            <div>
              <label htmlFor="Nombre">Nombre</label>
              <input
                id="Nombre"
                type="text"
                required
                placeholder="Ingrese Nombre"
                value={nombre}
                onChange={(e) => setNombre(e.target.value)}
              ></input>
            </div>
            <div>
              <label htmlFor="Apellido">Nombre</label>
              <input
                id="Apellido"
                type="text"
                required
                placeholder="Ingrese Apellido"
                value={apellido}
                onChange={(e) => setApellido(e.target.value)}
              ></input>
            </div>
            <div>
              <label htmlFor="Email">Email</label>
              <input
                id="Email"
                type="email"
                required
                placeholder="Ingrese email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              ></input>
            </div>
            <div>
              <label htmlFor="password">Password</label>
              <input
                id="password"
                type="password"
                required
                placeholder="Ingrese password"
                onChange={(e) => setPassword(e.target.value)}
              ></input>
            </div>
            <div>
              <label htmlFor="confirmPassword">confirm Password</label>
              <input
                id="confirmPassword"
                type="password"
                required
                placeholder="confirmar password"
                onChange={(e) => setConfirmPassword(e.target.value)}
              ></input>
            </div>
            <div>
              <label />
              <button className="primary" type="submit">
                Modificar
              </button>
            </div>
          </>
        )}
      </form>
    </div>
  ); */
}