import React, { useEffect,useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import CheckoutSteps from '../components/CheckoutSteps';
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
import { createOrder } from '../actions/orderActions';
import Tarjeta from '../components/Tarjeta';
import { promosCart, saveTarjeta } from '../actions/cartActions';
import { ORDER_CREATE_RESET } from '../constants/orderConstants';
import { PANTALLAATRAS, PANTALLASELECCIONADA } from '../constants/userConstants';

export default function PlaceOrderScreen(props) {
  const cart = useSelector((state) => state.cart);

  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;

  const RangosHorario =  useSelector(state => state.RangosHorario)
  const { RangosHorarioLista} = RangosHorario;

  if (!userInfo) {
    props.history.push('/empresa/'+ localStorage.getItem('EmpresaId'));
  }
 
 
  if (!cart.paymentMethod) {
    props.history.push('/payment');
  }
 
  var TipoPago = cart.paymentMethod.Descripcion
  if(cart.paymentMethod.TipoId === 3)
      TipoPago = 'Tarjeta'

  const orderCreate = useSelector((state) => state.orderCreate);
  const {loading, success, error, order} = orderCreate;

  const toPrice = (num) => Number(num.toFixed(2)); // 5.123 => "5.12" => 5.12
   cart.itemsPrice = toPrice(
    cart.cartItems.reduce((a, c) => a + c.cantidad * (cart.paymentMethod.TipoId === c.TipoDescuentoId ? c.price: c.nPrecioDescuento), 0)
   );


  cart.shippingPrice = cart.shippingAddress.CostoEnvio;
 
  cart.descuentos = cart.promociones.TotalMontoDescuento + (   
    cart.cartItems.reduce((a, c) => a + c.cantidad *   c.nPrecioDescuento , 0)
    - cart.cartItems.reduce((a, c) => a + c.cantidad * ((cart.paymentMethod.TipoId === c.TipoDescuentoId || c.TipoDescuentoId===0) ? c.price: c.nPrecioDescuento), 0)

   ) ;
 

  cart.totalPrice = cart.itemsPrice + cart.shippingPrice -   cart.descuentos  ;
 
  const [observacion, setObservacion] = useState("");
  const [tarjetaNumero, setTarjetaNumero] = useState(cart.paymentMethod.TarjetaId);
  const [tarjetaCodigo, setTarjetaCodigo] = useState(cart.paymentMethod.TarjetaCodigoSeguridad);
  const [tarjetaNombre, setTarjetaNombre] = useState(cart.paymentMethod.Descripcion3);
  const [tarjetaExpira, setTarjetaExpira] = useState(cart.paymentMethod.TarjetaMes + '/' + cart.paymentMethod.TarjetaAnio.substring(2, 4));

  const dispatch = useDispatch();
  useEffect(() => {
      dispatch(saveTarjeta({Numero:tarjetaNumero,Codigo: tarjetaCodigo,Nombre: tarjetaNombre,Expira:tarjetaExpira}));
  }, [dispatch, tarjetaNumero, tarjetaCodigo,tarjetaNombre,tarjetaExpira]);


  const EnviarNumeroTarjeta = (tarjeta) =>  
  {
    setTarjetaNumero(tarjeta); 
  }
  const EnviarNombreTarjeta = (tarjeta) =>  
  {
    setTarjetaNombre(tarjeta); 
  }
  const EnviarExpiraTarjeta = (tarjeta) =>  
  {
    setTarjetaExpira(tarjeta); 
  }
  const EnviarCodigoTarjeta = (tarjeta) =>  
  {
    setTarjetaCodigo(tarjeta); 
  }
 
  const placeOrderHandler = () => {
   
     dispatch(createOrder({...cart, orderItems: cart.cartItems},observacion));
  };

  useEffect(() => {
    if (success) {
      props.history.push(`/order/${order}`);
     // props.history.push(`/order/4073`);
      dispatch({ type: ORDER_CREATE_RESET });
    }
  }, [dispatch, order, props.history, success]);




  useEffect(() => {

    /* setTimeout(() => { 
      props.history.push('/');
  }, 50000) */
  dispatch({type: PANTALLASELECCIONADA, payload:  'Comprar'}); 
  if(RangosHorarioLista && RangosHorarioLista.length > 0 )
  dispatch({type: PANTALLAATRAS, payload: 'Horario'}); 
  else
  dispatch({type: PANTALLAATRAS, payload: 'payment'}); 
  
    dispatch(promosCart());
}, [dispatch,RangosHorarioLista]);

  return (
    <div>
      <CheckoutSteps step1 step2 step3 step4 step5></CheckoutSteps>
      <div className="row top">
        <div className="col-2">
          <ul>
            <li>
              <div className="card card-body">
                <h2>Comprando</h2>
                <p>
                  <strong>Nombre:</strong> {userInfo?  userInfo.Nombre + ' ' + userInfo.Apellido:''} <br />
                  <strong>Direccion: </strong> {cart.shippingAddress.Direccion},
                  {cart.shippingAddress.LocalidadDescripcion} 
                  {cart.horario && <><br />
                  <strong>Horario Entrega:</strong> { cart.horario.sDesde + ' a ' + cart.horario.sHasta} 
                  </>}
                </p>
              </div>
            </li>
            <li>
              <div className="card card-body">
                <h2>Pagar</h2>
                <p>
                  <strong>Metodo:</strong> {TipoPago}
                </p>
              </div>
            </li>
            <li>
              <div className="card card-body">
                <h2>Items Pedidos</h2>
                <ul className="categoriesli">
                  {cart.cartItems.map((item) => (
                    <li key={item.product}>
                      <div className="row">
                        <div>
                        {item.image && String(item.image) !== "https://twinsarchivos.blob.core.windows.net/imagenesabmmercaderias/Producto.png" ?

                          <img
                            src={item.image}
                            alt={item.name}
                            className="small2"
                          ></img>:<></>}
                        </div>
                        <div  >
                          <Link to={`/product/${item.product}`}>
                          {item.cantidad} x {item.name}
                          </Link>
                        </div>
                       
                        {(cart.paymentMethod.TipoId !== item.TipoDescuentoId && item.TipoDescuentoId !== 0) &&
                        <div>
                           ${item.nPrecioDescuento} = ${item.cantidad * item.nPrecioDescuento}
                        </div>}

                        {(cart.paymentMethod.TipoId === item.TipoDescuentoId || item.TipoDescuentoId === 0) &&
                        <div>
                          {item.price !== item.nPrecioDescuento && <label style={{textDecoration:"line-through",marginRight:"10px"}}>${item.nPrecioDescuento} </label>  } 
                         ${item.price} 
                        {item.TipoDescuento !== "" && <label>({item.TipoDescuento})</label>}
                        </div>}


                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </li>
          </ul>
        </div>
        <div className="col-1">
          <div className="card card-body">
            <ul>
              <li>
                <h2>Resumen del Pedido</h2>
              </li>
              <li>
                <div className="row">
                  <div>Items</div>
                  <div>${cart.cartItems.reduce((a, c) => a + c.cantidad *   c.nPrecioDescuento , 0).toFixed(2)}</div>
                </div>
              </li>
              <li>
                <div className="row">
                  <div>Costo de Envio</div>
                  <div>${cart.shippingPrice.toFixed(2)}</div>
                </div>
              </li>
              { (cart.promociones  )&&
              (
              <li>
                <div className="row">
                  <div>Descuentos/Promociones</div>
                  <div>-${cart.descuentos.toFixed(2)}</div>
                </div>
              </li>
              )}
              <li>
                <div className="row">
                  <div>
                    <strong>Total Compra</strong>
                  </div>
                  <div>
                    <strong>${cart.totalPrice.toFixed(2)}</strong>
                  </div>
                </div>
              </li>
              <li>

              
               { cart.paymentMethod.TipoId  === 3  &&  
                 <Tarjeta EnviarNumeroTarjeta={EnviarNumeroTarjeta} 
                          EnviarNombreTarjeta={EnviarNombreTarjeta}
                          EnviarExpiraTarjeta={EnviarExpiraTarjeta}
                          EnviarCodigoTarjeta={EnviarCodigoTarjeta}
                       numero=  {tarjetaNumero}
                       nombre=  {tarjetaNombre}
                       expira=  {tarjetaExpira}
                       codigo=  {tarjetaCodigo}
                       />

                 }
                <li>
                <div  >
                  <div>
                    <strong>Comentario</strong>
                  </div>
                  <div>
                  <textarea
                        id="comment" style={{width:"100%",}} maxLength="200"
                        value={observacion}
                        onChange={(e) => setObservacion(e.target.value)}
                      ></textarea>
                  </div>
                </div>
              </li>
 

                <button
                  type="button"
                  onClick={placeOrderHandler}
                  className="primary block"
                  disabled={cart.cartItems.length === 0}
                >
                  Realizar Compra
                </button>
              </li>

              
              {loading && <LoadingBox Mensaje={'Generando Pedido, por favor aguarde unos instantes'}></LoadingBox>}
              {error && <MessageBox variant="danger">{error}</MessageBox>}

            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}