
import { useDispatch, useSelector} from 'react-redux';
import { BrowserRouter, Link, Route  } from 'react-router-dom';
import HomeScreen from './screens/HomeScreen';
import ProductScreen from './screens/ProductScreen';
import CartScreen from './screens/CartScreen';
import SigninScreen from './screens/SigninScreen';
import { signout } from './actions/userActions';
import RegisterScreen from './screens/RegisterScreen';
import ShippingAddressScreen from './screens/ShippingAddressScreen';
import PaymentMethodScreen from './screens/PaymentMethodScreen';
import PlaceOrderScreen from './screens/PlaceOrderScreen';
import OrderScreen from './screens/OrderScreen'; 
import ProfileScreen from './screens/ProfileScreen';
import PrivateRoute from './components/PrivateRoute'; 
import SearchBox from './components/SearchBox';
import SearchScreen from './screens/SearchScreen';
import { listProductCategories, listProducts } from './actions/productActions';
import { useEffect,useState  } from 'react'; 
import NuevaDireccionEntrega from './screens/NuevaDireccionEntrega';
import { makeStyles } from '@material-ui/core/styles'; 
import EmpresasScreen from './screens/EmpresasScreen';
import ShoppingIcon from '@material-ui/icons/ShoppingCartOutlined';
import ShoppingIconLleno from '@material-ui/icons/ShoppingCart';  
import OrderHistoryScreen2 from './screens/OrderHistoryScreen2';
import RadioButtonUncheckedOutlinedIcon from '@material-ui/icons/RadioButtonUncheckedOutlined';
import RadioButtonCheckedOutlinedIcon from '@material-ui/icons/RadioButtonCheckedOutlined';
import { prices, ratings } from './functions/utils';
import { saveFiltroGrupo, saveFiltroPrecio, saveFiltroRating } from './actions/FiltrosActions';
import Rating from './components/Rating';
import ExitToAppIcon from '@material-ui/icons/ExitToApp'; 
import StoreIcon from '@material-ui/icons/Store';
import { Tooltip } from '@material-ui/core';
import { Sidebar } from 'primereact/sidebar';
import HorarioScreen from './screens/HorarioScreen';
import InfoIcon from '@material-ui/icons/Info';
import ReactWhatsapp from 'react-whatsapp';

import HomeScreenGrupos from './screens/HomeScreenGrupos';
import AboutScreen from './screens/AboutScreen';
const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(0),
    alignContent: 'center',
    width: 'Auto',
    fontSize: 35
  },
  button2: {
    margin: theme.spacing(0),
    alignContent: 'center',
    width: 'Auto',
    fontSize: 24
  },
}));

function App() {
const classes = useStyles();
const [sidebarIsOpen, setSidebarIsOpen] = useState(false);
const cart = useSelector((state) => state.cart);
const { cartItems } = cart;
const filtros = useSelector((state) => state.filtros);
const { filtroItems } = filtros;
const userSignin = useSelector((state) => state.userSignin);
const {userInfo} = userSignin;

const empresaInfo = useSelector((state) => state.empresaInfo);
const { empresaData } = empresaInfo;

const NombreEmpresa = empresaData? empresaData.sDescripcion :"" ;
const IdEmpresa = empresaData? empresaData.Id :0 ;
 

const pantallaSeleccionada = useSelector((state) => state.pantallaSeleccionada);
const {pantallaInfo} = pantallaSeleccionada;
const pantallaAtras= useSelector((state) => state.pantallaAtras);
const {pantallaAtrasInfo} = pantallaAtras;


const dispatch = useDispatch();
  const signoutHandler = () => {
    dispatch(signout());
    setSidebarIsOpen(false)
  }; 

  const productCategoryList = useSelector((state) => state.productCategoryList);
  const {
    categories,
  } = productCategoryList;
  useEffect(() => {
    dispatch(listProductCategories(IdEmpresa));
  }, [dispatch,IdEmpresa]);

  const setearGrupo = (e,h) => {
    dispatch(saveFiltroGrupo(e,h)); 
    //setSidebarIsOpen(false)
    dispatch(listProducts(localStorage.getItem('EmpresaId'),{ }));
  };

  const setearPrecio = (e) => {
     
    dispatch(saveFiltroPrecio(e)); 
    //setSidebarIsOpen(false)
    dispatch(listProducts(localStorage.getItem('EmpresaId'),{ }));
  };

  const setearRating = (e) => {
    dispatch(saveFiltroRating(e)); 
    //setSidebarIsOpen(false)
    dispatch(listProducts(localStorage.getItem('EmpresaId'),{ }));
  };
 

  return (
    <BrowserRouter>

    <div className="grid-container" style={{backgroundColor:empresaData?empresaData.ColorFondo:"White"}}>
    {IdEmpresa > 0 && (
    <header   style={{ zIndex: 20, backgroundColor:empresaData.ColorEncabezado}}>
              <tbody>
                <tr>
              {  (!sidebarIsOpen) ?
                 <td width="Auto"><Tooltip title={<h5 style={{ color: "lightgreen" }}>Abrir el menu</h5>}><button type="button" className="open-sidebar" onClick={() => setSidebarIsOpen(true)}  > <i className="fa fa-bars" style={{ color:empresaData.ColorFuenteEncabezado}}></i> </button></Tooltip></td>
                :<td width="Auto"><Tooltip title={<h5 style={{ color: "lightgreen" }}>Cerrar el menu</h5>}><button type="button" className="open-sidebar" onClick={() => setSidebarIsOpen(false)}  > <i className="fa fa-arrow-left" style={{ color:empresaData.ColorFuenteEncabezado}}></i> </button></Tooltip></td>
              }
              {pantallaInfo !== "Menu" && pantallaInfo !== "MenuGrupos" ?
            <td width="Auto">   <Link style={{ fontSize: "2rem",color:empresaData.ColorFuenteEncabezado,cursor: "pointer", padding:"0"}} to={"/"+( pantallaAtrasInfo ? pantallaAtrasInfo :'/Empresa/' + localStorage.getItem('EmpresaId'))}>
            <div style={{padding:"0",margin:'0'}}> 
                <i className="fa fa-arrow-left" aria-hidden="true" style={{ fontSize: "3rem", color:empresaData.ColorFuenteEncabezado,margin:'0', padding:"0"}}/> 
            </div>
        </Link>
                </td>
                :
                <Tooltip title={<h5 style={{ color: "lightgreen" }}>Volver a la Tienda</h5>}> 
                <td width="Auto"> <Link to={'/Empresa/' + localStorage.getItem('EmpresaId')}>
                            <StoreIcon  className={classes.button} style={{ color:empresaData.ColorFuenteEncabezado}}/>
                    </Link>
                </td>
                </Tooltip>
                }
                <td width="100%">  
                <div>
                <Tooltip title={<h5 style={{ color: "lightgreen" }}>Volver a la Tienda</h5>}> 
                <Link to={'/Empresa/' + localStorage.getItem('EmpresaId')} style={{ color:empresaData.ColorFuenteEncabezado}} className="brand">{NombreEmpresa}</Link>
                </Tooltip> 
                </div>
                  </td>
               {empresaData.bSoloCatalogo === false &&
              <td width="Auto">  
                <Link to="/cart" onClick={() => setSidebarIsOpen(false)} >  
                <tbody>
                  <td>
                  <Tooltip title={<h5 style={{ color: "lightgreen" }}>Ver carrito de compras</h5>}>
                  {cartItems.length > 0 ? (
                    <ShoppingIconLleno className={classes.button}/>) : (
                    <ShoppingIcon className={classes.button}   />)}
                    </Tooltip>
                    </td>
                  <td> {cartItems.length > 0 && (
                <span className="badge">{cartItems.length}</span>
              )}
              </td>
               
                </tbody>
                </Link>
                </td>}

                <td width="Auto">
                {userInfo ? (
                   <Tooltip title={<h5 style={{ color: "lightgreen" }}>Salir del sistema</h5>}>
                  <Link to="#signout" style={{color: empresaData.ColorFuenteEncabezado}} activeStyle={{color: 'red'}} onClick={signoutHandler}>  
                  <tbody><td><ExitToAppIcon className={classes.button}/></td><td><span></span></td></tbody>
                  </Link>
                  </Tooltip>
            ) : (
              <Tooltip title={<h5 style={{ color: "#FF70FF" }}>Ingresar al sistema</h5>}>
                    <Link to="/signin" onClick={() => setSidebarIsOpen(false)} style={{color: empresaData.ColorFuenteEncabezado}} activeStyle={{color: 'red'}}>  
                      <tbody><td><span ><i className="fa fa-user-circle" style={{fontSize: '32px'}}  aria-hidden="true"></i> </span></td></tbody>
                </Link>
                </Tooltip>
 
            )}        
             </td>
                </tr>
                <tr>
            <td colSpan="5" width="100%" align="center">
           { ( pantallaInfo === "Menu" || pantallaInfo === "Productos" ) 
           &&
              <Route render={({ history }) => (<SearchBox history={history}></SearchBox>)}>             
                </Route> }
              </td>  
                </tr>
              </tbody>            
    </header>
 )}
{IdEmpresa === 0 && (
    <header className="row">
      
            <div className="TextoRojo">
             <h3 > EMPRESAS REGISTRADAS EN SWINT DIGITAL </h3>  
          </div>  
           
    </header>
 )}
{IdEmpresa > 0 && (
    <Sidebar style={{overflowY:'auto', backgroundColor:empresaData.ColorMenu,minWidth:"220px" }} backgroundColor={empresaData.ColorMenu}   visible={sidebarIsOpen} baseZIndex={1000000} onHide={() => setSidebarIsOpen(false)}>
    <div>
    <div className="container">
    <img className="large2" src={"https://twinsarchivos.blob.core.windows.net/logosempresas/"+ localStorage.getItem('EmpresaId')+".png"} alt='imslogo' />

    </div>

    </div>  

         
 
  

          {userInfo && <strong style={{color: empresaData.ColorFuenteEncabezado}}>Bienvenido {userInfo.Nombre} </strong>}
 
               
               
            <ul className="categories">
                <li>
                    <Link to={'/Empresa/' + localStorage.getItem('EmpresaId')} onClick={() => setSidebarIsOpen(false)} style={{color: empresaData.ColorFuenteEncabezado}} activeStyle={{color: 'red'}}>  
                      <tbody><td><StoreIcon className={classes.button2}/></td><td><span style={{marginLeft:'10px'}}>Tienda</span></td></tbody>
                </Link>
            </li>
          {userInfo ? (
              <div>
 
                <li>
                    <Link to="/Perfil" onClick={() => setSidebarIsOpen(false)} style={{color:empresaData.ColorFuenteEncabezado}} activeStyle={{color: 'red'}}>  
                      <tbody><td>  <i className="fa fa-id-card"></i>  </td><td><span style={{marginLeft:'10px'}}> Mis Datos</span></td></tbody>
                </Link>
            </li>
            <li>
                    <Link to="/orderhistory" onClick={() => setSidebarIsOpen(false)} style={{color: empresaData.ColorFuenteEncabezado}} activeStyle={{color: 'red'}}>  
                    <tbody><td>  <i className="fa fa-shopping-basket"></i>  </td><td><span style={{marginLeft:'10px'}}>Mis Pedidos</span></td></tbody>
                    
                </Link>
            </li>
            <li>
                    <Link to="#signout"   style={{color: empresaData.ColorFuenteEncabezado}} activeStyle={{color: 'red'}} onClick={signoutHandler}>  
                      <tbody><td><i className="fa fa-sign-out" aria-hidden="true"></i></td><td><span style={{marginLeft:'10px'}}> Salir</span></td></tbody>
                </Link>
            </li>
  
                
              </div>
            ) : (
              <div>
              <ul > 
                <li>
                    <Link to="/signin" onClick={() => setSidebarIsOpen(false)} style={{color: empresaData.ColorFuenteEncabezado}} activeStyle={{color: 'red'}}>  
                      <tbody><td><i className="fa fa-user-circle" style={{fontSize: '24px', marginRight: '5px'}}  aria-hidden="true"></i> </td><td>
                        <span>Ingresar</span></td></tbody>
                </Link>
            </li>
     
          </ul>
               </div>
            )}        
</ul>
              <ul className="categories"><li>
                    <Link to={'/About/'} onClick={() => setSidebarIsOpen(false)} style={{color: empresaData.ColorFuenteEncabezado}} activeStyle={{color: 'red'}}>  
                      <tbody><td><InfoIcon className={classes.button2}/></td><td><span style={{marginLeft:'10px'}}>Nosotros</span></td></tbody>
                </Link>
            </li></ul>



        {(pantallaInfo === 'Productos' || (empresaData && empresaData.bTiendaInicioPorGrupos === false && pantallaInfo === 'Menu')) &&
          <ul className="categories">
            <li  >
              <h2 style={{marginBottom:"0px",paddingBottom:"0px" ,textDecorationLine:"underline",color: empresaData.ColorFuenteEncabezado }} >Categorias</h2>
            </li>
            <li className={String( filtroItems.Grupo) === "0" || !filtroItems.Grupo ? 'liTildado' : ''}  
            onClick={() => setearGrupo(0,'')} style={{cursor:"pointer",color: empresaData.ColorFuenteEncabezado}}>
            {String( filtroItems.Grupo) === "0" || filtroItems.Grupo === null ? <RadioButtonCheckedOutlinedIcon/> : <RadioButtonUncheckedOutlinedIcon/>}
              {' '  }   Todas
            </li>           
            {!categories ? (
              <></>
            ) : 
            (         
              categories.map((c) => (
                <li className={filtroItems.Grupo === c.Grupo_Id ? 'liTildado' : ''} style={{cursor:"pointer",color: empresaData.ColorFuenteEncabezado}} key={c.Grupo_Id} onClick={() => setearGrupo(c.Grupo_Id,c.Descripcion)}>
                   {filtroItems.Grupo === c.Grupo_Id  ? <RadioButtonCheckedOutlinedIcon/> : <RadioButtonUncheckedOutlinedIcon/>}             
                   {' ' + c.Descripcion} </li>         
            ))           
            )}
          </ul>}


          {((pantallaInfo === 'Productos') || (empresaData && empresaData.bTiendaInicioPorGrupos === false && pantallaInfo === 'Menu')) &&
          <ul className="categories">
          <li>
          <h2 style={{marginBottom:"0px",paddingBottom:"0px", fontStyle:"unset", textDecorationLine:"underline",color: empresaData.ColorFuenteEncabezado}} >Precio</h2>
            </li>
            {prices.map((c) => (
                <li className={filtroItems.Precio === c.name ? 'liTildado' : ''} style={{cursor:"pointer",color: empresaData.ColorFuenteEncabezado}} key={c.name} onClick={() => setearPrecio(c.name)}>
                   {filtroItems.Precio === c.name  ? <RadioButtonCheckedOutlinedIcon/> : <RadioButtonUncheckedOutlinedIcon/>}              
                   {' ' + c.name} </li>          
            ))}            
            </ul>}


            {(pantallaInfo === 'Productos' || (empresaData && empresaData.bTiendaInicioPorGrupos === false && pantallaInfo === 'Menu')) &&
            <ul className="categories">
              <li>
              <h2 style={{marginBottom:"0px",paddingBottom:"0px", textDecorationLine:"underline",color: empresaData.ColorFuenteEncabezado}} >Calificaciones</h2>
              </li>
            <li className={String( filtroItems.Rating) === "Todas" || !filtroItems.Rating ? 'liTildado' : ''}  
            onClick={() => setearRating("Todas")} style={{cursor:"pointer",color: empresaData.ColorFuenteEncabezado}}>
            {String( filtroItems.Rating) === "Todas" || filtroItems.Rating === null ? <RadioButtonCheckedOutlinedIcon/> : <RadioButtonUncheckedOutlinedIcon/>}
               Todas
            </li>   
            {ratings.map((c) => (
                <li className={filtroItems.Rating === c.name ? 'liTildado' : ''} style={{cursor:"pointer",color: empresaData.ColorFuenteEncabezado}} key={c.name} onClick={() => setearRating(c.name)}>
                <div className="row">{filtroItems.Rating === c.name  ? <RadioButtonCheckedOutlinedIcon/> : <RadioButtonUncheckedOutlinedIcon/>}              
                <Rating style={{textalign:"left",color: empresaData.ColorFuenteEncabezado}} caption={' o mas'} rating={c.rating}></Rating></div> </li> 
            ))}            
            </ul>}






        </Sidebar>
     )}
    
    <main style={{backgroundColor:empresaData?empresaData.ColorFondo:"White"}}>

    { (empresaData && empresaData.Id !== 328 && empresaData.sWhatsApp !== '')  &&
    <div style={{ position: 'fixed',zIndex: 1, bottom: 0,  right: 0,  justifyContent: 'center', alignItems: 'center', marginBottom:"30px"}} >
                <Tooltip title={<h4 style={{ color: "#FF70FF" }}>Whatsapp</h4>}> 
                                   <ReactWhatsapp number={empresaData.sWhatsApp} message="" className="fa fa-whatsapp" style={{fontSize:'48px',color:'white',zIndex:"100",cursor:'pointer',
                                 marginRight:"20px" ,background:"#24C049"}}/> 
                                </Tooltip>
                </div>
      }
        <Route path='/cart/:id/:cantidad/:caracteristica1/:caracteristica2' component={CartScreen} ></Route>
        <Route path='/cart/' component={CartScreen} exact></Route>
        <Route path='/product/:id' component={ProductScreen} ></Route>
        <Route path='/signin' component={SigninScreen} ></Route>
        <Route path='/About' component={AboutScreen} ></Route>
        <Route path='/register' component={RegisterScreen} ></Route>
        <Route path='/shipping' component={ShippingAddressScreen} ></Route>
        <Route path='/payment' component={PaymentMethodScreen} ></Route>
        <Route path='/placeorder' component={PlaceOrderScreen} ></Route>
        <Route path='/order/:id' component={OrderScreen} ></Route>
        <Route path='/nuevadireccion' component={NuevaDireccionEntrega} ></Route>
        <Route path='/Horario' component={HorarioScreen}></Route>
        <Route  path="/search/name/:name?" component={SearchScreen} exact></Route>
        <Route  path="/search/category/:category"  component={SearchScreen} exact></Route>
        <Route  path="/search/category/:category/name/:name"  component={SearchScreen}  exact></Route>
        <Route path="/search/category/:category/name/:name/min/:min/max/:max/rating/:rating/order/:order"  component={SearchScreen}  exact></Route>
        <PrivateRoute   path="/Perfil"  component={ProfileScreen}  ></PrivateRoute>
        <PrivateRoute  path="/orderhistory" component={OrderHistoryScreen2}  ></PrivateRoute>
        <Route path='/ProductosPorGrupo/:id' component={HomeScreen} exact></Route>
        <Route path='/' component={EmpresasScreen} exact></Route>
        {
         empresaData && empresaData.bTiendaInicioPorGrupos === true ?
          <>
          <Route path='/Empresa/:id' component={HomeScreenGrupos} exact></Route>
          <Route path='/Empresa/:id/:puesto' component={HomeScreenGrupos} exact></Route>
          </>
          :
          <>
          <Route path='/Empresa/:id' component={HomeScreen} exact></Route>
          <Route path='/Empresa/:id/:puesto' component={HomeScreen} exact></Route>
          </>
        }


    </main>
    <footer className="row center" style={{color:empresaData ?empresaData.ColorFuenteEncabezado:"white" ,backgroundColor:empresaData ?empresaData.ColorEncabezado:"black"}}>
            Swint Digital 2022 Todos los derechos reservados
    </footer>
</div>
</BrowserRouter>
  );
}

export default App;
