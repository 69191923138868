import React, { useEffect } from 'react'
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
import {useDispatch, useSelector } from 'react-redux'
import { listempresas } from '../actions/empresaActions';
import Empresa from '../components/Empresa';
import { GetIdentificacion } from '../actions/userActions';
 

export default function EmpresasScreen() {
    const dispatch = useDispatch();
const empresaList =  useSelector(state => state.empresaList);
const {loading, error, empresas} = empresaList;
useEffect(() => { 
     dispatch(listempresas())
},[dispatch])

 
useEffect(() => {dispatch(GetIdentificacion(0))},[dispatch]);

return (
     
      <div >
        {loading? <LoadingBox></LoadingBox>
        :
        error?<MessageBox variant="danger">{error}</MessageBox>
        :
<div className="row center">
            <div className="row center">
            {
              empresas.map(empresa => (
              <Empresa key={empresas.MercaderiaId} empresa={empresa}/>
              ))
            }
            </div>
            
            </div> 
        }     
      </div> 
    )
}
