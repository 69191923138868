import React   from 'react';
import { listProducts } from '../actions/productActions';
 
import { useDispatch } from 'react-redux';
import { saveFiltroGrupo } from '../actions/FiltrosActions';

function NewlineText(props) {
  const text = props.text;
  if(text)
  return text.split('\n').map(str => <h5 style={{margin:0,padding:0}}>{str}</h5>);
  else
  return <h4 style={{margin:0,padding:0}}>{}</h4>;
}


const Grupo = ({ Grupo ,props}) => {

  const dispatch = useDispatch();
  const setearGrupo = (e,h) => {
    dispatch(saveFiltroGrupo(e,h)); 
    dispatch(listProducts(localStorage.getItem('EmpresaId'),{ }));
    props.history.push('/ProductosPorGrupo/'+ localStorage.getItem('EmpresaId'));
  };


  return (

    <div key={Grupo.Grupo_Id} style={{cursor:"pointer"}} className="card"  onClick={() => setearGrupo(Grupo.Grupo_Id,Grupo.Descripcion)}
    >

{Grupo.LinkFoto && String(Grupo.LinkFoto) !== "https://twinsarchivos.blob.core.windows.net/imagenesabmmercaderias/Producto.png" ?
      
      <div className="image-body" style={{margin:0,padding:0}}>
      <img className="medium" src={Grupo.LinkFoto} alt={Grupo.Descripcion}
      style={{filter: "blur(0px)"}}  />
  </div>
     :<></>
    }



   
      <div className="card-body" >
      <h3 style={{ overflowWrap: "break-word",  margin:2,padding:0 }}>{Grupo.Descripcion}    ({ Grupo.Cantidad}) </h3>
      <div contenteditable="true">
                       <NewlineText text={Grupo.sObservaciones} />
  </div>
      </div>     
 
    </div>
  );
};

export default Grupo;