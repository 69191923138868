import React, { useEffect, useState } from 'react'; 
import { useDispatch, useSelector } from 'react-redux';
import Carousel from 'react-elastic-carousel'
import { PANTALLAATRAS, PANTALLASELECCIONADA } from '../constants/userConstants';
function NewlineText(props) {
    const text = props.text;
    return text.split('\n').map(str => <p style={{margin:0}}>{str}</p>);
    
  }
export default function AboutScreen() {
 
    const empresaInfo = useSelector((state) => state.empresaInfo);
    const { empresaData } = empresaInfo;
 
    const [lstImagenes, SetlstImagenes] = useState();
    const [totalPages, SettotalPages] = useState(0);

   
    const dispatch = useDispatch();
      useEffect(() => {
        dispatch({type: PANTALLASELECCIONADA, payload:  'Ingresar'}); 
        dispatch({type: PANTALLAATRAS, payload:  'Empresa/' + localStorage.getItem('EmpresaId')}); 
        let ListaImagenes = [];
        if(empresaData.sLinkPublicidad1 && String(empresaData.sLinkPublicidad1) !== "")
        ListaImagenes.push({'Link':empresaData.sLinkPublicidad1});
          if(empresaData.sLinkPublicidad2 && String(empresaData.sLinkPublicidad2) !== "")
          ListaImagenes.push({'Link':empresaData.sLinkPublicidad2});
          if(empresaData.sLinkPublicidad3 && String(empresaData.sLinkPublicidad3) !== "")
          ListaImagenes.push({'Link':empresaData.sLinkPublicidad3});
          if(empresaData.sLinkPublicidad4 && String(empresaData.sLinkPublicidad4) !== "")
          ListaImagenes.push({'Link':empresaData.sLinkPublicidad4});
          SetlstImagenes(ListaImagenes);
          SettotalPages(ListaImagenes.length);
      }, [dispatch,empresaData]);



      const breakPoints = [
        { width: 1, itemsToShow: 1 },
        { width: 550, itemsToShow: 1, itemsToScroll: 1 },
        { width: 768, itemsToShow: 1 },
        { width: 1200, itemsToShow: 1 }
      ];

      let carousel;
      let resetTimeout;
    return (
        empresaData ? <div style={{marginLeft:"5px",marginRight:"5px", marginTop:"-50px", marginBottom:"50px"}}>
            <h3>Acerca de Nosotros</h3>
            {lstImagenes && 
            <Carousel style={{margin:0, padding:0 }} autoPlaySpeed={3000} enableAutoPlay={true} breakPoints={breakPoints}  focusOnSelect={true} outerSpacing={0}   
                           renderButtonGroupOutside={true}
                           
                           onNextEnd={({ index }) => {
                            clearTimeout(resetTimeout)
                            if (index + 1 === totalPages) {
                               resetTimeout = setTimeout(() => {
                                   if(carousel)
                                carousel.goTo(0)
                              }, 3000) // same time
                            }
                       }}

                           /* onNextEnd={() => principio()} */
                           showArrows={false}  ref={ref => (carousel = ref)}>
                          { lstImagenes.map(imagen => (
                            <div>
                            <img style={{verticalAlign:'center', alignContent:'center'  ,height:"400px",objectFit:"cover" }}   
                                  alt='' src={imagen.Link} />
                                    </div>
                              )) }
                       </Carousel>}

                       <div contenteditable="true" style={{marginLeft:"10px",marginRight:"10px" }}>
                       <NewlineText text={empresaData.sObservaciones} />
  </div>


        </div>:<></>
    )
}
