 
import {  EMPRESA_FAIL, EMPRESA_REQUEST, EMPRESA_SUCCESS, LOCALIDADES_FAIL, LOCALIDADES_REQUEST, LOCALIDADES_SUCCESS, PROVINCIAS_FAIL, PROVINCIAS_REQUEST, PROVINCIAS_SUCCESS, USER_DETAILS_FAIL, USER_DETAILS_REQUEST, USER_DETAILS_SUCCESS, USER_REGISTER_FAIL,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
    USER_SIGNIN_FAIL,
    USER_SIGNIN_REQUEST,
    USER_SIGNIN_SUCCESS,
    USER_SIGNOUT,
    USER_UPDATE_PROFILE_FAIL,
  USER_UPDATE_PROFILE_REQUEST,
  USER_UPDATE_PROFILE_SUCCESS,
  DIRECCION_INSERT_REQUEST,
  DIRECCION_INSERT_SUCCESS,
  DIRECCION_INSERT_FAIL,
  DIRECCION_DELETE_FAIL,
  DIRECCION_DELETE_SUCCESS,
  DIRECCION_DELETE_REQUEST,
  RECUPERARCLAVE_REQUEST,
  RECUPERARCLAVE_FAIL,
  RECUPERARCLAVE_SUCCESS
  } from '../constants/userConstants';
  
  export const signin = (email, password) => async (dispatch) => {
    dispatch({ type: USER_SIGNIN_REQUEST});
    try {
      var parametros = {EmpresaId: localStorage.getItem('EmpresaId'),Pass: password,User:  email};
    
      const response = await fetch('https://twinsappservice.azurewebsites.net/api/Operadores/GetOperadorAppPedidos2', {
            method: 'POST',
            headers: { 'Content-type': 'application/json; charset=UTF-8','Access-Control-Allow-Credentials':'true'},
            body: JSON.stringify(parametros)
            });    
           const data = await response.json();
           if(!data || data.Id === 0)
           {dispatch({ type: USER_SIGNIN_FAIL, payload:  'Email o password no validos'  });}
           else
           {
           dispatch({type: USER_SIGNIN_SUCCESS, payload:  data});
           localStorage.setItem('userInfo', JSON.stringify(data));
           }
           
    } catch (error) {
      dispatch({
        type: USER_SIGNIN_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
 
  export const register = (nombre,apellido, email, password, localidadcodigo, localidaddescripcion, provinciaid,direccion,provincia,pais,Telefono,EntreCalles) => async (dispatch) => {
    dispatch({ type: USER_REGISTER_REQUEST, payload: { email, password } });
    try {
      var parametros = {Empresa: localStorage.getItem('EmpresaId'),
      Email: email,
      Clave:  password,
      Nombre: nombre,
      Apellido: apellido,
      Telefono: Telefono,
      LocalidadCodigo: localidadcodigo,
      LocalidadDescripcion: localidaddescripcion,
      ProvinciaId: provinciaid,
      Direccion: direccion,
      CUIT: '',
      Provincia: provincia,
      Pais : pais,
      Codigo: '',
      bLoginPorCuit: false,
      bLoginPorCodigo: false,
      EntreCalles:EntreCalles
    };

 
      const response = await fetch('https://twinsappservice.azurewebsites.net/api/Registracion/RegistracionApp', {
            method: 'POST',
            headers: { 'Content-type': 'application/json; charset=UTF-8','Access-Control-Allow-Credentials':'true'},
            body: JSON.stringify(parametros)
            });    
           const data = await response.json();

           if(!data || data.Id === 0 || data.Error !== '')
           {dispatch({ type: USER_REGISTER_FAIL, payload:  data.Error  });}
           else
           {

            var parametros2 = {EmpresaId: localStorage.getItem('EmpresaId'),OperadorId: data.Id};

            const response = await fetch('https://twinsappservice.azurewebsites.net/api/Operadores/GetOperadorAppPedidosPorId', {
                  method: 'POST',
                  headers: { 'Content-type': 'application/json; charset=UTF-8','Access-Control-Allow-Credentials':'true'},
                  body: JSON.stringify(parametros2)
                  });    
                 const dataUser = await response.json();
                 if(dataUser.Message)
                  { 
                    dispatch({ type: USER_DETAILS_FAIL, payload: dataUser.Message});
                  }
                 else if(!dataUser || dataUser.Id === 0)
                 {
                   dispatch({ type: USER_DETAILS_FAIL, payload:  'Email o password no validos'  });
                  }
                 else
                 { 
                    dispatch({type: USER_SIGNIN_SUCCESS, payload:  dataUser});
                    dispatch({ type: USER_REGISTER_SUCCESS, payload: dataUser });
                    dispatch({type: USER_DETAILS_SUCCESS, payload:  dataUser}); 
                    localStorage.setItem('userInfo', JSON.stringify(dataUser));
                 }

 
           }
    } catch (error) {
      dispatch({
        type: USER_REGISTER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

  export const signout = () => (dispatch) => {
    localStorage.removeItem('userInfo');
    localStorage.removeItem('cartItems');
    localStorage.removeItem('shippingAddress');
    dispatch({ type: USER_SIGNOUT });
  };

  export const GetIdentificacion = (EmpresaId) => async (dispatch) => {
    dispatch({ type: EMPRESA_REQUEST});
            try {
 
              var parametros = {EmpresaId: EmpresaId};
              const response = await fetch('https://twinsappservice.azurewebsites.net/api/Operadores/GetIdentificacion', {
                    method: 'POST',
                    headers: { 'Content-type': 'application/json; charset=UTF-8','Access-Control-Allow-Credentials':'true'},
                    body: JSON.stringify(parametros)
                    });    
                  const data = await response.json();
 
                  if(data.Message)
                  { 
                    dispatch({ type: EMPRESA_FAIL, payload: data.Message   });
                  }
                  else if(!data || data.Id === 0)
                  { 
                    dispatch({ type: EMPRESA_FAIL, payload:  'No se encontro la Empresa'  });
                  }
                  else
                  {
                    if(data.Identificacion.Id === 340)
                    {
                      data.Identificacion.bSoloCatalogo = true;
                      if(!data.Identificacion.ColorEncabezado )
                      data.Identificacion.ColorEncabezado = "pink";
                      if(!data.Identificacion.ColorMenu )
                      data.Identificacion.ColorMenu = "pink";
                      if(!data.Identificacion.ColorFondo )
                      data.Identificacion.ColorFondo= "beige";
                      if(!data.Identificacion.ColorFuenteEncabezado )
                      data.Identificacion.ColorFuenteEncabezado= "red";
                      
                    }
                    else{
                      data.Identificacion.bSoloCatalogo = false;
                      if(!data.Identificacion.ColorEncabezado )
                      data.Identificacion.ColorEncabezado = "#203040";
                      if(!data.Identificacion.ColorMenu )
                      data.Identificacion.ColorMenu = "black";
                      if(!data.Identificacion.ColorFondo )
                      data.Identificacion.ColorFondo = "lightgray";
                      if(!data.Identificacion.ColorFuenteEncabezado )
                      data.Identificacion.ColorFuenteEncabezado= "white";
                    }
                    localStorage.setItem('EmpresaId', EmpresaId);
                    localStorage.setItem('Empresa', JSON.stringify(data.Identificacion));
                    dispatch({type: EMPRESA_SUCCESS, payload:  data.Identificacion});
 
             
                  }

            } catch (error) {
              
              dispatch({
                type: EMPRESA_FAIL,
                payload:
                  error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
              });
            }
 
  };

  export const detailsUser = (userId) => async (dispatch, getState) => {
    dispatch({ type: USER_DETAILS_REQUEST, payload: userId });
    const { empresaInfo: { empresaData }  } = getState();
    try {
      var parametros = {EmpresaId: empresaData.Id,OperadorId: userId};

      const response = await fetch('https://twinsappservice.azurewebsites.net/api/Operadores/GetOperadorAppPedidosPorId', {
            method: 'POST',
            headers: { 'Content-type': 'application/json; charset=UTF-8','Access-Control-Allow-Credentials':'true'},
            body: JSON.stringify(parametros)
            });    
           const data = await response.json();
           if(data.Message)
            { 
              dispatch({ type: USER_DETAILS_FAIL, payload: data.Message});
            }
           else if(!data || data.Id === 0)
           {
             dispatch({ type: USER_DETAILS_FAIL, payload:  'Email o password no validos'  });
            }
           else
           {
             
              dispatch({type: USER_DETAILS_SUCCESS, payload:  data}); 
           }
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({ type: USER_DETAILS_FAIL, payload: message });
    }
  };

  export const updateUserProfile = (user) => async (dispatch, getState) => {
    dispatch({ type: USER_UPDATE_PROFILE_REQUEST, payload: user });
    const { userSignin: { userInfo }, empresaInfo: { empresaData }  } = getState();
    try {
      var parametros = {EmpresaId: empresaData.Id,
                        OperadorId: userInfo.Id,
                        PersonaId: userInfo.ClienteId,
                        Nombre : user.nombre,
                        Apellido : user.apellido,
                        Email: user.email,
                        Clave : user.password,
                        Activo : true,
                        PcId : 0,
                        DNI : userInfo.Documento,
                        Telefono : userInfo.Telefono
      };
      const response = await fetch('https://twinsappservice.azurewebsites.net/api/Registracion/RegistracionAppUpdate2', {
            method: 'POST',
            headers: { 'Content-type': 'application/json; charset=UTF-8','Access-Control-Allow-Credentials':'true'},
            body: JSON.stringify(parametros)
            });    
           const data = await response.json();
          
          if(data.Message)
          { 
              dispatch({ type: USER_UPDATE_PROFILE_FAIL, payload: data.Message});
          }
          else if( data !== "")
          {
            dispatch({ type: USER_UPDATE_PROFILE_FAIL, payload:  data  });
          }
          else
          {
              parametros = {EmpresaId: empresaData.Id,OperadorId: userInfo.Id};
                const response = await fetch('https://twinsappservice.azurewebsites.net/api/Operadores/GetOperadorAppPedidosPorId', {
                  method: 'POST',
                  headers: { 'Content-type': 'application/json; charset=UTF-8','Access-Control-Allow-Credentials':'true'},
                  body: JSON.stringify(parametros)
                  });    
                 const dataOperador = await response.json();
                 if(!dataOperador || dataOperador.Id === 0)
                 {dispatch({ type: USER_UPDATE_PROFILE_FAIL, payload:  'Email o password no validos'  });}
                 else
                 {
                  dispatch({ type: USER_UPDATE_PROFILE_SUCCESS, payload: dataOperador });
                  dispatch({ type: USER_SIGNIN_SUCCESS, payload: dataOperador });
                  localStorage.setItem('userInfo', JSON.stringify(dataOperador));
                 }
          }
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({ type: USER_UPDATE_PROFILE_FAIL, payload: message });
    }
  };

  export const listProvincias = () => async (dispatch, getState) => {
    dispatch({ type: PROVINCIAS_REQUEST });
 
    try {
      var parametros = {EmpresaId: localStorage.getItem('EmpresaId'), DestinoId:9};

      const response = await fetch('https://twinsappservice.azurewebsites.net/api/Registracion/Provincias', {
            method: 'POST',
            headers: { 'Content-type': 'application/json; charset=UTF-8','Access-Control-Allow-Credentials':'true'},
            body: JSON.stringify(parametros)
            });    
           const data = await response.json();
           if(data.Message)
            { 
              dispatch({ type: PROVINCIAS_FAIL, payload: data.Message});
            }
           else if(!data)
           {
             dispatch({ type: PROVINCIAS_FAIL, payload:  'Error inesperado'  });
            }
           else
           {
             
              dispatch({type: PROVINCIAS_SUCCESS, payload:  data.Lista}); 
           }
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({ type: PROVINCIAS_FAIL, payload: message });
    }
  };

  export const listLocalidades = (codigo) => async (dispatch, getState) => {
    dispatch({ type: LOCALIDADES_REQUEST });
   
    try {
      var parametros = {EmpresaId: localStorage.getItem('EmpresaId'), ProvinciaCodigo:codigo};

      const response = await fetch('https://twinsappservice.azurewebsites.net/api/Registracion/Localidades', {
            method: 'POST',
            headers: { 'Content-type': 'application/json; charset=UTF-8','Access-Control-Allow-Credentials':'true'},
            body: JSON.stringify(parametros)
            });    
           const data = await response.json();
           if(data.Message)
            { 
              dispatch({ type: LOCALIDADES_FAIL, payload: data.Message});
            }
           else if(!data)
           {
             dispatch({ type: LOCALIDADES_FAIL, payload:  'Error inesperado'  });
            }
           else
           {
 
              dispatch({type: LOCALIDADES_SUCCESS, payload:  data.Lista}); 
           }
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({ type: LOCALIDADES_FAIL, payload: message });
    }
  };

  export const insertDireccion = (pDireccionId,pProvinciaId,pDireccion,pLocalidadDescripcion,pLocalidadCodigo,EntreCalles) => async (dispatch, getState) => {
    dispatch({ type: DIRECCION_INSERT_REQUEST });
    const { userSignin: { userInfo }, empresaInfo: { empresaData }  } = getState();
    try {
      var parametros =
       {Empresa: empresaData.Id,
        DireccionId: pDireccionId,
        ProvinciaId: pProvinciaId,
        LocalidadDescripcion : pLocalidadDescripcion,
        LocalidadCodigo : pLocalidadCodigo,
        PersonaId: userInfo.ClienteId,
        Direccion : pDireccion,
        Activo : true,
        OperadorId : 0,
        PcId : 0 ,
        EntreCalles:EntreCalles
      };
      const response = await fetch('https://twinsappservice.azurewebsites.net/api/Registracion/DireccionInsert', {
            method: 'POST',
            headers: { 'Content-type': 'application/json; charset=UTF-8','Access-Control-Allow-Credentials':'true'},
            body: JSON.stringify(parametros)
            });    
           const data = await response.json();
          if(data.Message)
          { 
              dispatch({ type: DIRECCION_INSERT_FAIL, payload: data.Message});
          }
          else if( data.Error !== "")
          {
            dispatch({ type: DIRECCION_INSERT_FAIL, payload:  data.Error  });
          }
          else
          {  
            parametros = {EmpresaId: empresaData.Id,OperadorId: userInfo.Id};
            const response = await fetch('https://twinsappservice.azurewebsites.net/api/Operadores/GetOperadorAppPedidosPorId', {
            method: 'POST',
            headers: { 'Content-type': 'application/json; charset=UTF-8','Access-Control-Allow-Credentials':'true'},
            body: JSON.stringify(parametros)
            });    
           const dataOperador = await response.json();
           if(!dataOperador || dataOperador.Id === 0)
           {
             dispatch({ type: USER_UPDATE_PROFILE_FAIL, payload:  'Error inesperado al buscar el operador'  });}
           else
           {
              
              dispatch({ type: USER_SIGNIN_SUCCESS, payload: dataOperador });
              localStorage.setItem('userInfo', JSON.stringify(dataOperador));
              dispatch({ type: DIRECCION_INSERT_SUCCESS, payload: true }); 
           }           
            
          }
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({ type: DIRECCION_INSERT_FAIL, payload: message });
    }
  };

  export const updateDireccion = (pDireccionId,pProvinciaId,pDireccion,pLocalidadDescripcion,pLocalidadCodigo,pLocalidadId,pActivo,EntreCalles) => async (dispatch, getState) => {
    dispatch({ type: DIRECCION_DELETE_REQUEST });
    const { userSignin: { userInfo }, empresaInfo: { empresaData }  } = getState();
    try {
      var parametros =
       {Empresa: empresaData.Id,
        DireccionId: pDireccionId,
        ProvinciaId: pProvinciaId,
        LocalidadDescripcion : pLocalidadDescripcion,
        LocalidadCodigo : pLocalidadCodigo,
        PersonaId: userInfo.ClienteId,
        Direccion : pDireccion,
        Activo : pActivo,
        OperadorId : 0,
        PcId : 0 ,
        LocalidadId : pLocalidadId,
        EntreCalles:EntreCalles
      };
      const response = await fetch('https://twinsappservice.azurewebsites.net/api/Registracion/DireccionUpdate', {
            method: 'POST',
            headers: { 'Content-type': 'application/json; charset=UTF-8','Access-Control-Allow-Credentials':'true'},
            body: JSON.stringify(parametros)
            });    
           const data = await response.json();
          if(data.Message)
          { 
              dispatch({ type: DIRECCION_DELETE_FAIL, payload: data.Message});
          }
          else if( data.Error !== "")
          {
            dispatch({ type: DIRECCION_DELETE_FAIL, payload:  data.Error  });
          }
          else
          {  
            parametros = {EmpresaId: empresaData.Id,OperadorId: userInfo.Id};
            const response = await fetch('https://twinsappservice.azurewebsites.net/api/Operadores/GetOperadorAppPedidosPorId', {
            method: 'POST',
            headers: { 'Content-type': 'application/json; charset=UTF-8','Access-Control-Allow-Credentials':'true'},
            body: JSON.stringify(parametros)
            });    
           const dataOperador = await response.json();
           if(!dataOperador || dataOperador.Id === 0)
           {
             dispatch({ type: USER_UPDATE_PROFILE_FAIL, payload:  'Error inesperado al buscar el operador'  });}
           else
           {
              
              dispatch({ type: USER_SIGNIN_SUCCESS, payload: dataOperador });
              localStorage.setItem('userInfo', JSON.stringify(dataOperador));
              dispatch({ type: DIRECCION_DELETE_SUCCESS, payload: true }); 
           }           
            
          }
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({ type: DIRECCION_DELETE_FAIL, payload: message });
    }
  };

  export const PedirContrasenia = (email) => async (dispatch, getState) => {
    dispatch({ type: RECUPERARCLAVE_REQUEST });
    const { empresaInfo: { empresaData }  } = getState();
    try {
      var parametros =
       {EmpresaId: empresaData.Id,
        user: email
      };


     /*  dispatch({ type: RECUPERARCLAVE_FAIL, payload:  JSON.stringify(parametros) });
      return; */

      const response = await fetch('https://twinsappservice.azurewebsites.net/api/Operadores/GetPorCorreoNube', {
            method: 'POST',
            headers: { 'Content-type': 'application/json; charset=UTF-8','Access-Control-Allow-Credentials':'true'},
            body: JSON.stringify(parametros)
            });    
           const data = await response.json();
          if(data.Message)
          { 
              dispatch({ type: RECUPERARCLAVE_FAIL, payload: data.Message});
          }
          else if( data.Error !== "")
          {
            dispatch({ type: RECUPERARCLAVE_FAIL, payload:  data.Error  });
          }
          else
          {  var parametros2 =
            {Email: email,
              Clave: data.Pass
           };
              dispatch({ type: RECUPERARCLAVE_SUCCESS, payload: true }); 
              
                await fetch('https://twinsappservice.azurewebsites.net/api/Operadores/RecuperarClave', {
                method: 'POST',
                headers: { 'Content-type': 'application/json; charset=UTF-8','Access-Control-Allow-Credentials':'true'},
                body: JSON.stringify(parametros2)
                }); 
          }
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({ type: RECUPERARCLAVE_FAIL, payload: message });
    }
  };


  