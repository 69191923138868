import {  EMPRESA_FAIL, EMPRESA_REQUEST, EMPRESA_SUCCESS, USER_REGISTER_FAIL,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
    USER_SIGNIN_FAIL,
    USER_SIGNIN_REQUEST,
    USER_SIGNIN_SUCCESS,
    USER_SIGNOUT,
    USER_DETAILS_FAIL,
    USER_DETAILS_REQUEST,
    USER_DETAILS_SUCCESS,
    USER_UPDATE_PROFILE_FAIL,
  USER_UPDATE_PROFILE_REQUEST,
  USER_UPDATE_PROFILE_RESET,
  USER_UPDATE_PROFILE_SUCCESS,
  PROVINCIAS_REQUEST,
  PROVINCIAS_SUCCESS,
  PROVINCIAS_FAIL,
  LOCALIDADES_FAIL,
  LOCALIDADES_SUCCESS,
  LOCALIDADES_REQUEST,
  DIRECCION_INSERT_REQUEST,
  DIRECCION_INSERT_SUCCESS,
  DIRECCION_INSERT_FAIL,
  DIRECCION_INSERT_RESET,
  DIRECCION_DELETE_RESET,
  DIRECCION_DELETE_FAIL,
  DIRECCION_DELETE_SUCCESS,
  DIRECCION_DELETE_REQUEST,
  RECUPERARCLAVE_REQUEST,
  RECUPERARCLAVE_SUCCESS,
  RECUPERARCLAVE_FAIL,RECUPERARCLAVE_RESET, PANTALLASELECCIONADA, PANTALLAATRAS, GRUPOSELECCIONADO
  } from '../constants/userConstants';
  
  export const userSigninReducer = (state = {}, action) => {
    switch (action.type) {
      case USER_SIGNIN_REQUEST:
        return { loading: true };
      case USER_SIGNIN_SUCCESS:
        return { loading: false, userInfo: action.payload };
      case USER_SIGNIN_FAIL:
        return { loading: false, error: action.payload };
      case USER_SIGNOUT:
        return {};
      default:
        return state;
    }
  };

  export const userRegisterReducer = (state = {}, action) => {
    switch (action.type) {
      case USER_REGISTER_REQUEST:
        return { loading: true };
      case USER_REGISTER_SUCCESS:
        return { loading: false, userInfo: action.payload };
      case USER_REGISTER_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export const empresaInfoReducer = (state = {}, action) => {
    switch (action.type) {
      case EMPRESA_REQUEST:
        return { loading: true };
      case EMPRESA_SUCCESS:
        return { loading: false, empresaData: action.payload };
      case EMPRESA_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };

  export const userDetailsReducer = (state = { loading: true }, action) => {
    switch (action.type) {
      case USER_DETAILS_REQUEST:
        return { loading: true };
      case USER_DETAILS_SUCCESS:
        return { loading: false, user: action.payload };
      case USER_DETAILS_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };

  export const userUpdateProfileReducer = (state = {}, action) => {
    switch (action.type) {
      case USER_UPDATE_PROFILE_REQUEST:
        return { loading: true };
      case USER_UPDATE_PROFILE_SUCCESS:
        return { loading: false, success: true };
      case USER_UPDATE_PROFILE_FAIL:
        return { loading: false, error: action.payload };
      case USER_UPDATE_PROFILE_RESET:
        return {loading: false,};
      default:
        return state;
    }
  };


  export const provinciasListReducer = (  state = { loading:true,  provincias: [] },  action ) => {
    switch (action.type) {
      case PROVINCIAS_REQUEST:
        return { loading: true };
      case PROVINCIAS_SUCCESS:
        return {
          loading: false,
          provincias: action.payload
        };
      case PROVINCIAS_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
 
  export const localidadesListReducer = (  state = { loading:true,  localidades: [] },  action ) => {
    switch (action.type) {
      case LOCALIDADES_REQUEST:
        return { loading: true };
      case LOCALIDADES_SUCCESS:
        return {
          loading: false,
          localidades: action.payload
        };
      case LOCALIDADES_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };


  export const direccionInsertReducer = (state = {success: false}, action) => {
    switch (action.type) {
      case DIRECCION_INSERT_REQUEST:
        return { loading: true };
      case DIRECCION_INSERT_SUCCESS:
        return { loading: false, success: true };
      case DIRECCION_INSERT_FAIL:
        return { loading: false, error: action.payload };
        case DIRECCION_INSERT_RESET:
          return { loading: false, success: false };
      default:
        return state;
    }
  };

  export const direccionDeleteReducer = (state = {success: false}, action) => {
    switch (action.type) {
      case DIRECCION_DELETE_REQUEST:
        return { loading: true };
      case DIRECCION_DELETE_SUCCESS:
        return { loading: false, success: true };
      case DIRECCION_DELETE_FAIL:
        return { loading: false, error: action.payload };
        case DIRECCION_DELETE_RESET:
          return { loading: false, success: false };
      default:
        return state;
    }
  };

  export const recuperarclaveReducer = (state = {success: false}, action) => {
    switch (action.type) {
      case RECUPERARCLAVE_REQUEST:
        return { loading: true };
      case RECUPERARCLAVE_SUCCESS:
        return { loading: false, success: true };
      case RECUPERARCLAVE_FAIL:
        return { loading: false, error: action.payload };
        case RECUPERARCLAVE_RESET:
          return { loading: false, success: false };
      default:
        return state;
    }
  };

  export const pantallaSeleccionadaReducer = (state = {}, action) => {
    switch (action.type) {
      case PANTALLASELECCIONADA:
        return { loading: false, pantallaInfo: action.payload };
      default:
        return state;
    }
  };

  export const pantallaAtrasReducer = (state = {}, action) => {
    switch (action.type) {
        case PANTALLAATRAS:
          return { loading: false, pantallaAtrasInfo: action.payload };
      default:
        return state;
    }
  };

  export const grupoSeleccionadoReducer = (state = {}, action) => {
    switch (action.type) {
        case GRUPOSELECCIONADO:
          return { loading: false, grupoSeleccionadoInfo: action.payload };
      default:
        return state;
    }
  };
  