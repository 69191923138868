 
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { addToCart } from '../actions/cartActions';
import { detailsOrder } from '../actions/orderActions';
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
import { CART_EMPTY } from '../constants/cartConstants';

export default function OrderScreen(props) {
  const orderId = props.match.params.id;
  const orderDetails = useSelector((state) => state.orderDetails);
  const { order, loading, error } = orderDetails;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(detailsOrder(orderId));
  }, [dispatch, orderId]);

  const RepetirPedido = (src) => {
    dispatch({ type: CART_EMPTY });
    localStorage.removeItem('cartItems');
    order.Lista.map((item) => (
      dispatch(addToCart(item.Mercaderia_Id, item.nCantidad ,item.Caracteristica1,item.Caracteristica2,item.CaracteristicaValor1,item.CaracteristicaLink1,
        item.CaracteristicaValor2,item.CaracteristicaLink2,item.nPrecio,item.nPrecioDescuento,item.TipoDescuento,item.TipoDescuentoId ))
      )) 
      props.history.push(`/cart`);
     
  }

 
   
 

  return loading ? (
    <LoadingBox></LoadingBox>
  ) : error ? (
    <MessageBox variant="danger">{error}</MessageBox>
  ) : (
    <div style={{marginBottom:'50px',marginTop:"-45px"}}>
     <td><h1>Pedido {order.Id} </h1> </td>     
      <td width="Auto" ><button type="button" style={{marginLeft:'10px'}} onClick={() => RepetirPedido()}  > <i className="fa fa-repeat" style={{   color: 'Black' , verticalAlign:'center', fontSize:'18px'}}></i> REPETIR </button></td>      
      <div className="row top">
        <div className="col-2">
          <ul>
            <li>
              <div className="card card-body">
                <h2>Entrega</h2>
                <p>
                  <strong>Nombre:</strong> {order.ClienteDescripcion} <br />
                  <strong>Direccion: </strong> {order.EntregaDireccion},
                  {order.EntregaLocalidad},{order.EntregaProvincia}
                  {order.EntregaCodigoPostal},
                  {order.EntregaPais}
                </p>
                {order.Aprobado ? (
                  <MessageBox variant="success">
                    Pedido Aprobado
                  </MessageBox>
                ) : (
                  <MessageBox variant="danger">Pendiente de Aprobacion</MessageBox>
                )}
              </div>
            </li>
            <li>
              <div className="card card-body">
                <h2>Pago</h2>
                <p>
                  <strong>Metodo:</strong> {order.MetodoPago}
                </p>
                {order.isPaid ? (
                  <MessageBox variant="success">
                    Pagado {order.paidAt}
                  </MessageBox>
                ) : (
                  <MessageBox variant="danger">No Pagado</MessageBox>
                )}
              </div>
            </li>
            <li>
             <div className="card card-body">
                <h2>Productos Pedidos</h2>
                <ul>
                  {order.Lista.map((item) => (
                    <li key={item.Mercaderia_Id}>
                      <div className="row">
                        <div>
                          <img
                            src={item.LinkFoto}
                            alt={item.Mercaderia_Descripcion}
                            className="small"
                          ></img>
                        </div>
                        <div className="min-30">
                          <Link to={`/product/${item.Mercaderia_Id}`}>
                            {item.Mercaderia_Descripcion}
                          </Link>
                        </div>
                        <div>
                          {item.nCantidad} x ${item.nPrecio} = ${item.nCantidad * item.nPrecio}
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div> 
            </li>
          </ul>
        </div>
        <div className="col-1">
          <div className="card card-body">
            <ul>
              <li>
                <h2>Resumen del Pedido</h2>
              </li>
              <li>
                <div className="row">
                  <div>Items</div>
                  <div>${order.itemsPrice.toFixed(2)}</div>
                </div>
              </li>
              <li>
                <div className="row">
                  <div>Costo de Envio</div>
                  <div>${order.shippingPrice.toFixed(2)}</div>
                </div>
              </li>
              <li>
                <div className="row">
                  <div>Descuentos</div>
                  <div>${order.descuentos.toFixed(2)}</div>
                </div>
              </li>
              <li>
                <div className="row">
                  <div>
                    <strong> Total Pedido</strong>
                  </div>
                  <div>
                    <strong>${order.totalPrice.toFixed(2)}</strong>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}