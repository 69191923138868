import React, { useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import Carousel from 'react-elastic-carousel'
import {useDispatch, useSelector} from 'react-redux';
import Item from '../screens/ItemImagen';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import useWindowDimensions from '../functions/windowDimentions';
import { 
  isMobile
} from "react-device-detect";


export default  function ZoomImagenes (props)  {
    
    const [displayMaximizable, setDisplayMaximizable] = useState(false);
    const [ListaImagenes, setListaImagenes] = useState();
    const productDetails =  useSelector(state => state.productDetails);
    const {product} = productDetails;

    const { width, height  } = useWindowDimensions();

    const breakPoints = [
        { width: 1, itemsToShow: 1 },
        { width: 550, itemsToShow: 1, itemsToScroll: 1 },
        { width: 768, itemsToShow: 1 },
        { width: 1200, itemsToShow: 1 }
      ];

      let carousel;
    const dispatch = useDispatch();
    useEffect(() =>{

        if (product) {
          setListaImagenes(product.lstImagenes);
        }
      },
      [dispatch,product]);
    

    const dialogFuncMap = {
  
        'displayMaximizable': setDisplayMaximizable,
     
    }

    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);

        if (position) {
            
        }
    }

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    }

    const renderFooter = (name) => {
        return (
            <div>
                <Button label="Salir" style={{fontSize:"20px"}} icon="pi pi-times" onClick={() => onHide('displayMaximizable')} className="p-button-text" />
                 
            </div>
        );
    }
    return ( isMobile ? 
    
        <div >
        {ListaImagenes &&    <div   >
                <Button label="Maximizar"  icon="pi pi-external-link" onClick={() => onClick('displayMaximizable')} />
                <Dialog header={renderFooter} maximized visible={displayMaximizable} maximizable modal style={{ marginTop:"140px" }}  
                 onHide={() => onHide('displayMaximizable')}  >
                <div    >



          {ListaImagenes &&  ListaImagenes.length > 1 ? (
                       <div className="col-4" >
                       <div style={{ position: 'relative'}}  >
                           <Carousel    breakPoints={breakPoints} 
                            outerSpacing={0}   
                           renderButtonGroupOutside={true}  
                           showArrows={false} ref={ref => (carousel = ref)} >
                                { ListaImagenes.map(imagen => (
                              <Item style={{verticalAlign:'center'}}>
                      <div style={{verticalAlign:'center'}}>
                      <TransformWrapper >
                              <TransformComponent >
                              <div style={{  height:height-180, alignItems:"center",alignContent:"center",textAlign:"center" ,objectFit:"cover"   }}  >
                              <img  style={{ width:'100%' }}     
                                   alt='' src={imagen.Link} />
                                      </div>
                              </TransformComponent>
                            </TransformWrapper>
                               </div></Item>)) }
                       </Carousel>
                 {              
                     <><button style={{zIndex :'0' ,position:'absolute',top:'50%' }} onClick={() => carousel.slidePrev()}> <i className="fa fa-arrow-left"></i> </button>
                     <button style={{zIndex :'0', right:'0',  position:'absolute',top:'50%'}} onClick={() => carousel.slideNext()}> <i className="fa fa-arrow-right"></i> </button></>
                 }
               </div>
               </div>
                        ) : (
                          <div    >
                          <TransformWrapper  >
                              <TransformComponent>
                                <div style={{  height:height-180,width:width-50, alignItems:"center",alignContent:"center",textAlign:"center" ,objectFit:"cover"   }}  >
                              <img     style={{ width:'100%' }}  
                              src={ product.LinkFoto } alt={product.MercaderiaDescripcion} ></img>
                              </div>
                              </TransformComponent>
                          </TransformWrapper>
                        </div>
                        )} 





                       </div>
                </Dialog>

            </div>}
        </div>

:
<div >
        {ListaImagenes &&    <div   >
                <Button label="Maximizar"  icon="pi pi-external-link" onClick={() => onClick('displayMaximizable')} />
                <Dialog header={renderFooter} maximized visible={displayMaximizable} maximizable modal style={{ marginTop:"140px" }}  
                 onHide={() => onHide('displayMaximizable')}  >
                <div    >



          {ListaImagenes &&  ListaImagenes.length > 1 ? (
                       <div className="col-4" >
                       <div style={{ position: 'relative'}}  >
                           <Carousel    breakPoints={breakPoints} 
                            outerSpacing={0}   
                           renderButtonGroupOutside={true}  
                           showArrows={false} ref={ref => (carousel = ref)} >
                                { ListaImagenes.map(imagen => (
                              <Item style={{verticalAlign:'center'}}>
                      <div style={{verticalAlign:'center'}}>
                      <TransformWrapper >
                              <TransformComponent >
                              <div style={{  height:height-180,width:width-30, alignItems:"center",alignContent:"center",textAlign:"center" ,objectFit:"cover"   }}  >
                              <img  style={{ height:height-180 , alignItems:"center",alignContent:"center",textAlign:"center" ,objectFit:"cover"   }}    
                                   alt='' src={imagen.Link} />
                                      </div>
                              </TransformComponent>
                            </TransformWrapper>
                               </div></Item>)) }
                       </Carousel>
                 {              
                     <><button style={{zIndex :'0' ,position:'absolute',top:'50%' }} onClick={() => carousel.slidePrev()}> <i className="fa fa-arrow-left"></i> </button>
                     <button style={{zIndex :'0', right:'0',  position:'absolute',top:'50%'}} onClick={() => carousel.slideNext()}> <i className="fa fa-arrow-right"></i> </button></>
                 }
               </div>
               </div>
                        ) : (
                          <div    >
                          <TransformWrapper  >
                              <TransformComponent>
                                <div style={{ height:height-180,width:width-30, alignItems:"center",alignContent:"center",textAlign:"center"    }}  >
                              <img    style={{ height:height-180 , alignItems:"center",alignContent:"center",textAlign:"center" ,objectFit:"cover"   }} 
                              src={ product.LinkFoto } alt={product.MercaderiaDescripcion} ></img>
                              </div>
                              </TransformComponent>
                          </TransformWrapper>
                        </div>
                        )} 





                       </div>
                </Dialog>

            </div>}
        </div>

    )
}