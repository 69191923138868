import { TextField, Typography } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { listLocalidades, listProvincias, register } from '../actions/userActions';
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
import { LOCALIDADES_SUCCESS, PANTALLAATRAS, PANTALLASELECCIONADA } from '../constants/userConstants';
import IconButton from "@material-ui/core/IconButton"; 
import Visibility from "@material-ui/icons/Visibility";
import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Input from "@material-ui/core/Input";
import Mensaje from 'sweetalert';
export default function RegisterScreen(props) {
  const [nombre, setNombre] = useState('');
  const [apellido, setApellido] = useState('');
  const [email, setEmail] = useState(''); 
  const [confirmPassword, setConfirmPassword] = useState('');
  const [Direccion, setDireccion] = useState('');
  const [Telefono, setTelefono] = useState('');
  const [ProvinciaId, setProvinciaId] = useState(0);
  const [ProvinciaCodigo, setProvinciaCodigo] = useState(0);
  const [Pais, setPais] = useState('Argentina');
  const [LocalidadCodigo, setLocalidadCodigo] = useState(''); 
  const [LocalidadDescripcion, setLocalidadDescripcion] = useState(''); 
  const [EntreCalles, setEntreCalles] = useState('');

  const empresaInfo = useSelector((state) => state.empresaInfo);
  const { empresaData } = empresaInfo;

  const dispatch = useDispatch();
  const provinciasList =  useSelector(state => state.provinciasList);
  const {loading: loadingProvincias, error: errorProvincias, provincias} = provinciasList;

  useEffect(() => { 
    dispatch({type: PANTALLASELECCIONADA, payload:  'Registracion'}); 
  dispatch({type: PANTALLAATRAS, payload:  'signin'}); 
       dispatch(listProvincias())
  }, [dispatch])

  const localidadesList =  useSelector(state => state.localidadesList);
  const {loading: loadingLocalidades, error: errorLocalidades, localidades} = localidadesList;
  useEffect(() => { 
    
       dispatch(listLocalidades(ProvinciaCodigo))
  }, [dispatch,ProvinciaCodigo])

  const redirect = props.location.search
    ? props.location.search.split('=')[1]
    : '/';

  const userRegister = useSelector((state) => state.userRegister);
  const { userInfo, loading, error } = userRegister;

  const submitHandler = (e) => {
    e.preventDefault();
    if (values.password !== confirmPassword) {
    
      Mensaje({
        title: "Error",
        text: 'Password y confirmar Password no son iguales',
        icon: "error",
        button: "cerrar",
      });

    } else {
      dispatch(register(nombre,apellido, email, values.password, LocalidadCodigo, LocalidadDescripcion, ProvinciaId,Direccion,ProvinciaCodigo,Pais,Telefono,EntreCalles));
    }
  };
  useEffect(() => {
    if (userInfo) {
      props.history.push(redirect);
    }
  }, [props.history, redirect, userInfo]);

  const cambioLocalidad = (e) => {
    if(e)
    {
      setLocalidadCodigo(e.sCodigo)
      setLocalidadDescripcion(e.sDescripcion)
    }
  }
  
  const cambioProvincia = (e) => {
    if(e)
    {
      setProvinciaCodigo(e.sCodigo)
      setProvinciaId(e.Id)
    }
    else
    {
      setProvinciaCodigo('')
      setProvinciaId(0)
      dispatch({type: LOCALIDADES_SUCCESS, payload: []}); 
      setLocalidadCodigo('')
      setLocalidadDescripcion('')
    }
  }

  const [values, setValues] = React.useState({
    password: "",
    showPassword: false,
  });
  
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };
  
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  
  const handlePasswordChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  return (
    <div style={{marginBottom:"30px", padding:0,margin:0}}>
      <form className="form" onSubmit={submitHandler} style={{marginBottom:"30px" }}>
        <div>
          <h1>Crear Cuenta</h1>
        </div>
        {loading && <LoadingBox></LoadingBox>}
        {error && <MessageBox variant="danger">{error}</MessageBox>}
        <div  >
              <label htmlFor="Nombre">Nombre</label>
              <input
                id="Nombre"
                type="text"
                required
                placeholder="Ingrese Nombre"
                value={nombre}
                onChange={(e) => setNombre(e.target.value)}
              ></input>
            </div>
            <div>
              <label htmlFor="Apellido">Apellido</label>
              <input
                id="Apellido"
                type="text"
                required
                placeholder="Ingrese Apellido"
                value={apellido}
                onChange={(e) => setApellido(e.target.value)}
              ></input>
            </div>
        <div>
          <label htmlFor="email">Correo Electronico</label>
          <input
            type="email"
            id="email"
            placeholder="Ingresar email"
            required
            onChange={(e) => setEmail(e.target.value)}
          ></input>
        </div>
        <div>
          <label htmlFor="password">Password</label>
          <Input
        type={values.showPassword ? "text" : "password"}
        onChange={handlePasswordChange("password")}
        value={values.password} style={{fontSize:"20px"}}
        endAdornment={
          <InputAdornment position="end">
            <IconButton onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} >
              {values.showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        }
      />
        </div>
        <div>
          <label htmlFor="confirmPassword">Confirmar Password</label>
          <input
            type="password"
            id="confirmPassword"
            placeholder="Confirmar password"
            required
            onChange={(e) => setConfirmPassword(e.target.value)}
          ></input>
        </div>
        {empresaData.Id !== 328 &&  <div>


        <label htmlFor="country">Pais</label> 
           <input
          readOnly='true'
            type="text"
            id="country"
            placeholder="Ingresar Pais"
            value={Pais}
            onChange={(e) => setPais(e.target.value)}
            required
          ></input>
        </div>}

        {empresaData.Id !== 328 && <div>
              {loadingProvincias? <LoadingBox></LoadingBox>
              :
              errorProvincias?<MessageBox variant="danger">{errorProvincias}</MessageBox>
              :    
              <Autocomplete
              onChange={(event, value) => cambioProvincia(value)} 
              autoSelect 
              required
              getOptionLabel={(option) => option.sDescripcion}
              getOptionSelected={(option) => option.Id}
              renderOption={(option) => (
                <Typography style={{ fontSize: "1.5rem" }}>{option.sDescripcion}</Typography>
              )}
              itemID= "Id"
              options={provincias} 
              renderInput={(params) => (  <TextField {...params}   variant="outlined"  label="Ingrese Provincia"   inputProps={{ ...params.inputProps, style: { fontSize: "1.5rem" } }} />   )} 
            />}
        </div>}

        {empresaData.Id !== 328 &&   <div>
              {loadingLocalidades? <LoadingBox></LoadingBox>
              :
              errorLocalidades?<MessageBox variant="danger">{errorLocalidades}</MessageBox>
              :    
              <Autocomplete
              onChange={(event, value) => cambioLocalidad(value)} 
              required
              autoSelect 
              getOptionLabel={(option) => option.sDescripcion}
              getOptionSelected={(option) => option.Id}
              renderOption={(option) => (
                <Typography style={{ fontSize: "1.5rem" }}>{option.sDescripcion}</Typography>
              )}
              itemID= "Id"
              options={localidades} 
              renderInput={(params) => (  <TextField {...params}   variant="outlined"  label="Ingrese Localidad"   inputProps={{ ...params.inputProps, style: { fontSize: "1.5rem" } }} />   )} 
            />}
        </div>}
 
        {empresaData.Id !== 328 &&    <div>
          <label htmlFor="address">Direccion</label>
          <input
            type="text"
            id="address"
            placeholder="Ingresar Direccion"
            value={Direccion}
            onChange={(e) => setDireccion(e.target.value)}
            required
          ></input>
        </div>}

        {empresaData.Id !== 328 &&     <div>
          <label htmlFor="EntreCalles">Entre que calles</label>
          <input
            type="text"
            id="EntreCalles"
            placeholder="Entre que calles"
            value={EntreCalles}
            onChange={(e) => setEntreCalles(e.target.value)}
            required
          ></input>
        </div>}

        {empresaData.Id !== 328 &&   <div>
          <label htmlFor="telefono">Telefono</label>
          <input
            type="text"
            id="telefono"
            placeholder="Ingresar Telefono"
            value={Telefono}
            onChange={(e) => setTelefono(e.target.value)}
            required
          ></input>
        </div>}

        <div>
          <label />
          <button className="primary" type="submit">
            Registrarse
          </button>
        </div>

        <div>
          <label />
          <div>
            Ya tiene una cuenta?{' '}
            <Link to={`/signin?redirect=${redirect}`}>Login</Link>
          </div>
        </div>

      </form>
    </div>
  );
}