import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { saveShippingAddress } from '../actions/cartActions';
import CheckoutSteps from '../components/CheckoutSteps';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded'; 
import Mensaje from 'sweetalert';
import { updateDireccion } from '../actions/userActions';
import { DIRECCION_DELETE_RESET, PANTALLAATRAS, PANTALLASELECCIONADA } from '../constants/userConstants';
export default function ShippingAddressScreen(props) {
 
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;

 
 
  if (!userInfo) {
    props.history.push('/signin');
  }


  const dispatch = useDispatch();


  useEffect(() => {
    dispatch({type: PANTALLASELECCIONADA, payload:  'Ingresar'}); 
    dispatch({type: PANTALLAATRAS, payload: 'cart'}); 
  }, [dispatch]);


  const submitHandler = (e) => {
    e.preventDefault();   
    const oItemDireccion = userInfo.Direcciones.filter(l => { return l.Id === direccionseleccionada;})[0];
if(!oItemDireccion)
{
  Mensaje("Debe seleccionar una direccion", {
    icon: "error",
  });
}
else
{
    dispatch(saveShippingAddress(oItemDireccion));
     props.history.push('/payment');
}
  };

  const cmdNuevaDire = (e) => { 
    props.history.push('/nuevadireccion');
  };
 
  const [direccionseleccionada, setDireccionSeleccionada] = useState(userInfo ?( userInfo.Direcciones[0] ?userInfo.Direcciones[0].Id:0) : 0);
  const handleChangeDireccion = (event) => { 
    setDireccionSeleccionada(parseInt(event.target.value));
  };




  const direccionDelete = useSelector((state) => state.direccionDelete);
  const {  success: successDireccionDelete } = direccionDelete;
  const Eliminar = (e) => { 
    Mensaje({
      title: "Esta seguro de eliminar esta direccion?",
      text: "",
      icon: "warning",
      buttons: ["NO", "SI"],
      dangerMode: true,
    })
    .then((willDelete) => {
      if (willDelete) { 
         dispatch( 
          updateDireccion( e.Id,e.ProvinciaId,e.Direccion,e.LocalidadDescripcion,e.LocalidadCodigo,e.LocalidadId,false,e.EntreCalles)
        ); 
      }  
      else
      {
         
      }
    });
  };



  useEffect(() => {
    if(successDireccionDelete)
    {
      dispatch({ type: DIRECCION_DELETE_RESET });
      Mensaje(" Direccion Eliminada satisfactoriamente", {
        icon: "success",
      });
    }
  },[dispatch, props.history, successDireccionDelete] ) ;




  return (
    <div >
      <CheckoutSteps step1 step2></CheckoutSteps>
      <div className='row center'>
      <FormLabel component="legend"  style={{ fontSize: "3rem", color: 'black' }} >Direccion de Entrega</FormLabel>
      </div> 
        <div className='row center'>
          <button className="primary" onClick= {(e) => cmdNuevaDire(e.target.value)}  >
            Agregar Nueva Direccion
          </button>
        </div>
 
      <form className="form" onSubmit={submitHandler}>
      <FormControl component="fieldset" >
     
      <RadioGroup aria-label="gender"  name="gender1" value={direccionseleccionada} onChange={handleChangeDireccion}>
      {
      
      userInfo && userInfo.Direcciones.map(direccion => (
        <div style={  { flexGrow: 1, justifyContent:'center', alignItems: 'center' }  }>
 <FormControlLabel   
        style={{ RadioLabel: {  fontSize: "3rem"   } }}
         value={direccion.Id} control={<Radio style={{ RadioLabel: {  fontSize: "3rem"   } }}/>}   
        margin="normal"
        label={<span style={{ fontSize: '2rem' }}>{direccion.Direccion + ' ' +  direccion.LocalidadDescripcion + ' ' +  direccion.ProvinciaDescripcion} </span>}
        />

        <DeleteForeverRoundedIcon style={  {fontSize: "3rem"  ,cursor: "hand", marginLeft:"20"  }  }    onClick={() => Eliminar( direccion )} /> 
        <hr
        style={{
            color: "black",
            backgroundColor: "black",
            height: 2
        }}
    />
        </div>
         ))

         
      }
      </RadioGroup>
    </FormControl>

        <div>
          <label />
          <button className="primary" type="submit">
            Continuar
          </button>
        </div>
      </form>
    </div>
  );
}