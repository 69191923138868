import React, {useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { PedirContrasenia, signin } from '../actions/userActions';
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
import Mensaje from 'sweetalert';
import IconButton from "@material-ui/core/IconButton"; 
import Visibility from "@material-ui/icons/Visibility";
import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Input from "@material-ui/core/Input";
 
import { PANTALLAATRAS, PANTALLASELECCIONADA, RECUPERARCLAVE_RESET } from '../constants/userConstants';
export default function SigninScreen(props) {
  const [email, setEmail] = useState('');
  //const [password, setPassword] = useState('');

  const redirect = props.location.search? props.location.search.split('=')[1]: '/Empresa/' + localStorage.getItem('EmpresaId');
  const userSignin = useSelector((state) => state.userSignin);
  const {userInfo, loading, error} = userSignin;

  const recuperarclave = useSelector((state) => state.recuperarclave);
  const {success:successRecuperarClave, error:errorRecuperarClave} = recuperarclave;

const dispatch = useDispatch();


useEffect(() => {
  dispatch({type: PANTALLASELECCIONADA, payload:  'Ingresar'}); 
  dispatch({type: PANTALLAATRAS, payload:  'Empresa/' + localStorage.getItem('EmpresaId')}); 
}, [dispatch]);



  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(signin(email,values.password));
  };


  const PedirContra = (e) => { 
    if (!email) {
      Mensaje({
        title: "Error",
        text: "Debe cargar el Email",
        icon: "error",
        button: "cerrar",
      });
    }
    else{
    dispatch(PedirContrasenia(email));}
  };

  useEffect(() => {
    if (successRecuperarClave) {
      Mensaje("Se le envio un mail con su password", {
        icon: "success",
      });
      dispatch({ type: RECUPERARCLAVE_RESET }); 
    }
  }, [dispatch,successRecuperarClave]);

  useEffect(() => {
    if (errorRecuperarClave) {
      Mensaje({
        title: "Error",
        text: errorRecuperarClave,
        icon: "error",
        button: "cerrar",
      });
    }
  }, [errorRecuperarClave]);

  useEffect(() => {
    if (userInfo) {
      props.history.push(redirect);
    }
  }, [props.history, redirect, userInfo]);


  const [values, setValues] = React.useState({
    password: "",
    showPassword: false,
  });
  
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };
  
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  
  const handlePasswordChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };


  return (
    <div>
        <div className="form">
          <h1>Iniciar Sesión </h1>
        </div>

      <form className="form" onSubmit={submitHandler}>
       

        {loading && <LoadingBox></LoadingBox>}
        {error && <MessageBox variant="danger">{error}</MessageBox>}

  
        <div>
          <label htmlFor="email">Correo Electronico</label>
          <input
            //type="email"
            id="email"
            placeholder="Ingrese su correo electronico"
            required
            onChange={(e) => setEmail(e.target.value)}
          ></input>
        </div>
        <div>
   {/*        <label htmlFor="password">Password </label>
          <input
            type="password"
            id="password"
            placeholder="Ingrese su password"
            required
            onChange={(e) => setPassword(e.target.value)}
          ></input> */}
          
          <label htmlFor="password">Password</label>
          <Input
        type={values.showPassword ? "text" : "password"}
        onChange={handlePasswordChange("password")}
        value={values.password} style={{fontSize:"20px"}}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {values.showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        }
      />


        </div>
        <div>
          <label />
          <button className="primary" type="submit">
          Iniciar Sesión
          </button>
        </div>
        <div>
          <label />
          <div>
            Usuario Nuevo?   <Link to={`/register?redirect=${redirect}`}>Crear una nueva cuenta</Link>
          </div>
        </div>
      </form>
      <div className="form">
      <Link onClick={() => PedirContra()}>Olvido su contraseña?</Link>
        </div>
 

       

    </div>
  );
}