import { RANGOSHORARIO_LISTA_FAIL, RANGOSHORARIO_LISTA_REQUEST, RANGOSHORARIO_LISTA_SUCCESS }
   from "../constants/RangosHorarioConstants";

 
  
    export const RangosHorarioReducer = ( state = { loading:true,  RangosHorarioLista: [] },  action) => {
    switch (action.type) {
      case RANGOSHORARIO_LISTA_REQUEST:
        return { loading: true };
      case RANGOSHORARIO_LISTA_SUCCESS:
        return { loading: false, RangosHorarioLista: action.payload };
      case RANGOSHORARIO_LISTA_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
