
const {
    EMPRESA_LIST_REQUEST, 
    EMPRESA_LIST_SUCCESS,
    EMPRESA_LIST_FAIL,
  } = require("../constants/empresaConstants")

export const empresaListReducer = (  state = { loading:true,  empresas: [] },  action ) => {
switch (action.type) {
  case  EMPRESA_LIST_REQUEST:
    return { loading: true };
  case  EMPRESA_LIST_SUCCESS:
    return {
      loading: false,
      empresas: action.payload
    };
  case  EMPRESA_LIST_FAIL:
    return { loading: false, error: action.payload };
  default:
    return state;
}
};
