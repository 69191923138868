import React , {  useState } from 'react'
import Rating from './Rating'; 
import NumericInput from 'react-numeric-input';
import { useDispatch, useSelector } from 'react-redux';
import { addToCart, removeFromCartPorId } from '../actions/cartActions';
function NewlineText(props) {
  const text = props.text;
  if(text)
  return text.split('\n').map(str => <h4 style={{margin:0,padding:0}}>{str}</h4>);
  else
  return  <h4 style={{margin:0,padding:0}}> </h4> ;
}
export default function Product({ product ,props}) {
   
  const cart = useSelector((state) => state.cart);
  const { cartItems } = cart;

  const empresaInfo = useSelector((state) => state.empresaInfo);
  const { empresaData } = empresaInfo;

 // let dataCart = cartItems.find(l => { return  l.product === parseInt(product.MercaderiaId)});
  let mnCantidad = cartItems.filter(l => l.product === parseInt(product.MercaderiaId)).reduce((accumulator, currentValue) => accumulator + currentValue.cantidad, 0)
  const [cantidad, setCantidad] = useState( mnCantidad? mnCantidad:0);
  const dispatch = useDispatch();
  const AgregarCantidad = (mercaderiaId,cantidad,PrecioDescuento,Precio,TipoDescuento,TipoDescuentoId) => 
  {
    setCantidad(cantidad);
    if(cantidad > 0)
    dispatch(addToCart(mercaderiaId, cantidad,null,null,null,null,null,null,Precio,PrecioDescuento,TipoDescuento,TipoDescuentoId ));
    else
    dispatch(removeFromCartPorId(mercaderiaId));
  }


  const Detalle = (e ) => { 
    props.history.push('/product/'+ e);
  };


    return (
      
      <div key={product.MercaderiaId} className="card"  >
    
    
      <div className="image-body" style={{margin:0,padding:0,cursor:"pointer"}} onClick={() => Detalle(product.MercaderiaId)}>
        {console.log(product.LinkFoto)}
        {product.LinkFoto && String(product.LinkFoto) !== "https://twinsarchivos.blob.core.windows.net/imagenesabmmercaderias/Producto.png" ?
      
          <img className="medium" src={product.LinkFoto} alt={product.MercaderiaDescripcion} />
         :<></>
        }
      </div>
      <div className="card-body" >
      <div style={{margin:0,padding:0,cursor:"pointer"}} onClick={() => Detalle(product.MercaderiaId)}>
              <h3 style={{ overflowWrap: "break-word", width:"200px",margin:2,padding:0 }}>{product.MercaderiaDescripcion}</h3>
          
          {(!product.LinkFoto || String(product.LinkFoto) === "https://twinsarchivos.blob.core.windows.net/imagenesabmmercaderias/Producto.png") &&
         
                       <NewlineText text={product.Obs} />
          }

      </div>
      <div style={{margin:0,padding:0,cursor:"pointer"}} onClick={() => Detalle(product.MercaderiaId)}>
          <Rating rating={product.rating} numReviews={product.numReviews} ></Rating>
          {product.nPrecioDescuento !== product.nPrecio  && 
          <div className="row">
           <h1 style={{textDecoration: "line-through",margin:0,padding:0}}>  ${product.nPrecioDescuento }</h1>
         
           <h1 style={{ margin:0,padding:0}}>  ${product.nPrecio }</h1>
           </div>}
           { product.nPrecioDescuento === product.nPrecio  && 
           <div className="row">
           <h1 style={{margin:0,padding:0}}>{" "}</h1>
           <h1 style={{margin:0,padding:0}}>${product.nPrecio}</h1>
           </div>}
           <div>
           <h1 style={{margin:0,padding:0}}>{" "}</h1>
           <h5 style={{alignItems:"right",margin:0,padding:0}}>{product.TipoDescuento }</h5>
          </div>
          </div>





          {empresaData.bSoloCatalogo === false  && 
           <div className="row" style={{margin:0,padding:0}}> 
           
           
           <h3 style={{margin:0,padding:0}}>Pedido</h3>  
           {(product.nStock > 0) &&   <>
           { 
                                          (product.Medicion === 'P' && !product.bConCaracteristicas) && 
                                          (
                                          <NumericInput step={0.1} mobile  size={6} precision={2} min={ 0 } 
                                           required value={cantidad?cantidad:0} 
                                           onChange={e => AgregarCantidad(product.MercaderiaId,e?e:0,product.nPrecioDescuento,product.nPrecio,product.TipoDescuento,product.TipoDescuentoId)}
                                           style={{margin:0,padding:0,  wrap: {  fontSize: 18  }, input: {  fontWeight: 100,  },
                                            'input:focus' : {  border: '1px inset #69C',   outline: 'none'  },
                                            arrowUp: { borderBottomColor: 'rgba(66, 54, 0, 0.63)'},
                                            arrowDown: {  borderTopColor: 'rgba(66, 54, 0, 0.63)'} }}/>
                                           )
            }
            { 
                                          (product.Medicion !== 'P' && !product.bConCaracteristicas ) && 
                                          (
                                          <NumericInput   mobile step={1} min={0} size={6} precision={0} 
                                          required value={cantidad?cantidad:0} onChange={e => AgregarCantidad(product.MercaderiaId,e?e:0,product.nPrecioDescuento,product.nPrecio,product.TipoDescuento,product.TipoDescuentoId)} 
                                          style={{margin:0,padding:0,  wrap: { fontSize: 18 }, 
                                            input: {  fontWeight: 100, }, 'input:focus' : { border: '1px inset #69C', outline: 'none' },
                                            arrowUp: { borderBottomColor: 'rgba(66, 54, 0, 0.63)'},
                                            arrowDown: { borderTopColor: 'rgba(66, 54, 0, 0.63)' }  }}/>
                                           )}
                                             {
                                             product.bConCaracteristicas &&
                                             (
                                                <h1  style={{margin:0,padding:0}}>{cantidad?cantidad:0} </h1>
                                             )
              }  
              </> }

              {(product.nStock === 0) &&   <>
                <h1  style={{margin:0,padding:0, color:"red"}}>Sin Stock </h1>
                </> }
          </div>}
        </div>     
     
     
     
      </div>
    );
}
