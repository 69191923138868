import React from 'react';
import {  useSelector } from 'react-redux';
export default function CheckoutSteps(props) {

  const RangosHorario =  useSelector(state => state.RangosHorario)
  const { RangosHorarioLista} = RangosHorario;

  return (
    <div className="row checkout-steps">
     {/*  <div className={props.step1 ? 'active' : ''}>Login</div> */}
      <div className={props.step2 ? 'active' : ''}>Direccion</div>
      <div className={props.step3 ? 'active' : ''}>Metodo</div>
      {RangosHorarioLista && RangosHorarioLista.length > 0 &&
      <div className={props.step4 ? 'active' : ''}>Horario</div>}
      <div className={props.step5 ? 'active' : ''}>Comprar</div>
    </div>
  );
}