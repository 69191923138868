import {
  CART_ADD_ITEM,
  CART_REMOVE_ITEM,
  CART_SAVE_SHIPPING_ADDRESS,
  CART_SAVE_PAYMENT_METHOD,
  CART_EMPTY,
  CART_SAVE_TARJETA,
  CART_GET_PROMOS,
  CART_REMOVEPORID_ITEM,
  CART_SAVE_HORARIO
} from '../constants/cartConstants';

export const cartReducer = (state = { cartItems: [] }, action) => {
  switch (action.type) {
    case CART_ADD_ITEM:
      const item = action.payload;
      const existItem = state.cartItems.find((x) => x.product === item.product && x.caracteristica1 === item.caracteristica1 && x.caracteristica2 === item.caracteristica2);
      if (existItem) {
        return {
          ...state,
          cartItems: state.cartItems.map((x) =>
            (x.product === existItem.product && x.caracteristica1=== existItem.caracteristica1 && x.caracteristica2=== existItem.caracteristica2)  ? item : x
          ),
        };
      } else {
        return { ...state, cartItems: [...state.cartItems, item] };
      }
    case CART_REMOVE_ITEM:

        return {          
          ...state,
          cartItems: state.cartItems.filter((x) => x.name !== action.payload ),
        };  


    case CART_REMOVEPORID_ITEM:
        return {          
          ...state,
          cartItems: state.cartItems.filter((x) => x.product !== action.payload ),
        }; 
    case CART_SAVE_SHIPPING_ADDRESS:
        return { ...state, shippingAddress: action.payload };
    case CART_SAVE_PAYMENT_METHOD:
        return { ...state, paymentMethod: action.payload };
    case CART_EMPTY:
        return { ...state, cartItems: [] };
    case CART_SAVE_TARJETA:
        return { ...state, tarjeta: action.payload };
    case CART_GET_PROMOS:
        return { ...state, promociones: action.payload };
        case CART_SAVE_HORARIO:
          return { ...state, horario: action.payload };
    default:
      return state;
  }
};