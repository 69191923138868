import { Tooltip } from '@material-ui/core';
import React, { useEffect } from 'react';
import NumericInput from 'react-numeric-input';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { addToCart, promosCart, removeFromCart } from '../actions/cartActions';
import MessageBox from '../components/MessageBox';
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded'; 
import { getlistaRangosHorario } from '../actions/rangoshorarioActions';
import { PANTALLAATRAS, PANTALLASELECCIONADA } from '../constants/userConstants';
 


export default function CartScreen(props) {
    const mercaderiaId = props.match.params.id;
    const cantidad = props.match.params.cantidad ? Number(props.match.params.cantidad)  : 1; 
    const caracteristicaId1 = props.match.params.caracteristica1 ? Number(props.match.params.caracteristica1)  : 0;
    const caracteristicaId2 = props.match.params.caracteristica2 ? Number(props.match.params.caracteristica2)  : 0;

    const cart = useSelector((state) => state.cart);
    const { cartItems } = cart;
    const productDetails =  useSelector(state => state.productDetails);
    const { product} = productDetails;
    const dispatch = useDispatch();

    const empresaInfo =  useSelector(state => state.empresaInfo);
    const { empresaData } = empresaInfo;

    useEffect(() => {
      dispatch(getlistaRangosHorario(new Date()));
  }, [dispatch]);


    useEffect(() => {
      let caracteristica1 
      let caracteristica2
      if (mercaderiaId && cantidad) {
         caracteristica1 = product.lstCaracteristicasOpciones1 ? product.lstCaracteristicasOpciones1.find(r => r.Id ===caracteristicaId1):null;
         caracteristica2 = product.lstCaracteristicasOpciones2 ? product.lstCaracteristicasOpciones2.find(r => r.Id ===caracteristicaId2):null;

         var nPrecioCaracteristica = product.lstCaracteristicasOpcionesStock.find( l => l.MercaderiaCaracteristicaOpcion1_Id === product.caracteristica1
          && l.MercaderiaCaracteristicaOpcion2_Id === product.caracteristica2)

        product.nPrecio = nPrecioCaracteristica ? nPrecioCaracteristica.nPrecio : product.nPrecio;

        dispatch(addToCart(mercaderiaId, cantidad,caracteristicaId1,caracteristicaId2,caracteristica1 ? caracteristica1.sValor : '',
        caracteristica1 ? caracteristica1.sLink : '',caracteristica2 ? caracteristica2.sValor : '',caracteristica2 ? caracteristica2.sLink : '',product.nPrecio,
        product.nPrecioDescuento,product.TipoDescuento,product.TipoDescuentoId ));
      }
    }, [dispatch, mercaderiaId, cantidad,caracteristicaId1,caracteristicaId2,product 
    ]);

    useEffect(() => {
      
      if(empresaData && empresaData.bTiendaInicioPorGrupos === true)
      {
        dispatch({type: PANTALLASELECCIONADA, payload:  'Producto'}); 
        dispatch({type: PANTALLAATRAS, payload:  'ProductosPorGrupo/' + localStorage.getItem('EmpresaId')}); 
        
      }
      else
      {
          dispatch({type: PANTALLASELECCIONADA, payload:  'Producto'}); 
          if(props.match.params.id)
          dispatch({type: PANTALLAATRAS, payload:  'Product/' + String(props.match.params.id)}); 
          else
          dispatch({type: PANTALLAATRAS, payload:  'Empresa/' + String(localStorage.getItem('EmpresaId'))}); 
      }


   


        dispatch(promosCart());
    }, [dispatch, cartItems,empresaData,props.match.params.id]);

    const  promos = useSelector((state) => state.cart);
    const { promociones } = promos; 

    const removeFromCartHandler = (id) => {
        dispatch(removeFromCart(id));
      };
      const checkoutHandler = () => {
        props.history.push('/signin?redirect=shipping');
      };
    return (     <div className="row top" style={{marginBottom:'50px',marginTop:"-45px"}}>
    <div className="col-2">
      <h1>Carrito</h1>
      {cartItems.length === 0 ? (
        <MessageBox>
          El carrito esta vacio. <Link to={"/Empresa/" + localStorage.getItem('EmpresaId')}>Volver a la tienda</Link>
        </MessageBox>
      ) : (
        <ul  className="categoriesli">
          {cartItems.map((item) => (
            <li key={item.product}>
              <div className="row">
                <div>
                {item.image && String(item.image) !== "https://twinsarchivos.blob.core.windows.net/imagenesabmmercaderias/Producto.png" ?

                  <img
                    src={item.image}
                    alt={item.name}
                    className="small2"
                  ></img>:<></>}
                </div>
                <div  >
                  <Link to={`/product/${item.product}`}>{item.name}</Link>
                </div>
                <div>
                                      { item.Medicion === 'P' && 
                                        (
                                        <NumericInput mobile step={0.1}  size={6} precision={2} min={ item.CantidadEstandar }  
                                        required value={item.cantidad?item.cantidad:0} 
                                        onChange={(e) =>
                                          dispatch(
                                            addToCart(item.product, e,item.caracteristica1,item.caracteristica2,item.caracteristica1sValor,
                                                        item.caracteristica1sLink,item.caracteristica2sValor,item.caracteristica2sLink,item.price,
                                                        item.nPrecioDescuento,item.TipoDescuento,item.TipoDescuentoId )
                                          )
                                        }
                                        style={{  wrap: { fontSize: 18  },
                                                  input: { fontWeight: 100, },
                                                  'input:focus' : {  border: '1px inset #69C',  outline: 'none'  },
                                                  arrowUp: { borderBottomColor: 'rgba(66, 54, 0, 0.63)'  },
                                                  arrowDown: { borderTopColor: 'rgba(66, 54, 0, 0.63)'  }  }}/>
                                         )}
                                          { item.Medicion !== 'P' && 
                                        (
                                            <NumericInput mobile step={1} min={ 1 } size={4} precision={0} required value={item.cantidad}  onChange={(e) =>
                                              dispatch( addToCart(item.product,Number(e) ,item.caracteristica1,item.caracteristica2,item.caracteristica1sValor,
                                              item.caracteristica1sLink,item.caracteristica2sValor,item.caracteristica2sLink,item.price,
                                              item.nPrecioDescuento,item.TipoDescuento,item.TipoDescuentoId)
                                          )
                                        }
                                        style={{
                                              wrap: { fontSize: 18 },
                                              input: { fontWeight: 100,  },
                                              'input:focus' : {  border: '1px inset #69C', outline: 'none'  },
                                              arrowUp: {  borderBottomColor: 'rgba(66, 54, 0, 0.63)'  },
                                              arrowDown: {  borderTopColor: 'rgba(66, 54, 0, 0.63)'  } }}/>
                                         )}
                </div>
                <div>
                  
                {item.price !== item.nPrecioDescuento && <label style={{textDecoration:"line-through",marginRight:"10px"}}>${item.nPrecioDescuento} </label>  } 
                ${item.price} 
                {item.TipoDescuento !== "" && <label>({item.TipoDescuento})</label>}
                  
                
                </div>
                <div>
                  <Tooltip title={<h5 style={{ color: "#FE6DFE" }}>Eliminar del pedido</h5>}>
                      <DeleteForeverRoundedIcon style={{fontSize: "3rem",cursor: "hand" , verticalAlign: "bottom"}} onClick={() => removeFromCartHandler(item.name)} /> 
                  </Tooltip>
                </div>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
    <div className="col-1">
      <div className="card card-body">
        <ul>
        <li>
                <div className="row">
                  <div>Subtotal</div>
                  <div>({cartItems.reduce((a, c) => a + c.cantidad, 0)} items) : $
              {cartItems.reduce((a, c) => a + c.nPrecioDescuento * c.cantidad, 0)}</div>
                </div>
              </li>
              { (promociones   )   && (
              <li>
                <div className="row">
                  <div>Descuentos/Promociones</div>
                  <div>$ {parseFloat(Number(promociones ? promociones.TotalMontoDescuento:0) + cartItems.reduce((a, c) => a + c.price * c.cantidad, 0) - cartItems.reduce((a, c) => a + c.nPrecioDescuento * c.cantidad, 0)).toFixed(3)}</div>
                </div>
              </li>)}
              { (promociones  )   && (
              <li>
                <div className="row">
                  <div><strong>Total Con Descuento</strong></div>
                  <div><strong>$ {(promociones ? promociones.TotalMonto:0)  } </strong></div>
                </div>
              </li>)}
          
          <li>

             <button
              type="button"
              onClick={checkoutHandler}
              className="primary block"
              disabled={cartItems.length === 0}
            >
              Realizar Pedido
            </button> 
          </li>
        </ul>
      </div>
    </div>
  </div>
);
}