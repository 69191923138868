import { CART_EMPTY } from '../constants/cartConstants';
import {
  ORDER_CREATE_FAIL,
  ORDER_CREATE_REQUEST,
  ORDER_CREATE_SUCCESS,
  ORDER_DETAILS_FAIL,
  ORDER_DETAILS_REQUEST,
  ORDER_DETAILS_SUCCESS,
  ORDER_MINE_LIST_REQUEST,
  ORDER_MINE_LIST_FAIL,
  ORDER_MINE_LIST_SUCCESS,
  ORDER_DELETE_FAIL,
  ORDER_DELETE_SUCCESS,
  ORDER_DELETE_REQUEST,
} from '../constants/orderConstants';
import { EMPRESA_SUCCESS } from '../constants/userConstants';

export const createOrder = (order,Observaciones) => async (dispatch, getState) => {
  dispatch({ type: ORDER_CREATE_REQUEST, payload: order });
  try {
    const { userSignin: { userInfo }, } = getState(); 
    var parametros = {
        EmpresaId: localStorage.getItem('EmpresaId'),
        Detalle:order.cartItems,
        DireccionEntregaId: order.shippingAddress.Id,
        OperadorId: userInfo.Id,
        DesdeApp: true,
        Tarjeta: order.tarjeta,
        ClienteAppMail:userInfo.Email,
        FormaPagoId: order.paymentMethod.TipoId ,
        TotalPago: order.totalPrice,
        nCostoEnvio: order.shippingPrice,
        ClienteAppNombre: userInfo.Nombre  ,
        ClienteAppApellido:userInfo.Apellido,
        ClienteAppDNI: userInfo.Documento,
        ClienteAppId: userInfo.ClienteId,
        nDescuento:order.descuentos,
        Puesto: localStorage.getItem('Puesto'),
        Horario: order.horario ? order.horario.Id : 0,
        nDescuentoFamilia: order.promociones.TotalMontoDescuento ?order.promociones.TotalMontoDescuento :0,
        FechaEntrega:order.Fecha?order.Fecha:new Date(),
        Observaciones: Observaciones? Observaciones:""
  };
    
 
    const response = await fetch('https://twinsappservice.azurewebsites.net/api/MercadoPago/InsertPedidoAppWeb', {
           method: 'POST',
           headers: { 'Content-type': 'application/json; charset=UTF-8','Access-Control-Allow-Credentials':'true'},
           body: JSON.stringify(parametros)
           });    
         const data = await response.json();
         if(!data )
         {
             dispatch({ type: ORDER_CREATE_FAIL, payload:  'Error Inesperado al crear el pedido'  });
        }
        else if(data.Error !== "")
        {
                dispatch({ type: ORDER_CREATE_FAIL, payload:  data.Error  });
        }
        else if(data.Dato === 0)
        {
                dispatch({ type: ORDER_CREATE_FAIL, payload:  'Error Inesperado al crear el pedido'  });
        }
         else
         {
            dispatch({ type: ORDER_CREATE_SUCCESS, payload: data.Dato});
            dispatch({ type: CART_EMPTY });
            localStorage.removeItem('cartItems');
         }
  } catch (error) {
    dispatch({
      type: ORDER_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const detailsOrder = (orderId) => async (dispatch, getState) => {
  dispatch({ type: ORDER_DETAILS_REQUEST, payload: orderId });
  try {
    var parametros = {
      EmpresaId: localStorage.getItem('EmpresaId'),
      PedidoId: orderId  };
      const response = await fetch('https://twinsappservice.azurewebsites.net/api/Pedidos/ResumenGet', {
           method: 'POST',
           headers: { 'Content-type': 'application/json; charset=UTF-8','Access-Control-Allow-Credentials':'true'},
           body: JSON.stringify(parametros)
           });    
         const data = await response.json();
         const responseDetalle = await fetch('https://twinsappservice.azurewebsites.net/api/Pedidos/DetalleApp', {
           method: 'POST',
           headers: { 'Content-type': 'application/json; charset=UTF-8','Access-Control-Allow-Credentials':'true'},
           body: JSON.stringify(parametros)
           });    
         const detalle = await responseDetalle.json(); 
         var resultado = {
           Id:data.Id,
          ClienteDescripcion: data.ClienteDescripcion,
          EntregaLocalidad : data.Localidad,
          EntregaProvincia : data.Provincia,
          EntregaPais: data.Pais? data.Pais: "Argentina",
          EntregaDireccion: data.Direccion,
          EntregaCodigoPostal: data.CodigoPostal? data.CodigoPostal: "",
          MetodoPago : data.sFormaPago, 
          Aprobado: data.bAprobado,
          itemsPrice : detalle.Lista.reduce((accumulator, currentValue) => accumulator + (currentValue.nPrecio * currentValue.nCantidad), 0) ,
          shippingPrice: data.nCostoEnvio? data.nCostoEnvio:  0 ,
          descuentos:  detalle.Lista.reduce((accumulator, currentValue) => accumulator + (currentValue.nPrecio * currentValue.nCantidad), 0) 
                    + (data.nCostoEnvio? data.nCostoEnvio:  0) -(data.nTotalPedido? data.nTotalPedido:  0 ) ,
          tarjetaNumero: data.sTarjeta? data.sTarjeta: "",
          totalPrice: data.nTotalPedido? data.nTotalPedido:  0 ,
          Lista:  detalle.Lista  }; 
    dispatch({ type: ORDER_DETAILS_SUCCESS, payload: resultado });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: ORDER_DETAILS_FAIL, payload: message });
  }
};

export const listOrderMine = () => async (dispatch, getState) => {
  dispatch({ type: ORDER_MINE_LIST_REQUEST });
  const { userSignin: { userInfo }, } = getState();
  try {
    var desde = new Date();
    desde.setDate(desde.getDate() - 360);
    var hasta = new Date();
    hasta.setDate(hasta.getDate() );
    var parametros = {
      EmpresaId: localStorage.getItem('EmpresaId'),
      ClienteId: userInfo.ClienteId  ,
      FechaDesde: desde,
      FechaHasta : hasta
    };
    if(!localStorage.getItem('Empresa'))
    {
      const responseIdentificacion = await fetch('https://twinsappservice.azurewebsites.net/api/Operadores/GetIdentificacion', {
        method: 'POST',
        headers: { 'Content-type': 'application/json; charset=UTF-8','Access-Control-Allow-Credentials':'true'},
        body: JSON.stringify(parametros)
        });    
      const dataIden = await responseIdentificacion.json();
      localStorage.setItem('Empresa', JSON.stringify(dataIden.Identificacion));
     dispatch({type: EMPRESA_SUCCESS, payload:  dataIden.Identificacion});
    }
    else
    {
      const serialisedState = JSON.parse(localStorage.getItem('Empresa'));
      dispatch({type: EMPRESA_SUCCESS, payload: serialisedState});
    }
      const response = await fetch('https://twinsappservice.azurewebsites.net/api/Pedidos/PedidoResumenApp', {
           method: 'POST',
           headers: { 'Content-type': 'application/json; charset=UTF-8','Access-Control-Allow-Credentials':'true'},
           body: JSON.stringify(parametros)
           });    
         const data = await response.json();
         data.Lista = data.Lista.sort((b, a) => a.Id - b.Id);
    dispatch({ type: ORDER_MINE_LIST_SUCCESS, payload: data.Lista });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: ORDER_MINE_LIST_FAIL, payload: message });
  }
};

export const deleteOrder = (order) => async (dispatch, getState) => {
  dispatch({ type: ORDER_DELETE_REQUEST, payload: order });
  try {
    const { userSignin: { userInfo }, } = getState(); 
    var parametros = {
        EmpresaId: localStorage.getItem('EmpresaId'),
        MercadoPagoId: order.MercadoPagoId,
        PedidoId: order.Id,
        OperadorId: userInfo.Id      
  };
    const response = await fetch('https://twinsappservice.azurewebsites.net/api/MercadoPago/EliminarPedido', {
           method: 'POST',
           headers: { 'Content-type': 'application/json; charset=UTF-8','Access-Control-Allow-Credentials':'true'},
           body: JSON.stringify(parametros)
           });    
         const data = await response.json();
       if(data.Message)
        { 
            dispatch({ type: ORDER_DELETE_FAIL, payload:  data.Message  });
        }
        else if(data !== "")
        { 
            dispatch({ type: ORDER_DELETE_FAIL, payload:  data  });
        }
         else
         {
            dispatch({ type: ORDER_DELETE_SUCCESS, payload: data.Dato});
         }
  } catch (error) {
    dispatch({
      type: ORDER_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};