import React from 'react'
import { Link } from 'react-router-dom';
export default function Empresa(props) {
    const {empresa}=props;
    return (
      <div key={empresa.Id} className="card">
        <Link to={`/empresa/${empresa.Id}`}>
          <img className="medium" src={"https://twinsarchivos.blob.core.windows.net/logosempresas/"+ String(empresa.Id)+".png"} alt={empresa.Id} />
        </Link>
        <div  >
       
            <h2>{empresa.sDescripcion}</h2>
        
       <p>{empresa.Localidad_Descripcion + ' - ' + empresa.Provincia_Descripcion}</p>
             
     
          <div className="row">
          <Link to={`/empresa/${empresa.Id}`}>
            <div className="price">{empresa.Id}</div>
            </Link>
            <div>
              {/* <Link to={`/seller/${empresa.seller._id}`}>
                {empresa.seller.seller.name}
              </Link> */}
            </div>
          </div>
        </div>
      </div>
    );
}
