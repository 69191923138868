import { EMPRESA_LIST_FAIL, EMPRESA_LIST_REQUEST, EMPRESA_LIST_SUCCESS } from "../constants/empresaConstants";

export const listempresas = () => async (dispatch) =>{
    if(localStorage.getItem('listaempresas'))
    {
        dispatch({type: EMPRESA_LIST_SUCCESS, payload:  JSON.parse(localStorage.getItem('listaempresas'))}); 
    }
else
{
    dispatch({  type: EMPRESA_LIST_REQUEST  });
}
    try{  
 
         const response = await fetch('https://twinsappservice.azurewebsites.net/api/Generalidades/EmpresasApp', {
               method: 'POST',
               headers: { 'Content-type': 'application/json; charset=UTF-8','Access-Control-Allow-Credentials':'true'} 
               });    
              const data = await response.json();

              dispatch({type: EMPRESA_LIST_SUCCESS, payload:  data.Lista});
              localStorage.removeItem('listaempresas');
              localStorage.setItem('listaempresas',  JSON.stringify(data.Lista ));
    }
    catch(error){
        dispatch({ type:EMPRESA_LIST_FAIL, payload: error.message });
    }
}