import {createStore, compose,applyMiddleware, combineReducers} from 'redux';
import thunk from 'redux-thunk'  ;
import { cartReducer } from './reducers/cartReducers';
import { orderCreateReducer, orderDeleteReducer, orderDetailsReducer, orderMineListReducer } from './reducers/orderReducers';
import { productDetailsReducer,  productListReducer,productCategoryListReducer, productReviewCreateReducer, imagenesListaReducer,}   from './reducers/productReducers';
import { userSigninReducer, userRegisterReducer,empresaInfoReducer,userDetailsReducer,userUpdateProfileReducer, provinciasListReducer, localidadesListReducer, direccionInsertReducer, direccionDeleteReducer, recuperarclaveReducer, pantallaSeleccionadaReducer, pantallaAtrasReducer, grupoSeleccionadoReducer  } from './reducers/userReducers';
import { empresaListReducer } from './reducers/empresaReducers';
import { filtrosReducer } from './reducers/filtrosReducers';
import { RangosHorarioReducer } from './reducers/rangoshorarioReducers';
     const initialState = {
         userSignin:
         {
             userInfo: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')):null
         },
        cart: {
          cartItems: localStorage.getItem('cartItems')
            ? JSON.parse(localStorage.getItem('cartItems'))
            : [],

            shippingAddress: localStorage.getItem('shippingAddress')
            ? JSON.parse(localStorage.getItem('shippingAddress'))
            : {},
            paymentMethod: 'Tarjeta',
        },
        empresaInfo : localStorage.getItem('empresaInfo')?  localStorage.getItem('empresaInfo') : '',
        empresaData : localStorage.getItem('Empresa')?  localStorage.getItem('Empresa') : '',
      };
const reducer = combineReducers({
    productList: productListReducer,
    productDetails: productDetailsReducer,
    cart: cartReducer,
    userSignin: userSigninReducer,
    userRegister: userRegisterReducer,
    orderCreate: orderCreateReducer,
    empresaInfo: empresaInfoReducer,
    orderDetails: orderDetailsReducer,
    orderMineList: orderMineListReducer,
    userDetails:  userDetailsReducer,
    userUpdateProfile: userUpdateProfileReducer,
    productCategoryList: productCategoryListReducer,
    productReviewCreate: productReviewCreateReducer,
    provinciasList :provinciasListReducer,
    localidadesList :localidadesListReducer,
    direccionInsert: direccionInsertReducer,
    direccionDelete: direccionDeleteReducer,
    empresaList: empresaListReducer,
    orderDelete: orderDeleteReducer,
    filtros: filtrosReducer,
    imagenesLista:imagenesListaReducer,
    RangosHorario:RangosHorarioReducer,
    recuperarclave:recuperarclaveReducer,
    pantallaSeleccionada:pantallaSeleccionadaReducer,
    pantallaAtras:pantallaAtrasReducer,
    grupoSeleccionado:grupoSeleccionadoReducer
});
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const store = createStore(
      reducer, initialState, 
      composeEnhancer(applyMiddleware(thunk))
      );
  export default store;