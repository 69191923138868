import React ,{useState} from 'react'
import {useDispatch, useSelector } from 'react-redux'
import { saveFiltroPalabra } from '../actions/FiltrosActions';
import { listProducts } from '../actions/productActions';
 

export default function SearchBox(props) {
  const filtros = useSelector((state) => state.filtros);
  const { filtroItems } = filtros;
  const dispatch = useDispatch();
  const [name, setName] = useState(filtroItems.Palabra);
 
  const empresaInfo = useSelector((state) => state.empresaInfo);
  const { empresaData } = empresaInfo;

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(saveFiltroPalabra(name));  
    dispatch(listProducts(localStorage.getItem('EmpresaId'),{ }));

    if(empresaData && empresaData.bTiendaInicioPorGrupos === true)
    {
      props.history.push(`/ProductosPorGrupo/${localStorage.getItem('EmpresaId')}`);
    }
    else
    {
      props.history.push(`/empresa/${localStorage.getItem('EmpresaId')}`);
    }


   
  };

 
  return (
    <form className="search" onSubmit={submitHandler}>
      <div >
        <input
          type="text"
          name="q"
          id="q"
          value={name}
          onChange={(e) => setName(e.target.value)}
        ></input>
        <button className="primary" type="submit">
          <i className="fa fa-search"></i>
        </button>

 
      </div>
    </form>
  );
}