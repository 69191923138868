import React, { useEffect } from 'react'
import { listProductCategories } from '../actions/productActions';
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
import {useDispatch, useSelector } from 'react-redux'
 
import { CART_EMPTY } from '../constants/cartConstants';
import { PANTALLAATRAS, PANTALLASELECCIONADA, USER_SIGNOUT } from '../constants/userConstants';
import {  saveFiltroGrupo, saveFiltroPalabra, saveFiltroPrecio, saveFiltroRating } from '../actions/FiltrosActions';
import Grupo from '../components/Grupo';
import { GetIdentificacion } from '../actions/userActions';

export default function HomeScreenGrupos(props) {
const EmpresaId = props.match.params.id;
const Puesto = props.match.params.puesto;
 
const dispatch = useDispatch();
useEffect(() => {
  dispatch({type: PANTALLASELECCIONADA, payload:  'MenuGrupos'}); 
  dispatch({type: PANTALLAATRAS, payload:  ''}); 
  dispatch(GetIdentificacion(EmpresaId))
},[dispatch,EmpresaId]);

const productCategoryList = useSelector((state) => state.productCategoryList);
const {error, loading, categories } = productCategoryList;
useEffect(() => { 
      if(EmpresaId !== localStorage.getItem('EmpresaId'))
      {
        localStorage.setItem('Puesto',Puesto?Puesto:'') ;
        localStorage.setItem('EmpresaId',EmpresaId) ;
        dispatch({type: CART_EMPTY});
        dispatch({ type: USER_SIGNOUT });
        dispatch(saveFiltroPrecio('Todos'));
        dispatch(saveFiltroPalabra(''));
        dispatch(saveFiltroGrupo(0));
        dispatch(saveFiltroRating('Todas'));
        localStorage.removeItem('userInfo');
        localStorage.removeItem('cartItems');
        localStorage.removeItem('shippingAddress'); 
        localStorage.removeItem('ListaProductosOrden') 
        localStorage.setItem('ListaProductosOrden','newest') 
      }
      dispatch(listProductCategories(EmpresaId))
  
},[dispatch,EmpresaId,Puesto])

return (
      <div style={{ marginTop:"-45px"}} >
         <div >
        {loading? <LoadingBox></LoadingBox>
        :
        error?<MessageBox variant="danger">{error}</MessageBox>
        :
        
            <div className="row center">
            <div className="row center">
            {
            categories.map(grupo => (
              <Grupo key={grupo.Grupo_Id} Grupo={grupo} props={props}/>
             
              ))
         
            
            }
            </div>
            </div> 
        }     
      </div> 
      </div> 
    )
}
