import { FormControl, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import React, { useState,useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { savePaymentMethod } from '../actions/cartActions';
import CheckoutSteps from '../components/CheckoutSteps';
import { PANTALLAATRAS, PANTALLASELECCIONADA } from '../constants/userConstants';

export default function PaymentMethodScreen(props) {
  const cart = useSelector((state) => state.cart);
  const { shippingAddress } = cart;
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;

  const RangosHorario =  useSelector(state => state.RangosHorario)
  const { RangosHorarioLista} = RangosHorario;

  if (!userInfo) {
  props.history.push('/signin?redirect=payment');
  }

  if (!cart.cartItems) {
    props.history.push('/empres/'+ localStorage.getItem('EmpresaId'));

  }if (cart.cartItems.length === 0) {
    props.history.push('/empresa/'+ localStorage.getItem('EmpresaId'));
  }

  if (!shippingAddress.Id) {
    props.history.push('/shipping');
  }

  const [metodoseleccionado, setMetodoSeleccionado] = useState(userInfo ? ((userInfo.MetodosPago ? ( userInfo.MetodosPago[0] ?userInfo.MetodosPago[0].Descripcion:'') : '')) : '');
  const handleChangeMetodo = (event) => { 
    setMetodoSeleccionado( (event.target.value));
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({type: PANTALLASELECCIONADA, payload:  'Metodo'}); 
    dispatch({type: PANTALLAATRAS, payload: 'shipping'}); 
  }, [dispatch]);

  


  const submitHandler = (e) => {
    e.preventDefault();

    const drSeleccionada = userInfo.MetodosPago.filter(l => { return  l.Descripcion === metodoseleccionado})[0];
    dispatch(savePaymentMethod(drSeleccionada));
    if(RangosHorarioLista && RangosHorarioLista.length > 0 )
    props.history.push('/horario');
    else
    props.history.push('/placeorder');
  };


  return (
    <div>
       <CheckoutSteps step1 step2 step3></CheckoutSteps>
      <div className="row">
     
      <form className="form"  onSubmit={submitHandler}>
        <div style={{margin:0,padding:0}}>
          <h1>Metodo de Pago</h1>
        </div>

<FormControl component="fieldset" style={{margin:0,padding:0}}>
      <RadioGroup aria-label="gender"  name="gender1" value={metodoseleccionado} onChange={handleChangeMetodo}>
      {userInfo && userInfo.MetodosPago.map(metodo => (
        <FormControlLabel   key={metodo.Descripcion}
        style={{ margin:0,padding:0, RadioLabel: {  fontSize: "3rem"   } }}
         value={metodo.Descripcion}  control={<Radio style={{ RadioLabel: {  fontSize: "3rem"   } }}/>}   
        margin="normal"
        label={<span style={{ fontSize: '2rem' }}>{metodo.Descripcion} </span>}/>
         ))
      }
      </RadioGroup>
    </FormControl>
        <div style={{marginBottom: '50px'}}>
          <label />
          <button className="primary" type="submit">
            Continuar
          </button>
        </div>
      </form>
    </div>
    </div>
  );
}