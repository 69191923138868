import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { listProducts } from '../actions/productActions';
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
import Product from '../components/Product';
 

export default function SearchScreen(props) {
    const { name = 'all', category = 0, min=0, max=0, rating=0 ,order = 'newest'} = useParams();
  const dispatch = useDispatch();

 

  const productList = useSelector((state) => state.productList);
  const { loading, error, products } = productList;
 
  /* const productCategoryList = useSelector((state) => state.productCategoryList);
  const {
    loading: loadingCategories,
    error: errorCategories,
    categories,
  } = productCategoryList; */

 
  useEffect(() => {
    dispatch(
        listProducts(localStorage.getItem('EmpresaId'),{
          name: name !== 'all' ? name : '' ,
          category ,
          min,max,rating,order
        })
      );
    }, [category, dispatch,max, min,  name,rating,order]);

    const getFilterUrl = (filter) => {
        const filterCategory = filter.category || category;
        const filterName = filter.name || name;
        const filterMin = filter.min ? filter.min : filter.min === 0 ? 0 : min;
        const filterMax = filter.max ? filter.max : filter.max === 0 ? 0 : max;
        const filterRating = filter.rating || rating;
        const sortOrder = filter.order || order;
        return `/search/category/${filterCategory}/name/${filterName}/min/${filterMin}/max/${filterMax}/rating/${filterRating}/order/${sortOrder}`;
      };
      return (
        <div>
          <div className="row">
          <div className="row">
          {!loading &&
                
                   
                  
                    products.map((product) => (
                      
                      <div key={product.MercaderiaId} >{ ' - ' + product.MercaderiaDescripcion}</div>

                    ))
                  
               
               }
           </div>

             {!loading && <div>{products.length} Resultados</div>}
           
           



        <div>
          Ordenar Por{' '}
          <select
            value={order}
            onChange={(e) => {
              props.history.push(getFilterUrl({ order: e.target.value }));
            }}
          >
            <option value="newest">Mas Nuevo</option>
            <option value="lowest">Precio: mas bajo</option>
            <option value="highest">Precio: mas alto</option>
            <option value="toprated">Mejores Calificaciones</option>
          </select>
        </div>







          </div>
          <div className="row top">
             <div className="col-1">
           {/*   <h3>Grupos</h3>
                <div>
              {loadingCategories ? (
                <LoadingBox></LoadingBox>
              ) : errorCategories ? (
                <MessageBox variant="danger">{errorCategories}</MessageBox>
              ) : (
                <ul>
                  <li >
                      <Link
                        className={'all' === `${category}` ? 'active' : ''  }
                        to={getFilterUrl({ category: 'all'})}
                      >
                        Todos
                      </Link>
                    </li>
                  {categories.map((c) => (
                    <li key={c.Grupo_Id}>
                      <Link
                        className={`${c.Grupo_Id}` === `${category}` ? 'active' : ''  }
                        to={getFilterUrl({ category: c.Grupo_Id})}
                      >
                        {c.Descripcion}
                      </Link>
                    </li>
                  ))}
                </ul>
              )}
            </div> */}
            
            
            {/* <div>
                <h3>Precio</h3>
                <ul>
           
              {prices.map((p) => (
                <li key={p.name}>
                  <Link

                    to={getFilterUrl({ min: p.min, max: p.max })}
                    className={ `${p.min}-${p.max}` === `${min}-${max}` ? 'active' : ''}
                  >
                    {p.name}
                  </Link>
                </li>
              ))}
            </ul>
            </div> */}

            {/* <div>
            <h3>Calificaciones</h3>
            <ul>
            <li>
            <Link
                        className={'0' === `${rating}` ? 'active' : ''  }
                        to={getFilterUrl({ rating: '0'})}
                      >
                        Todos
                      </Link>
                    </li>
           
              {ratings.map((r) => (
                <li key={r.name}>
                  <Link
                    to={getFilterUrl({ rating: r.rating })}
                    className={`${r.rating}` === `${rating}` ? 'active' : ''}
                  >
                    <Rating caption={' mas'} rating={r.rating}></Rating>
                  </Link>
                </li>
              ))}
            </ul>
          </div> */}
            </div>
            <div className="col-3">
              {loading ? (
                <LoadingBox></LoadingBox>
              ) : error ? (
                <MessageBox variant="danger">{error}</MessageBox>
              ) : (
                <>
                  {products.length === 0 && (
                    <MessageBox>No se encontraron productos para los filtros seleccionados</MessageBox>
                  )}
                  <div className="row center">
                    {products.map((product) => (
                      <Product key={product.MercaderiaId} product={product}></Product>
                    ))}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      );
    }