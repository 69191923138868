export const USER_SIGNIN_REQUEST = 'USER_SIGNIN_REQUEST';
export const USER_SIGNIN_SUCCESS = 'USER_SIGNIN_SUCCESS';
export const USER_SIGNIN_FAIL = 'USER_SIGNIN_FAIL';
export const USER_SIGNOUT = 'USER_SIGNOUT';

export const USER_REGISTER_REQUEST = 'USER_REGISTER_REQUEST';
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS';
export const USER_REGISTER_FAIL = 'USER_REGISTER_FAIL';

export const EMPRESA_REQUEST = 'EMPRESA_REQUEST';
export const EMPRESA_SUCCESS = 'EMPRESA_SUCCESS';
export const EMPRESA_FAIL = 'EMPRESA_FAIL';

export const USER_DETAILS_REQUEST = 'USER_DETAILS_REQUEST';
export const USER_DETAILS_SUCCESS = 'USER_DETAILS_SUCCESS';
export const USER_DETAILS_FAIL = 'USER_DETAILS_FAIL';

export const USER_UPDATE_PROFILE_REQUEST = 'USER_UPDATE_PROFILE_REQUEST';
export const USER_UPDATE_PROFILE_SUCCESS = 'USER_UPDATE_PROFILE_SUCCESS';
export const USER_UPDATE_PROFILE_FAIL = 'USER_UPDATE_PROFILE_FAIL';
export const USER_UPDATE_PROFILE_RESET = 'USER_UPDATE_PROFILE_RESET';

export const PROVINCIAS_REQUEST = 'PROVINCIAS_REQUEST';
export const PROVINCIAS_SUCCESS = 'PROVINCIAS_SUCCESS';
export const PROVINCIAS_FAIL = 'PROVINCIAS_FAIL'; 

export const LOCALIDADES_REQUEST = 'LOCALIDADES_REQUEST';
export const LOCALIDADES_SUCCESS = 'LOCALIDADES_SUCCESS';
export const LOCALIDADES_FAIL = 'LOCALIDADES_FAIL'; 

export const DIRECCION_INSERT_REQUEST = 'DIRECCION_INSERT_REQUEST';
export const DIRECCION_INSERT_SUCCESS = 'DIRECCION_INSERT_SUCCESS';
export const DIRECCION_INSERT_FAIL = 'DIRECCION_INSERT_FAIL'; 
export const DIRECCION_INSERT_RESET = 'DIRECCION_INSERT_RESET'; 

export const DIRECCION_DELETE_REQUEST = 'DIRECCION_DELETE_REQUEST';
export const DIRECCION_DELETE_SUCCESS = 'DIRECCION_DELETE_SUCCESS';
export const DIRECCION_DELETE_FAIL = 'DIRECCION_DELETE_FAIL'; 
export const DIRECCION_DELETE_RESET = 'DIRECCION_DELETE_RESET'; 

export const RECUPERARCLAVE_REQUEST = 'RECUPERARCLAVE_REQUEST';
export const RECUPERARCLAVE_SUCCESS = 'RECUPERARCLAVE_SUCCESS';
export const RECUPERARCLAVE_FAIL = 'RECUPERARCLAVE_FAIL'; 
export const RECUPERARCLAVE_RESET = 'RECUPERARCLAVE_RESET'; 

export const PANTALLASELECCIONADA = 'PANTALLASELECCIONADA';
export const PANTALLAATRAS = 'PANTALLAATRAS';

export const GRUPOSELECCIONADO = 'GRUPOSELECCIONADO';