import { RANGOSHORARIO_LISTA_FAIL,  RANGOSHORARIO_LISTA_REQUEST, RANGOSHORARIO_LISTA_SUCCESS
   } from "../constants/RangosHorarioConstants";

 
export const getlistaRangosHorario = (Fecha) => async (dispatch) => {
  

    dispatch({ type: RANGOSHORARIO_LISTA_REQUEST});
    try {
      var parametros = {EmpresaId: localStorage.getItem('EmpresaId'), Fecha:Fecha};
  
   
      const response = await fetch('https://twinsappservice.azurewebsites.net/api/RangosHorario/Disponibles', {
            method: 'POST',
            headers: { 'Content-type': 'application/json; charset=UTF-8','Access-Control-Allow-Credentials':'true'},
            body: JSON.stringify(parametros)
            });    
           const data = await response.json();
      
          if(data.Message)
           {
            dispatch({ type: RANGOSHORARIO_LISTA_FAIL, payload:  'Error inesperado. Por favor vuelva a intentarlo'  });
           }
           else if(data.Error !== "")
           {
            dispatch({ type: RANGOSHORARIO_LISTA_FAIL, payload:  data.Error });
           }
           else
           {
            dispatch({type: RANGOSHORARIO_LISTA_SUCCESS, payload:  data.Lista}); 
           
           }
           
    } catch (error) {
      dispatch({
        type: RANGOSHORARIO_LISTA_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

